import moment from "moment";
import React from "react";
import { RouteComponentProps } from "react-router-dom";

import { PostProductBarcodeInsertDataSource } from "../../../../../endpoints/magazine/PostProductBarcodeInsert";
import { GetProductBarcodeFindDataSource } from "../../../../../endpoints/magazine/GetProductBarcodeFind";
import { GetStateDetailsDataSource } from "../../../../../endpoints/magazine/GetStateDetails";
import { PostStateProductUpdateDataSource } from "../../../../../endpoints/magazine/PostStateProductUpdate";
import { DataSourceStorageInitial } from "../../../../../utils/endpoint/DataSourceNew";
import { DataSourceFunctionGetStorage } from "../../../../../utils/endpoint/DataSourceNew.function.types";

import TO from "./Details.json";

import "./Details.scss";

import Overlay, {
    OverlayData
} from "../../../../../components/overlay/Overlay";
import { BasicComponent } from "../../../../../utils/BasicComponent/BasicComponent";
import { PostMagazineStateProductUpdateData } from "../../../../../../../../Api/types/src/api/magazine/PostMagazineStateProductUpdate.types";
import { ProductListItem } from "../../../../../../../../Api/types/src/domain/catalogue/ProductList.types";

import SelectDateModal, {
    SelectDateModalProps,
    SelectDateModalResponseData
} from "./SelectDateModal/SelectDateModal";
import { MagazineStateDetailsStateModals } from "./Details.types";
import AddProductModal, {
    MagazineStateSelectProductModalResponse
} from "./SelectProductModal/SelectProductModal";
import FindBarcodeModal from "./FindBarcodeModal/FindBarcodeModal";

interface EditProductCount {
    expireDate?: string;
    count: string;
}

interface EditProduct {
    product: ProductListItem;
    counts: EditProductCount[];
}

interface MagazineStateDetailsState {
    datasource: {
        MagazineGetStateDetails: DataSourceFunctionGetStorage<
            typeof GetStateDetailsDataSource
        >;
        MagazinePostStateProductUpdate: DataSourceFunctionGetStorage<
            typeof PostStateProductUpdateDataSource
        >;
        MagazineGetProductBarcodeFind: DataSourceFunctionGetStorage<
            typeof GetProductBarcodeFindDataSource
        >;
        MagazineProductBarcodeInsert: DataSourceFunctionGetStorage<
            typeof PostProductBarcodeInsertDataSource
        >;
    };
    modal: MagazineStateDetailsStateModals;
    magazineStateId: number | undefined;
    open: {
        [key: number]: boolean;
    };
    isEdit: boolean;
    products: EditProduct[];
    wsState: boolean;
}

export default class MagazineStateDetails extends BasicComponent<
    RouteComponentProps,
    MagazineStateDetailsState
> {
    state: MagazineStateDetailsState = {
        datasource: {
            MagazineGetStateDetails: DataSourceStorageInitial,
            MagazinePostStateProductUpdate: DataSourceStorageInitial,
            MagazineGetProductBarcodeFind: DataSourceStorageInitial,
            MagazineProductBarcodeInsert: DataSourceStorageInitial
        },
        modal: {
            selectDate: {
                open: false,
                props: undefined
            },
            addProduct: {
                open: false
            },
            findBarcode: {
                open: false
            },
            findProductForBarcode: {
                open: false,
                barcode: undefined
            }
        },
        magazineStateId: undefined,
        open: {},
        isEdit: false,
        products: [],
        wsState: false
    };

    protected TranslateObject = TO;

    private dsBarcodeFind = GetProductBarcodeFindDataSource(this);
    private dsBarcodeProductInsert = PostProductBarcodeInsertDataSource(this);
    private dsStateDetails = GetStateDetailsDataSource(this);
    private dsStateProductUpdate = PostStateProductUpdateDataSource(this);

    private ws: WebSocket | undefined;

    private keyboardWatcher: ((e: any) => void) | undefined;

    private currentScan: {
        value: string;
        timestamp: number;
        timeout: number | undefined;
    } = {
        value: "",
        timestamp: 0,
        timeout: undefined
    };

    componentDidMount() {
        this._isMounted = true;
        const params = new URLSearchParams(this.props.location.search);
        const magazineStateId = parseInt(params.get("MagazineStateId") || "");

        if (!Number.isInteger(magazineStateId)) {
            this.props.history.push("/magazine/stocktaking");
        }

        this.dsStateDetails.request({
            params: { magazineStateId: magazineStateId }
        });

        this.setState({ magazineStateId: magazineStateId });
    }

    get overlayData(): OverlayData {
        if (
            this.dsStateDetails.state === "idle" ||
            this.dsStateDetails.state === "pending"
        ) {
            return {
                show: true,
                title: "Loading..."
            };
        }
        return {
            show: false
        };
    }

    render() {
        if (!this.dsStateDetails.data) {
            return (
                <div className="content-basic">
                    <Overlay data={{ title: "Ładowanie..." }} />
                </div>
            );
        }

        const modalSelectDate =
            !!this.state.modal.selectDate &&
            this.state.modal.selectDate.props ? (
                <SelectDateModal
                    onDismiss={() => this.modalSelectDateDismiss()}
                    onClose={data => this.modalSelectDateClose(data)}
                    props={this.state.modal.selectDate.props}
                />
            ) : null;

        const modalAddProduct = !!this.state.modal.addProduct.open ? (
            <AddProductModal
                onDismiss={() => this.modalAddProductDismiss()}
                onClose={data => this.modalAddProductClose(data)}
            />
        ) : null;

        const modalFindBarcode = !!this.state.modal.findBarcode.open ? (
            <FindBarcodeModal
                onDismiss={() => this.modalFindBarcodeDismiss()}
                onClose={barcode => this.modalFindBarcodeClose(barcode)}
            />
        ) : null;

        const modalFindProductForBarcode = !!this.state.modal
            .findProductForBarcode.open ? (
            <AddProductModal
                onDismiss={() => this.modalFindProductForBarcodeDismiss()}
                onClose={data => this.modalFindProductForBarcodeClose(data)}
            />
        ) : null;

        return (
            <div className="content-basic">
                {modalSelectDate}
                {modalAddProduct}
                {modalFindBarcode}
                {modalFindProductForBarcode}
                {!this.state.isEdit ? (
                    <div className="content-basic__header">
                        <button
                            className="_button-light"
                            onClick={() => this.startEdit()}
                        >
                            {this.T("editProducts")}
                        </button>
                    </div>
                ) : (
                    <div className="content-basic__header">
                        <button
                            className="_button-light"
                            onClick={() => this.cancelEdit()}
                        >
                            {this.T("cancel")}
                        </button>
                        <button
                            className="_button-light"
                            onClick={() => this.saveAndClose()}
                        >
                            {this.T("saveClose")}
                        </button>
                        <button className="_button-light" onClick={() => this.saveEdit()}>
                            {this.dsStateProductUpdate.state === "pending" ? this.T("saveProgress") : this.T("save")}
                        </button>
                        <button
                            className="_button-light"
                            onClick={() => this.modalAddProductOpen()}
                        >
                            {this.T("add")}
                        </button>

                        <button
                            className="_button-light"
                            onClick={() => this.modalFindBarcodeOpen()}
                        >
                            {this.T("findBarcode")}
                        </button>

                        {this.state.wsState ? (
                            <button
                                className="_button-light"
                                onClick={() => this.closeWebSocket()}
                            >
                                {this.T("wsEnd")}
                            </button>
                        ) : (
                            <button
                                className="_button-light"
                                onClick={() => this.startWebSocket()}
                            >
                                {this.T("wsStart")}
                            </button>
                        )}
                    </div>
                )}

                <div className="content-basic__header">
                    <div className="content-basic__header-text">
                        {moment(
                            this.dsStateDetails.data.magazineState.stateDate
                        ).format("YYYY-MM-DD")}
                        <br />
                        {
                            this.dsStateDetails.data.magazineState
                                .magazineLocation.label
                        }
                    </div>
                </div>
                <div className="content-basic__content">
                    <Overlay data={this.overlayData} />
                    {!this.state.isEdit ? (
                        <div className="_magazine-state-details">
                            {this.dsStateDetails.data.products.map(product => (
                                <div
                                    className="_magazine-state-details__product"
                                    key={product.productListItem.productId}
                                >
                                    <div className="_magazine-state-details__product-content">
                                        <div className="_magazine-state-details__product-content-title">
                                            {this.T(
                                                product.productListItem
                                                    .productName
                                            )}
                                        </div>
                                        <div className="_magazine-state-details__product-content-ean">
                                            {product.productListItem.ean}
                                        </div>
                                        <div className="_magazine-state-details__product-content-count">
                                            {
                                                product.magazineStateProduct
                                                    .totalCount
                                            }
                                        </div>
                                        <div
                                            className="_magazine-state-details__product-content-arrow"
                                            onClick={() =>
                                                this.changeProduct(
                                                    product.productListItem
                                                        .productId
                                                )
                                            }
                                        >
                                            {product.productListItem
                                                .isExpiration
                                                ? this.state.open[
                                                      product.productListItem
                                                          .productId
                                                  ]
                                                    ? "keyboard_arrow_up"
                                                    : "keyboard_arrow_down"
                                                : null}
                                        </div>
                                    </div>
                                    {product.productListItem.isExpiration &&
                                    this.state.open[
                                        product.productListItem.productId
                                    ] ? (
                                        <div className="_magazine-state-details__product-counts">
                                            {product.magazineStateProduct.counts.map(
                                                count => (
                                                    <div
                                                        className="_magazine-state-details__product-count"
                                                        key={count.expireDate}
                                                    >
                                                        <div className="_magazine-state-details__product-count-date">
                                                            {moment(
                                                                count.expireDate
                                                            ).format(
                                                                "YYYY-MM-DD"
                                                            )}
                                                        </div>
                                                        <div className="_magazine-state-details__product-count-count">
                                                            {count.countActual}
                                                        </div>
                                                    </div>
                                                )
                                            )}
                                        </div>
                                    ) : null}
                                </div>
                            ))}
                            {this.dsStateDetails.data.products.length === 0 ? (
                                <div className="">Brak produktów</div>
                            ) : null}
                        </div>
                    ) : (
                        <div className="_magazine-state-details">
                            {this.state.products.map(product => (
                                <div
                                    className="_magazine-state-details__product"
                                    key={product.product.productId}
                                >
                                    <div className="_magazine-state-details__product-content">
                                        <div className="_magazine-state-details__product-content-title">
                                            {this.T(
                                                product.product.productName
                                            )}
                                        </div>
                                        <div className="_magazine-state-details__product-content-ean">
                                            {product.product.ean}
                                        </div>
                                        <div className="_magazine-state-details__product-content-count"></div>
                                        <div
                                            className="_magazine-state-details__product-content-arrow"
                                            onClick={() =>
                                                this.removeProduct(
                                                    product.product.productId
                                                )
                                            }
                                        >
                                            close
                                        </div>
                                    </div>

                                    <div className="_magazine-state-details__product-counts">
                                        {product.counts.map(count => (
                                            <div
                                                className="_magazine-state-details__product-count no-padding"
                                                key={count.expireDate || "---"}
                                            >
                                                <div
                                                    className="_magazine-state-details__product-count-date"
                                                    onClick={() =>
                                                        product.product.isExpiration &&
                                                        this.modalSelectDateOpen({
                                                            product: product.product,
                                                            isExpiration: product.product.isExpiration,
                                                            expireDate: count.expireDate,
                                                            count: count.count
                                                        })
                                                    }
                                                >
                                                    {!!count.expireDate
                                                        ? moment(
                                                              count.expireDate
                                                          ).format("YYYY-MM-DD")
                                                        : "---"}
                                                </div>
                                                <div className="_magazine-state-details__product-count-count">
                                                    <input
                                                        type="text"
                                                        value={count.count}
                                                        onChange={e =>
                                                            this.updateCount(
                                                                product.product
                                                                    .productId,
                                                                count.expireDate,
                                                                e.target.value
                                                            )
                                                        }
                                                    />
                                                </div>
                                                <div
                                                    className="_magazine-state-details__product-count-arrow"
                                                    onClick={() =>
                                                        !!product.product
                                                            .isExpiration &&
                                                        this.removeProductExpireDate(
                                                            product.product
                                                                .productId,
                                                            count.expireDate
                                                        )
                                                    }
                                                >
                                                    {product.product
                                                        .isExpiration
                                                        ? "close"
                                                        : ""}
                                                </div>
                                            </div>
                                        ))}
                                        {product.product.isExpiration ? (
                                            <div className="_magazine-state-details__product-count no-padding">
                                                <div className="_magazine-state-details__product-count-date"></div>
                                                <div className="_magazine-state-details__product-count-count"></div>
                                                <div
                                                    className="_magazine-state-details__product-count-arrow"
                                                    onClick={() =>
                                                        !!product.product.isExpiration &&
                                                        this.modalSelectDateOpen({
                                                            product: product.product,
                                                            isExpiration: product.product.isExpiration
                                                        })
                                                    }
                                                >
                                                    add
                                                </div>
                                            </div>
                                        ) : null}
                                    </div>
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            </div>
        );
    }

    private removeProduct(productId: number) {
        this.setState(p => ({
            products: p.products.filter(p => p.product.productId !== productId)
        }));
    }

    private updateCount(
        productId: number,
        expireDate: string | undefined,
        countValue: string
    ) {
        this.setState(p => ({
            products: p.products.map(product =>
                product.product.productId === productId
                    ? {
                          ...product,
                          counts: product.counts.map(count => {
                              if (count.expireDate === expireDate) {
                                  return {
                                      expireDate: count.expireDate,
                                      count: countValue
                                  };
                              }
                              return count;
                          })
                      }
                    : product
            )
        }));
    }

    private removeProductExpireDate(
        productId: number,
        exprieDate: string | undefined
    ) {
        this.setState(p => ({
            products: p.products.map(product =>
                product.product.isExpiration &&
                product.product.productId === productId
                    ? {
                          ...product,
                          counts: product.counts.filter(
                              count => count.expireDate !== exprieDate
                          )
                      }
                    : product
            )
        }));
    }

    private changeProduct(productId: number) {
        this.setState(p => ({
            open: { ...p.open, [productId]: !p.open[productId] }
        }));
    }

    private startEdit() {
        this.setState(p => {
            if (!p.datasource.MagazineGetStateDetails.response) {
                return p;
            }

            return {
                ...p,
                isEdit: true,
                products: p.datasource.MagazineGetStateDetails.response.products.map(
                    p => ({
                        product: p.productListItem,
                        counts: p.magazineStateProduct.counts.map(count => ({
                            expireDate: count.expireDate,
                            count: count.countActual.toString()
                        }))
                    })
                )
            };
        });

        this.startKeyboardWathing();
    }

    private async saveAndClose() {
        if (!this.state.magazineStateId) {
            return;
        }
        await this.saveEdit();
        this.setState(
            p => ({ isEdit: false, products: [] }),
            () =>
                !!this.state.magazineStateId &&
                this.dsStateDetails.request({
                    params: { magazineStateId: this.state.magazineStateId! }
                })
        );
    }

    private async saveEdit() {
        if (!this.state.magazineStateId || !this.validateCounts()) {
            console.log("not saving");
            return;
        }
        const magazineStateProductUpdateData: PostMagazineStateProductUpdateData = {
            magazineStateId: this.state.magazineStateId,
            products: this.state.products.map(product => ({
                productId: product.product.productId,
                counts: product.counts.map(count => ({
                    countActual: parseInt(count.count),
                    expireDate: count.expireDate
                }))
            }))
        };
        await this.dsStateProductUpdate.request({
            data: magazineStateProductUpdateData
        });
    }

    private validateCounts() {
        return this.state.products.reduce(
            (res, product) =>
                res &&
                product.counts.reduce(
                    (res2, count) =>
                        !!res2 && Number.isInteger(parseInt(count.count)),
                    true as boolean
                ),
            true as boolean
        );
    }

    private cancelEdit() {
        this.setState(p => ({ isEdit: false, products: [] }));
        this.endKeyboardWatching();
    }

    private async modalSelectDateOpen(props: SelectDateModalProps) {
        console.log(props);
        props.expireDate = !!props.expireDate
            ? moment(props.expireDate).format("YYYY-MM-DD")
            : undefined;
        return new Promise(resolve => {
            this.setState(
                p => ({
                    modal: {
                        ...p.modal,
                        selectDate: {
                            open: true,
                            props: props
                        }
                    }
                }),
                () => resolve()
            );
        });
    }

    private async modalSelectDateClose(data: SelectDateModalResponseData) {
        await this.modalSelectDateDismiss();
        await new Promise(resolve => {
            this.setState(
                p => ({
                    products: p.products.map(product =>
                        product.product.productId === data.productId
                            ? {
                                  ...product,
                                  counts: product.product.isExpiration
                                      ? product.counts.findIndex(e =>
                                            moment(e.expireDate).isSame(
                                                data.expireDate,
                                                "day"
                                            )
                                        ) === -1
                                          ? [
                                                ...product.counts,
                                                {
                                                    expireDate: data.expireDate,
                                                    count: data.count.toString()
                                                }
                                            ]
                                          : product.counts.map(count =>
                                                moment(count.expireDate).isSame(
                                                    data.expireDate,
                                                    "day"
                                                )
                                                    ? {
                                                          ...count,
                                                          count: (
                                                              parseInt(
                                                                  count.count
                                                              ) + data.count
                                                          ).toString()
                                                      }
                                                    : count
                                            )
                                      : product.counts.length === 0
                                      ? [
                                            {
                                                expireDate: undefined,
                                                count: data.count.toString()
                                            }
                                        ]
                                      : [
                                            {
                                                expireDate: undefined,
                                                count: (
                                                    data.count +
                                                    parseInt(
                                                        product.counts[0].count
                                                    )
                                                ).toString()
                                            }
                                        ]
                              }
                            : product
                    )
                }),
                () => resolve()
            );
        });
        return await this.saveEdit();
    }

    private async modalSelectDateDismiss() {
        return new Promise(resolve => {
            this.setState(
                p => ({
                    modal: {
                        ...p.modal,
                        selectDate: { open: false, props: undefined }
                    }
                }),
                () => resolve()
            );
        });
    }

    private async modalAddProductOpen() {
        return new Promise(resolve => {
            this.setState(
                p => ({
                    modal: {
                        ...p.modal,
                        addProduct: { open: true }
                    }
                }),
                () => resolve()
            );
        });
    }

    private async modalAddProductClose(
        data: MagazineStateSelectProductModalResponse
    ) {
        await this.modalAddProductDismiss();

        if (
            this.state.products.findIndex(
                e => e.product.productId === data.product.productId
            ) === -1
        ) {
            await this.insertNewProduct(data);
        }

        this.modalSelectDateOpen({ product: data.product, isExpiration: data.product.isExpiration });
    }

    private async insertNewProduct(
        data: MagazineStateSelectProductModalResponse
    ) {
        return new Promise(resolve => {
            this.setState(
                p => {
                    if (data.product.isExpiration) {
                        p.products.push({
                            product: data.product,
                            counts: []
                        });
                    } else {
                        p.products.push({
                            product: data.product,
                            counts: [{ expireDate: undefined, count: "0" }]
                        });
                    }
                },
                () => resolve()
            );
        });
    }

    private async modalAddProductDismiss() {
        return new Promise(resolve => {
            this.setState(
                p => ({
                    modal: {
                        ...p.modal,
                        addProduct: { open: false }
                    }
                }),
                () => resolve()
            );
        });
    }

    private async modalFindBarcodeOpen() {
        this.setState(p => ({
            modal: { ...p.modal, findBarcode: { open: true } }
        }));
    }

    private async modalFindBarcodeDismiss() {
        this.setState(p => ({
            modal: { ...p.modal, findBarcode: { open: false } }
        }));
    }

    private async modalFindBarcodeClose(barcode: string) {
        const response = await this.dsBarcodeFind.request({
            params: {
                barcode: barcode
            }
        });
        this.modalFindBarcodeDismiss();
        if (!!response && !!response.product) {
            this.modalAddProductClose({ product: response.product });
            this.modalSelectDateOpen({
                product: response.product,
                isExpiration: response.product.isExpiration
            });
        } else {
            this.modalFindProductForBarcodeOpen(barcode);
        }
    }

    private async modalFindProductForBarcodeOpen(barcode: string) {
        this.setState(p => ({
            modal: {
                ...p.modal,
                findProductForBarcode: { open: true, barcode: barcode }
            }
        }));
    }

    private async modalFindProductForBarcodeDismiss() {
        this.setState(p => ({
            modal: {
                ...p.modal,
                findProductForBarcode: {
                    ...p.modal.findProductForBarcode,
                    open: false
                }
            }
        }));
    }

    private async modalFindProductForBarcodeClose(
        data: MagazineStateSelectProductModalResponse
    ) {
        if (!!this.state.modal.findProductForBarcode.barcode) {
            await this.dsBarcodeProductInsert.request({
                data: {
                    productId: data.product.productId,
                    barcode: this.state.modal.findProductForBarcode.barcode
                }
            });
            this.modalAddProductClose(data);
            this.modalSelectDateOpen({ product: data.product, isExpiration: data.product.isExpiration });
        }

        this.modalFindProductForBarcodeDismiss();
    }

    private startWebSocket() {
        this.ws = new WebSocket("ws://localhost:4201");

        this.ws.onopen = () => {
            console.log("websocket opened");
            this.setState({ wsState: true });
        };

        this.ws.onclose = () => {
            console.log("websocket closed");
            this.setState({ wsState: false });
            this.ws = undefined;
        };

        this.ws.onmessage = msg => {
            try {
                const data = JSON.parse(msg.data);
                if (data.type === "BarcodeScan") {
                    this.modalFindBarcodeClose(data.barcode);
                }
            } catch (e) {
                console.log(e);
            }
        };
    }

    private closeWebSocket() {
        if (!this.ws) {
            return;
        }

        this.ws.close();
    }

    private startKeyboardWathing() {
        const watcher = (e: KeyboardEvent) => {
            // console.log(e);

            const char = e.key;

            const timestamp = moment().valueOf();

            if (timestamp - this.currentScan.timestamp > 500) {
                this.currentScan.value = "";
            }

            this.currentScan.value += char;

            this.currentScan.timestamp = timestamp;

            if (!!this.currentScan.timeout) {
                window.clearTimeout(this.currentScan.timeout);
            }

            this.currentScan.timeout = window.setTimeout(() => {
                console.log("set timeout");
                console.log(this.currentScan.value);
                if (this.currentScan.value.length >= 6) {
                    this.modalFindBarcodeClose(this.currentScan.value);
                }
            }, 500);
        };

        this.keyboardWatcher = watcher;

        window.addEventListener("keydown", this.keyboardWatcher);
    }

    private endKeyboardWatching() {
        if (!!this.keyboardWatcher) {
            window.removeEventListener("keydown", this.keyboardWatcher);
        }
    }
}
