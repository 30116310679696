import BasicFormComponent from "../../../../components/basicFormComponent/BasicFormComponent";
import React from "react";

import appTranslate from "../../../../appTranslate.json";
import axios from "axios";

import TextField from "../../../../components/form/TextField";

import { checkNotEmptyText } from "../../../../helpers/validator/Validator";

import Modal from "../../../../components/modal/Modal";
import Loader from "../../../../components/basic/Loader";

class EditModal extends BasicFormComponent {
    TObject = appTranslate.areas.company.company.editModal;

    constructor(props) {
        super(props);

        this.state = {
            form: {
                companyName: { value: "", error: "", checks: [checkNotEmptyText] },
                nip: { value: "", error: "", checks: [checkNotEmptyText] }
            },
            loader: true
        };
    }

    componentDidMount() {
        this.fetchData();
    }

    fetchData = () => {
        this.setLoader(true);
        this.context.authService
            .getAuthHeader()
            .then(authHeader => {
                return axios({
                    url: GEAPP_ADMIN_CONFIG.adminApiUrl + "/company/company/details/" + this.props.params.id, // eslint-disable-line no-undef
                    method: "GET",
                    headers: {
                        ...authHeader,
                        "Content-Type": "application/json"
                    }
                })
                    .then(response => {
                        console.log(response);
                        if (response.status === 200 && response.data.code === 200) {
                            const company = response.data.data;
                            this.setLoader(false);
                            this.setState(prevState => {
                                return {
                                    form: {
                                        ...prevState.form,
                                        companyName: {
                                            ...prevState.form.companyName,
                                            value: company.companyName
                                        },
                                        nip: {
                                            ...prevState.form.nip,
                                            value: company.nip
                                        }
                                    }
                                };
                            });
                        }
                    })
                    .catch(error => {
                        console.log(error);
                    });
            })
            .catch(e => {
                console.log(e);
            })
            .finally(() => {
                // this.setLoader('properties', false);
            });
    };

    prepareData = () => {
        return {
            companyId: this.props.params.id,
            companyName: this.state.form.companyName.value,
            nip: this.state.form.nip.value
        };
    };

    onSubmit = () => {
        console.log(this.prepareData());
        if (this.validateFields()) {
            this.submit();
        }
    };

    submit = () => {
        this.setLoader(true);
        this.context.authService
            .getAuthHeader()
            .then(authHeader => {
                return axios({
                    url: GEAPP_ADMIN_CONFIG.adminApiUrl + "/company/company/update",// eslint-disable-line no-undef
                    method: "POST",
                    headers: {
                        ...authHeader,
                        "Content-Type": "application/json"
                    },
                    data: {
                        company: this.prepareData()
                    }
                })
                    .then(response => {
                        console.log(response);
                        this.props.close(true);
                    })
                    .catch(error => {
                        console.log(error);
                    });
            })
            .catch(e => {
                console.log(e);
            })
            .finally(() => {
                // this.setLoader('properties', false);
            });
    };

    render() {
        const footer = (
            <div className="space-between">
                <button className="_button-light" onClick={() => this.onSubmit()}>
                    {this.T("save")}
                </button>

                <button className="_button-light" onClick={() => this.props.close(false)}>
                    {this.T("cancel")}
                </button>
            </div>
        );

        let loader = null;
        if (this.state.loader) {
            loader = <Loader />;
        }

        const content = (
            <div>
                {loader}
                <TextField
                    field={this.state.form.companyName}
                    onInput={value => this.onInputValue("companyName", value)}
                    onBlur={() => this.validateField("companyName")}
                    label={this.T("companyName")}
                />
                <TextField
                    field={this.state.form.nip}
                    onInput={value => this.onInputValue("nip", value)}
                    onBlur={() => this.validateField("nip")}
                    label={this.T("nip")}
                />
            </div>
        );
        return (
            <Modal
                title={this.T("title")}
                close={result => this.props.close(result)}
                footer={footer}
                content={content}
                visible={true}
            />
        );
    }
}

export default EditModal;
