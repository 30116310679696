import React from "react";
import { useState } from "react";
import { ProductListItem } from "../../../../../../../Api/types/src/domain/catalogue/ProductList.types";
import { GetProductSearch } from "../../../../endpoints/catalogue/GetProductSearchNew";
import { PostStartScreenChange } from "../../../../endpoints/catalogue/PostStartScreenChange";
import { useDataSource } from "../../../../hooks/useDataSource";
import { useTranslate } from "../../../../hooks/useTranslate";
import ModalWrapper from "../../../../utils/ModalWrapper";

import "./Add.scss";

interface Props {
    close: () => void;
}

const StartScreenAdd: React.FC<Props> = ({ close }) => {
    const [search, setSearch] = useState("");
    const dsSearch = useDataSource(GetProductSearch);

    const list = dsSearch.state.status === "completed" ? dsSearch.state.response.data.productList : [];

    return (
        <ModalWrapper
            title="Dodaj produkt"
            submit="Gotowe"
            cancel="Anuluj"
            onSubmit={() => close()}
            onCancel={() => close()}
        >
            <div className="start-screen-add__search-input _search-input">
                <input
                    type="text"
                    value={search}
                    onChange={(event) => setSearch(event.target.value)}
                    className="_search-input__input"
                />
                <button className="_button-light _search-input-button" onClick={() => submit()}>
                    Szukaj
                </button>
            </div>
            <div className="start-screen-add__search-results">
                {list.map((p) => (
                    <SearchItem key={p.productId} product={p} />
                ))}
            </div>
        </ModalWrapper>
    );

    function submit() {
        if (search.length < 3) return;
        dsSearch.load({ params: { searchValue: search } });
    }
};

export default StartScreenAdd;

const SearchItem: React.FC<{ product: ProductListItem }> = ({ product }) => {
    const T = useTranslate();
    const dsChange = useDataSource(PostStartScreenChange);

    const status = dsChange.state.status;

    return (
        <div className="start-screen-add-item">
            <div className="start-screen-add-item__info">
                <div className="start-screen-add-item__name">{T(product.productName)}</div>
                <div className="start-screen-add-item__ean">{product.ean}</div>
            </div>
            <div className="start-screen-add-item__button">
                <button
                    className={`_button-light small ${status === "completed" ? "isSelected" : ""}`}
                    onClick={() => add()}
                >
                    {product.isStartScreen
                        ? "Dodano"
                        : status === "idle"
                        ? "Dodaj"
                        : status === "pending"
                        ? "Dodawanie..."
                        : status === "completed"
                        ? "Dodano"
                        : "Błąd"}
                </button>
            </div>
        </div>
    );

    function add() {
        if (product.isStartScreen) return;
        if (dsChange.state.status === "idle" || dsChange.state.status === "error") {
            dsChange.load({ data: { productId: product.productId, status: true } });
        }
    }
};
