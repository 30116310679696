import { DataSourceNew } from "../../utils/endpoint/DataSourceNew";
import { DataSourceFunction } from "../../utils/endpoint/DataSourceNew.function.types";

import { GetMagazineLocationListResponse } from "../../../../../Api/types/src/api/magazine/GetMagazineLocationList.types";

export const GetLocationListDataSource: DataSourceFunction<
    "MagazineGetLocationList",
    undefined,
    undefined,
    GetMagazineLocationListResponse
> = parent =>
    new DataSourceNew(
        {
            name: "MagazineGetLocationList",
            endpointConfig: {
                url: "/magazine/location/list",
                method: "GET"
            }
        },
        parent
    );
