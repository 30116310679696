import React from "react";
import ModalWrapper from "../../../../../../utils/ModalWrapper";

import { ProductListItem } from "../../../../../../../../../Api/types/src/domain/catalogue/ProductList.types";

import { DataSourceStorageInitial } from "../../../../../../utils/endpoint/DataSourceNew";
import { DataSourceFunctionGetStorage } from "../../../../../../utils/endpoint/DataSourceNew.function.types";
import { GetStateDetailsDataSource } from "../../../../../../endpoints/catalogue/GetProductSearch";
import { BasicComponent } from "../../../../../../utils/BasicComponent/BasicComponent";

import "./SelectProductModal.scss";

import TO from "./SelectProductModal.json";

export interface MagazineStateSelectProductModalResponse {
    product: ProductListItem;
}

export interface MagazineStateSelectProductModalProps {
    onClose: (data: MagazineStateSelectProductModalResponse) => void;
    onDismiss: () => void;
}

export interface MagazineStateSelectProductModalState {
    datasource: {
        CatalogueGetProductSearch: DataSourceFunctionGetStorage<typeof GetStateDetailsDataSource>;
    };
    searchValue: string;
}

export default class SelectDateModal extends BasicComponent<
    MagazineStateSelectProductModalProps,
    MagazineStateSelectProductModalState
> {
    protected TranslateObject = {};

    state: MagazineStateSelectProductModalState = {
        datasource: {
            CatalogueGetProductSearch: DataSourceStorageInitial
        },
        searchValue: ""
    };

    private dsProductSearch = GetStateDetailsDataSource(this);

    render() {
        return (
            <ModalWrapper
                title={this.T(TO.title)}
                submit={this.T(TO.close)}
                cancel={this.T(TO.cancel)}
                onSubmit={() => this.props.onDismiss()}
                onCancel={() => this.props.onDismiss()}
            >
                <div className="_add-product-modal">
                    <div className="_add-product-modal__searchbar">
                        <input
                            className="_add-product-modal__searchbar-input"
                            type="text"
                            value={this.state.searchValue}
                            onChange={e => this.setState({ searchValue: e.target.value })}
                            placeholder={this.T(TO.searchPlaceholder)}
                            onKeyDown={e => this.onKeyDown(e as any)}
                        />
                        <button className="_add-product-modal__searchbar-button" onClick={() => this.onSearch()}>
                            {this.T(TO.search)}
                        </button>
                    </div>
                    <div className="_add-product-modal__list">
                        {this.dsProductSearch.state === "ready" && !!this.dsProductSearch.data ? (
                            this.dsProductSearch.data.productList.length > 0 ? (
                                this.dsProductSearch.data.productList.map(product => (
                                    <div className="_add-product-modal__product" key={product.productId}>
                                        <div className="_add-product-modal__product-content">
                                            <div className="_add-product-modal__product-content-title">
                                                {this.T(product.productName)}
                                            </div>
                                            <div className="_add-product-modal__product-content-ean">{product.ean}</div>
                                            <div className="_add-product-modal__product-content-category">
                                                {this.T(product.categoryMain.label)} > {this.T(product.category.label)}
                                            </div>
                                        </div>
                                        <div className="_add-product-modal__product-button">
                                            <button
                                                className="button-light small"
                                                onClick={() => this.props.onClose({ product: product })}
                                            >
                                                {this.T(TO.add)}
                                            </button>
                                        </div>
                                    </div>
                                ))
                            ) : (
                                <div>Brak wyników</div>
                            )
                        ) : null}
                    </div>
                </div>
            </ModalWrapper>
        );
    }

    private onSearch() {
        if (this.state.searchValue.length < 3) {
            return;
        }
        if (this.dsProductSearch.state === "pending") {
            return;
        }
        this.dsProductSearch.request({ params: { searchValue: this.state.searchValue } });
    }

    private onKeyDown(e: KeyboardEvent) {
        if (e.key === "Enter") {
            this.onSearch();
        }
    }
}
