import React from 'react';

interface Props {
    label: string;
    value: string | number | React.ReactFragment;
}

const Element: React.FC<Props> = ({ label, value }) => (
    <div className='product-details__row'>
        <div className='product-details__name'>
            <div className='product-details__name-main'>{value}</div>
            <div className='product-details__name-second'>{label}</div>
        </div>
    </div>
);

export default Element;
