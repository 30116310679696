import BasicFormComponent from '../../../../components/basicFormComponent/BasicFormComponent';
import React from 'react';

import appTranslate from '../../../../appTranslate.json';
import axios from 'axios';
import moment from 'moment';

import TextField from '../../../../components/form/TextField';
import SelectField from '../../../../components/form/SelectField';

import { checkNotEmptyText, checkValidDate } from '../../../../helpers/validator/Validator';

import Modal from '../../../../components/modal/Modal';
import Loader from '../../../../components/basic/Loader';

class EditModal extends BasicFormComponent {
    TObject = appTranslate.areas.contracts.order.changeStatusModal;

    constructor(props) {
        super(props);

        this.state = {
            orderStatus: [],
            details: undefined,
            form: {
                contractOrderStatusId: {
                    value: undefined,
                    error: ''
                },
                reason: {
                    value: ''
                }
            },
            loader: true
        };
    }

    componentDidMount() {
        this.fetchData();
    }

    fetchData = () => {
        this.setLoader(true);
        this.context.authService
            .getAuthHeader()
            .then(authHeader => {
                return axios({
                    url: GEAPP_ADMIN_CONFIG.adminApiUrl + '/contract/order/changeStatus/data/' + this.props.params.id,// eslint-disable-line no-undef
                    method: 'GET',
                    headers: {
                        ...authHeader,
                        'Content-Type': 'application/json'
                    }
                })
                    .then(response => {
                        console.log(response);
                        if (response.status === 200 && response.data.code === 200) {
                            const data = response.data.data.details;
                            this.setLoader(false);
                            this.setState(prevState => {
                                return {
                                    form: {
                                        ...prevState.form,
                                        contractOrderStatusId: {
                                            ...prevState.form.contractOrderStatusId,
                                            value: data.contractOrderStatus.contractOrderStatusId
                                        }
                                    },
                                    details: data,
                                    orderStatus: response.data.data.orderStatus
                                };
                            });
                        }
                    })
                    .catch(error => {
                        console.log(error);
                    });
            })
            .catch(e => {
                console.log(e);
            })
            .finally(() => {
                // this.setLoader('properties', false);
            });
    };

    prepareData = () => {
        const form = this.state.form;
        return {
            contractOrderId: this.props.params.id,
            contractOrderStatusId: parseInt(form.contractOrderStatusId.value),
            reason: form.reason.value
        };
    };

    onSubmit = () => {
        console.log(this.validateFields());

        console.log(this.prepareData());

        if (this.validateFields()) {
            this.submit();
        } else {
            console.log();
        }
    };

    submit = () => {
        this.setLoader(true);
        this.context.authService
            .getAuthHeader()
            .then(authHeader => {
                return axios({
                    url: GEAPP_ADMIN_CONFIG.adminApiUrl + '/contract/order/changeStatus',// eslint-disable-line no-undef
                    method: 'POST',
                    headers: {
                        ...authHeader,
                        'Content-Type': 'application/json'
                    },
                    data: {
                        ...this.prepareData()
                    }
                })
                    .then(response => {
                        console.log(response);
                        this.props.close(true);
                    })
                    .catch(error => {
                        console.log(error);
                    });
            })
            .catch(e => {
                console.log(e);
            })
            .finally(() => {
                // this.setLoader('properties', false);
            });
    };

    render() {
        const footer = (
            <div className='space-between'>
                <button className='_button-light' onClick={() => this.onSubmit()}>
                    {this.T('save')}
                </button>

                <button className='_button-light' onClick={() => this.props.close(false)}>
                    {this.T('cancel')}
                </button>
            </div>
        );

        let loader = null;
        if (this.state.loader) {
            loader = <Loader />;
        }

        const content = (
            <div>
                {loader}

                <SelectField
                    label={this.T('status')}
                    value={this.state.form.contractOrderStatusId.value}
                    options={this.state.orderStatus.map(e => {
                        return {
                            value: e.contractOrderStatusId,
                            label: this.T(e.label)
                        };
                    })}
                    onInput={value => this.onInputValue('contractOrderStatusId', value)}
                />

                <TextField
                    field={this.state.form.reason}
                    type='text'
                    onInput={value => this.onInputValue('reason', value)}
                    onBlur={() => this.validateField('reason')}
                    label={this.T('reason')}
                />
            </div>
        );
        return (
            <Modal
                title={this.T('title')}
                close={result => this.props.close(result)}
                footer={footer}
                content={content}
                visible={true}
            />
        );
    }
}

export default EditModal;
