import { LocalizedValue } from "../../../../../Api/types/src/helpers/Localize.types";
import { DataSourceConfig } from "../../hooks/useDataSource";

interface Category {
    categoryId: number;
    label: LocalizedValue<string>;
    ordered: number;
    photoId?: number;
    isVisible: boolean;
    mainCategoryId: number;
    productCount?: number;
}

interface CategoryMain extends Category {
    categories: Category[];
}

interface Response {
    code: 200;
    data: CategoryMain[];
}

export const GetCategoryTree: DataSourceConfig<undefined, undefined, Response> = {
    url: "/catalogue/category/tree",
    method: "GET",
};
