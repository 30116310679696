import BasicFormComponent from "../../../../components/basicFormComponent/BasicFormComponent";
import React from "react";

import appTranslate from "../../../../appTranslate.json";
import axios from "axios";
import moment from "moment";

import TextField from "../../../../components/form/TextField";
import SelectField from "../../../../components/form/SelectField";

import { checkNotEmptyText, checkValidDate } from "../../../../helpers/validator/Validator";

import Modal from "../../../../components/modal/Modal";
import Loader from "../../../../components/basic/Loader";

import "./ChangeProductModal.scss";

class ChangeProductModal extends BasicFormComponent {
    TObject = appTranslate.areas.contracts.order.changeProductModal;

    constructor(props) {
        super(props);

        this.state = {
            products: [],
            form: {},
            loader: true
        };
    }

    componentDidMount() {
        this.fetchData();
    }

    fetchData = () => {
        this.setLoader(true);
        this.context.authService
            .getAuthHeader()
            .then(authHeader => {
                return axios({
                    url: GEAPP_ADMIN_CONFIG.adminApiUrl + "/contract/order/changeProducts/data/" + this.props.params.id, // eslint-disable-line no-undef
                    method: "GET",
                    headers: {
                        ...authHeader,
                        "Content-Type": "application/json"
                    }
                })
                    .then(response => {
                        console.log(response);
                        if (response.status === 200 && response.data.code === 200) {
                            const data = response.data.data;
                            this.setLoader(false);
                            this.setState(prevState => {
                                return {
                                    products: data.map(p => ({
                                        ...p,
                                        usage: p.usage.map(u => {
                                            return {
                                                ...u,
                                                quantityMax: u.quantity - u.quantityUsed,
                                                quantityNew: !!u.quantityCurrent ? u.quantityCurrent : 0
                                            };
                                        }),
                                        included: p.usage.reduce((b, u) => (b ? b : !!u.quantityCurrent), false)
                                    }))
                                };
                            });
                        }
                    })
                    .catch(error => {
                        console.log(error);
                    });
            })
            .catch(e => {
                console.log(e);
            })
            .finally(() => {
                // this.setLoader('properties', false);
            });
    };

    prepareData = () => {
        return {
            data: this.state.products.reduce((t, p) => {
                if (p.included) {
                    t = [
                        ...t,
                        ...p.usage.reduce(
                            (tt, u) =>
                                u.quantityNew > 0
                                    ? [
                                          ...tt,
                                          {
                                              productId: p.productId,
                                              contractId: u.contractId,
                                              quantity: u.quantityNew
                                          }
                                      ]
                                    : tt,
                            []
                        )
                    ];
                }
                return t;
            }, []),
            contractOrderId: parseInt(this.props.params.id)
        };
    };

    onSubmit = () => {
        console.log(this.prepareData());
        console.log("submit");
        this.submit();
        // console.log(this.validateFields());

        // console.log(this.prepareData());

        // if (this.validateFields()) {
        //     // this.submit();
        // } else {
        //     console.log();
        // }
    };

    submit = () => {
        this.setLoader(true);
        this.context.authService
            .getAuthHeader()
            .then(authHeader => {
                return axios({
                    url: GEAPP_ADMIN_CONFIG.adminApiUrl + "/contract/order/changeProduct",// eslint-disable-line no-undef
                    method: "POST",
                    headers: {
                        ...authHeader,
                        "Content-Type": "application/json"
                    },
                    data: {
                        ...this.prepareData()
                    }
                })
                    .then(response => {
                        console.log(response);
                        this.props.close(true);
                    })
                    .catch(error => {
                        console.log(error);
                    });
            })
            .catch(e => {
                console.log(e);
            })
            .finally(() => {
                // this.setLoader('properties', false);
            });
    };

    includeProduct = (productId, state) => {
        this.setState(prev => ({
            products: prev.products.map(p => {
                if (p.productId === productId) {
                    return {
                        ...p,
                        included: state
                    };
                }
                return p;
            })
        }));
    };

    changeQuantityNew = (productId, contractId, value) => {
        console.log(`change quantity: ${productId} ${contractId} ${value}`);
        if (value !== "") {
            value = parseInt(value) || parseInt(value) === 0 ? parseInt(value) : "";
        }

        this.setState(prev => ({
            products: prev.products.map(p => {
                if (p.productId === productId) {
                    return {
                        ...p,
                        usage: p.usage.map(u => {
                            if (u.contractId === contractId) {
                                return {
                                    ...u,
                                    quantityNew: value
                                };
                            }
                            return u;
                        })
                    };
                }
                return p;
            })
        }));
    };

    onBlurQuantityNew = (productId, contractId) => {
        console.log(productId + " " + contractId);
        let product = this.state.products.find(p => p.productId === productId);
        console.log(product);
        if (!product) {
            return;
        }
        let usage = product.usage.find(u => u.contractId === contractId);
        console.log(usage);
        if (!usage) {
            return;
        }
        if (usage.quantityNew === "") {
            this.changeQuantityNew(productId, contractId, 0);
        }

        if (usage.quantityNew > usage.quantity - usage.quantityUsed) {
            this.changeQuantityNew(productId, contractId, usage.quantity - usage.quantityUsed);
        }
    };

    render() {
        const footer = (
            <div className="space-between">
                <button className="_button-light" onClick={() => this.onSubmit()}>
                    {this.T("save")}
                </button>

                <button className="_button-light" onClick={() => this.props.close(false)}>
                    {this.T("cancel")}
                </button>
            </div>
        );

        let loader = null;
        if (this.state.loader) {
            loader = <Loader />;
        }

        const content = (
            <div>
                {loader}
                <div className="order-product-count-modal__wrapper">
                    {this.state.products.map(p => {
                        const total = p.usage.reduce((s, u) => {
                            return s + (parseInt(u.quantityNew) ? parseInt(u.quantityNew) : 0);
                        }, 0);

                        return (
                            <div className="order-product-count-modal__product" key={p.productId}>
                                <div className="order-product-count-modal__product-element">
                                    <div className="order-product-count-modal__product-info">
                                        <div className="order-product-count-modal__product-name">
                                            {this.T(p.productName)}
                                        </div>
                                        <div className="order-product-count-modal__product-ean">{p.ean}</div>
                                        <div className="order-product-count-modal__product-category">
                                            {this.T(p.mainCategoryLabel)} > {this.T(p.categoryLabel)}
                                        </div>
                                    </div>
                                    {p.included ? (
                                        <div className="order-product-count-modal__product-include">
                                            <div className="order-product-count-modal__product-include-total">
                                                {total}
                                            </div>
                                            <div
                                                className="order-product-count-modal__product-include-icon"
                                                onClick={() => {
                                                    this.includeProduct(p.productId, false);
                                                }}
                                            >
                                                close
                                            </div>
                                        </div>
                                    ) : (
                                        <div className="order-product-count-modal__product-include">
                                            <div
                                                className="order-product-count-modal__product-include-icon"
                                                onClick={() => {
                                                    this.includeProduct(p.productId, true);
                                                }}
                                            >
                                                add
                                            </div>
                                        </div>
                                    )}
                                </div>
                                {p.included
                                    ? p.usage.map(u => (
                                          <div className="order-product-count-modal__usage-element" key={u.contractId}>
                                              <div className="order-product-count-modal__usage-contract-name">
                                                  {u.label}
                                              </div>
                                              <div className="order-product-count-modal__usage-contract-quantity">
                                                  <span>{this.T("contract")}</span>
                                                  <span className="value">{u.quantity}</span>
                                              </div>
                                              <div className="order-product-count-modal__usage-contract-quantity">
                                                  <span>{this.T("available")}</span>
                                                  <span className="value">{u.quantity - u.quantityUsed}</span>
                                              </div>
                                              <div className="order-product-count-modal__usage-contract-quantity">
                                                  <span>{this.T("old")}</span>
                                                  <span className="value">
                                                      {u.quantityCurrent ? u.quantityCurrent : 0}
                                                  </span>
                                              </div>
                                              <div className="order-product-count-modal__usage-contract-quantity-input">
                                                  <span>{this.T("new")}</span>
                                                  <input
                                                      type="text"
                                                      value={u.quantityNew}
                                                      onChange={event =>
                                                          this.changeQuantityNew(
                                                              p.productId,
                                                              u.contractId,
                                                              event.target.value
                                                          )
                                                      }
                                                      onBlur={() => this.onBlurQuantityNew(p.productId, u.contractId)}
                                                  />
                                              </div>
                                          </div>
                                      ))
                                    : null}
                            </div>
                        );
                    })}
                </div>
            </div>
        );
        return (
            <Modal
                title={this.T("title")}
                close={result => this.props.close(result)}
                footer={footer}
                content={content}
                visible={true}
            />
        );
    }
}

export default ChangeProductModal;
