import React from "react";
import FormFieldBase from "../FieldBase";
import FormFieldWrapper from "../FieldWrapper";

export type TextFieldValue = string;

const classBase = "form-field";

export function TextFieldInitialState(value?: string) {
    return {
        value: value || "",
        error: [] as string[]
    };
}

export default class TextField extends FormFieldBase<TextFieldValue> {
    render() {
        return (
            <FormFieldWrapper {...this.props}>
                <input
                    className={`${classBase}__text-field-input`}
                    type="text"
                    value={this.props.value}
                    onChange={e => this.props.valueChange(e.target.value)}
                    onFocus={() => this.props.onFocus && this.props.onFocus()}
                    onBlur={() => this.props.onBlur && this.props.onBlur()}
                    onKeyDown={e => this.onKey(e)}
                    id={this.props.id}
                />
            </FormFieldWrapper>
        );
    }
}
