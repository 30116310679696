import React from 'react';
import FormComponent, { FormComponentConfig } from '../../../../../utils/form/FormComponent';
import TextField, { TextFieldInitialState } from '../../../../../utils/form/fields/TextField';
import DatePicker, {
    DatePickerInitialState,
    DatePickerValidateDate
} from '../../../../../utils/form/fields/DatePicker';
import SelectField, { SelectFieldInitialState } from '../../../../../utils/form/fields/SelectField';
import moment from 'moment';
import { MagazineLocation } from '../../../../../../../../Api/types/src/areas/magazine/Location.types';
import { MagazineOrderListModel } from '../../../../../../../../Api/types/src/areas/magazine/Order.types';
import { MagazineOrderStatusModel } from '../../../../../../../../Api/types/src/areas/magazine/OrderStatus.types';

interface FieldList {
    magazineOrderStatusId: SelectField;
    reason: TextField;
}

interface Props {
    statusList: MagazineOrderStatusModel[];
    magazineOrder: MagazineOrderListModel;
}

export default class AddModalForm extends FormComponent<FieldList, Props> {
    state = {
        form: {
            magazineOrderStatusId: SelectFieldInitialState(
                this.props.magazineOrder.magazineOrderStatusId
            ),
            reason: TextFieldInitialState()
        }
    };

    formFieldsConfig: FormComponentConfig<FieldList> = {
        reason: {
            label: 'Comment'
        },
        magazineOrderStatusId: {
            label: 'Order status',
            options: []
        }
    };

    render() {
        return (
            <div>
                <SelectField
                    {...this.fieldProps('magazineOrderStatusId')}
                    options={this.props.statusList.map(l => ({
                        id: l.magazineOrderStatusId,
                        label: this.T(l.label)
                    }))}
                />

                <TextField {...this.fieldProps('reason')} />
            </div>
        );
    }
}
