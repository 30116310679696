import React from 'react';
import { DataSourcesStatus } from './CustomComponent';
import { Language } from '../services/translateService';
import { LocalizedValue } from '../../../../Api/types/src/helpers/Localize.types';
import translation from './DataSourceLoaderTranslate.json';
import Loader from './Loader';
import './DataSourceLoader.scss';

interface DataSourceLoaderProps {
    status: DataSourcesStatus;
    language: Language;
}

function T(object: LocalizedValue<string>, language: Language) {
    return object[language] || '';
}

const DataSourceLoader: React.FC<DataSourceLoaderProps> = ({ status, language }) => {
    switch (status) {
        case 'PENDING':
            return (
                <div className='data-source-loader__wrapper'>
                    <div className='data-source-loader__loader'>
                        <Loader />
                    </div>
                    <div className='data-source-loader__title'>
                        {T(translation.loading, language)}...
                    </div>
                </div>
            );

        case 'ERROR':
            return (
                <div className='data-source-loader__wrapper'>
                    <div className='data-source-loader__title'>
                        {T(translation.error, language)}
                    </div>
                    <div className='data-source-loader__label'>
                        {T(translation.errorText, language)}
                    </div>
                </div>
            );

        default:
            return null;
    }
};

export default DataSourceLoader;
