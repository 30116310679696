import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';

import appTranslate from '../../../../appTranslate.json';
import Loader from '../../../../components/basic/Loader';
import { AppContext } from '../../../../services/context';
import axios from 'axios';

import './Details.scss';

import EditModal from './EditModal';
import ChangeStatusModal from './ChangeStatusModal';
import ChangeProductModal from './ChangeProductModal';
import DeleteModal from './DeleteModal';

// import ProductCount from './ContractProductQuantityModal';

class Details extends Component {
    constructor(props) {
        super(props);

        this.orderId = this.props.match.params.orderId;

        this.state = {
            loader: false,
            order: null,
            productTree: [],
            openTree: {
                main: null,
                secondary: null
            },
            loaders: {
                productTree: false
            },
            modal: {
                edit: {
                    open: false,
                    params: null
                },
                delete: {
                    open: false,
                    params: null
                },
                changeStatus: {
                    open: false,
                    params: null
                },
                changeProducts: {
                    open: false,
                    params: null
                }
            }
        };
    }

    T = (prop, params, r) => {
        return this.context.translateService.translationProxy(
            prop,
            params,
            r,
            appTranslate.areas.contracts.order.details
        );
    };

    modalState = (modal, state, params) => {
        this.setState(prevState => {
            return {
                modal: {
                    ...prevState.modal,
                    [modal]: {
                        open: state,
                        params: params
                    }
                }
            };
        });
    };

    onModalClose = (modal, response) => {
        this.modalState(modal, false, null);

        if (modal === 'delete') {
            if (response) {
                window.location = '/contracts/order';
            }
            return;
        }

        if (response) {
            this.getData();
        }
    };

    componentDidMount() {
        this.getData();
    }

    setLoaderState = state => {
        this.setState({ loader: state });
    };

    setLoader = (loader, state) => {
        this.setState(prev => {
            return {
                loaders: {
                    ...prev.loaders,
                    [loader]: state
                }
            };
        });
    };

    modalState = (modal, state, params) => {
        this.setState(prevState => {
            return {
                modal: {
                    ...prevState.modal,
                    [modal]: {
                        open: state,
                        params: params
                    }
                }
            };
        });
    };

    openTree = (level, id) => {
        this.setState(prev => {
            return {
                openTree: {
                    ...prev.openTree,
                    [level]: prev.openTree[level] === id ? null : id
                }
            };
        });
    };

    getData = () => {
        console.log(this.props.match.params.fileId);
        this.setLoaderState(true);
        this.context.authService
            .getAuthHeader()
            .then(authHeader => {
                return axios({
                    url: GEAPP_ADMIN_CONFIG.adminApiUrl + '/contract/order/details/' + this.orderId,// eslint-disable-line no-undef
                    method: 'GET',
                    headers: {
                        ...authHeader,
                        'Content-Type': 'application/json'
                    }
                })
                    .then(response => {
                        console.log(response);
                        if (response.data.code === 200) {
                            this.setState({ order: response.data.data });
                        }
                    })
                    .catch(error => {
                        console.log(error);
                    });
            })
            .catch(e => {
                console.log(e);
            })
            .finally(() => {
                this.setLoaderState(false);
            });
    };

    render() {
        let loader = <div />;
        if (this.state.loader) {
            loader = <Loader />;
        }

        if (!this.state.order) {
            return <div className='content-basic'>{loader}</div>;
        }

        const elem = this.state.order;

        let editModal = <div />;
        if (this.state.modal.edit.open) {
            editModal = (
                <EditModal
                    close={response => this.onModalClose('edit', response)}
                    params={this.state.modal.edit.params}
                />
            );
        }

        let deleteModal = <div />;
        if (this.state.modal.delete.open) {
            deleteModal = (
                <DeleteModal
                    close={response => this.onModalClose('delete', response)}
                    params={this.state.modal.delete.params}
                />
            );
        }

        let changeStatus = <div />;
        if (this.state.modal.changeStatus.open) {
            changeStatus = (
                <ChangeStatusModal
                    close={response => this.onModalClose('changeStatus', response)}
                    params={this.state.modal.changeStatus.params}
                />
            );
        }

        let changeProducts = <div />;
        if (this.state.modal.changeProducts.open) {
            changeProducts = (
                <ChangeProductModal
                    close={response => this.onModalClose('changeProducts', response)}
                    params={this.state.modal.changeProducts.params}
                />
            );
        }

        return (
            <div className='content-basic'>
                {loader}
                {editModal}
                {deleteModal}
                {changeStatus}
                {changeProducts}
                <div className='content-basic__header'>
                    <button
                        className='_button-light'
                        onClick={() =>
                            this.modalState('edit', true, {
                                id: this.orderId
                            })
                        }
                    >
                        {this.T('edit')}
                    </button>

                    <button
                        className='_button-light'
                        onClick={() =>
                            this.modalState('delete', true, {
                                id: this.orderId
                            })
                        }
                    >
                        {this.T('delete')}
                    </button>

                    <button
                        className='_button-light'
                        onClick={() =>
                            this.modalState('changeStatus', true, {
                                id: this.orderId
                            })
                        }
                    >
                        {this.T('changeStatus')}
                    </button>

                    <button
                        className='_button-light'
                        onClick={() =>
                            this.modalState('changeProducts', true, {
                                id: this.orderId
                            })
                        }
                    >
                        {this.T('changeProducts')}
                    </button>
                </div>
                <div className='content-basic__header'>
                    <div className='product-details'>
                        <div className='product-details__row'>
                            <div className='product-details__name'>
                                <div className='product-details__name-main'>
                                    {this.T('orderLabel')} {elem.contractOrderId}
                                </div>
                                <div className='product-details__name-second'>{this.T('label')}</div>
                            </div>
                        </div>

                        <div className='product-details__row'>
                            <div className='product-details__name'>
                                <div className='product-details__name-main'>
                                    {this.T(elem.contractOrderStatus.label)}
                                </div>
                                <div className='product-details__name-second'>{this.T('status')}</div>
                            </div>
                        </div>

                        <div className='product-details__row'>
                            <div className='product-details__name'>
                                <div className='product-details__name-main'>
                                    {moment(elem.createdAt).format('YYYY-MM-DD HH:mm')}
                                </div>
                                <div className='product-details__name-second'>{this.T('createdAt')}</div>
                            </div>
                        </div>

                        <div className='product-details__row'>
                            <div className='product-details__name'>
                                <div className='product-details__name-main'>
                                    {moment(elem.shipmentDate).format('YYYY-MM-DD')}
                                </div>
                                <div className='product-details__name-second'>{this.T('shipmentDate')}</div>
                            </div>
                        </div>

                        <div className='product-details__row'>
                            <div className='product-details__name'>
                                <div className='product-details__name-main'>
                                    {elem.company.companyName} ({elem.company.nip})
                                </div>
                                <div className='product-details__name-second'>{this.T('company')}</div>
                            </div>
                        </div>

                        <div className='product-details__row'>
                            <div className='product-details__name'>
                                <div className='product-details__name-main'>
                                    {elem.destination.label} <br />
                                    <span className='smaller-text'>
                                        {elem.destination.street} {elem.destination.houseNumber}
                                        <br />
                                        {elem.destination.postalCode} {elem.destination.city}
                                        <br />
                                        {elem.destination.region} {elem.destination.country}
                                        <br />
                                        {elem.destination.telephone}
                                        <br />
                                        {elem.destination.extraInfo}
                                    </span>
                                </div>
                                <div className='product-details__name-second'>{this.T('destination')}</div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='content-basic__header'>
                    <div className='product-details'>
                        <div className='product-details__row'>
                            <div className='product-details__property-group'>{this.T('products')}</div>
                        </div>
                        <div className='product-details__row'>
                            <div className='contract-order-product__wrapper'>
                                {this.state.order && this.state.order.products
                                    ? this.state.order.products.map(p => {
                                          const isOpen = this.state.openTree.main === p.productId;
                                          return (
                                              <div className='contract-order-product__parent' key={p.productId}>
                                                  <div
                                                      className='contract-order-product__parent-element'
                                                      onClick={() => this.openTree('main', p.productId)}
                                                  >
                                                      <div className='contract-order-product__arrow'>
                                                          {isOpen ? 'expand_less' : 'expand_more'}
                                                      </div>
                                                      <div className='contract-order-product__product-name'>
                                                          {this.T(p.productName)}
                                                      </div>
                                                      <div className='contract-order-product__product-ean'>{p.ean}</div>
                                                      <div className='contract-order-product__product-category'>
                                                          {this.T(p.mainCategoryLabel)} > {this.T(p.categoryLabel)}
                                                      </div>
                                                      <div className='contract-order-product__product-quantity'>
                                                          {p.quantity}
                                                      </div>
                                                  </div>
                                                  {isOpen
                                                      ? p.contracts.map(c => {
                                                            return (
                                                                <div
                                                                    className='contract-order-product__child-element'
                                                                    key={c.contractId}
                                                                >
                                                                    <div className='contract-order-product__contract-label'>
                                                                        {c.contractLabel}
                                                                    </div>
                                                                    <div className='contract-order-product__contract-quantity-used'>
                                                                        {c.quantityUsed}
                                                                    </div>
                                                                    <div className='contract-order-product__contract-quantity'>
                                                                        / {c.quantity}
                                                                    </div>
                                                                </div>
                                                            );
                                                        })
                                                      : null}
                                              </div>
                                          );
                                      })
                                    : null}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
Details.contextType = AppContext;

export default Details;
