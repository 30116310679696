import React from "react";
import ReactDOM from "react-dom";
import "./scss/index.scss";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

declare global {
    const GEAPP_ADMIN_CONFIG: {
        adminApiUrl: string;
        apiUrl: string;
        photoUrl: string;
        fileUrl: string;
    };
}

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
