import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";

import appTranslate from "../../../../appTranslate.json";
import Loader from "../../../../components/basic/Loader";
import { AppContext } from "../../../../services/context";
import axios from "axios";

import EditModal from "./EditModal";
import EditPhoto from "./EditPhoto";
import EditBindings from "./EditBindings";

import CopytBindingsModal from "./CopyBindingModal";

import BindingModal from "./BindingToModal";
import FileBindingModal from "./FileBindingModal";

class CatalogueProductDetails extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loader: false,
            product: null,
            editModal: false,
            managePhotosModal: false,
            manageBindingsModal: false,
            modal: {
                binding: {
                    open: false,
                    params: null
                },
                fileBinding: {
                    open: false,
                    params: null
                },
                copyBinding: {
                    open: false,
                    params: null
                }
            },
            tree: {
                bindedProductsTo: [],
                bindedProductsFrom: [],
                bindedFiles: []
            },
            openTree: {
                bindedProductsTo: {
                    main: null,
                    secondary: null
                },
                bindedProductsFrom: {
                    main: null,
                    secondary: null
                },
                bindedFiles: {
                    main: null
                }
            }
        };
    }

    componentDidMount() {
        this.getData();
    }

    T = (prop, params, r) => {
        let p;

        if (typeof prop === "object") {
            p = prop;
        }
        if (typeof prop === "string") {
            p = prop.split(".").reduce((obj, a) => obj[a], appTranslate.areas.catalogue.product.details);
        }
        if (r) {
            return this.context.translateService.getTranslationR(p, params);
        }
        return this.context.translateService.getTranslation(p, params);
    };

    setLoaderState = state => {
        this.setState({ loader: state });
    };

    modalState = (modal, state, params) => {
        this.setState(prevState => {
            return {
                modal: {
                    ...prevState.modal,
                    [modal]: {
                        open: state,
                        params: params
                    }
                }
            };
        });
    };

    getData = () => {
        this.setLoaderState(true);
        this.context.authService
            .getAuthHeader()
            .then(authHeader => {
                return axios({
                    url:
                        GEAPP_ADMIN_CONFIG.adminApiUrl + // eslint-disable-line no-undef
                        "/catalogue/products/product/details/" +
                        this.props.match.params.productId,
                    method: "GET",
                    headers: {
                        ...authHeader,
                        "Content-Type": "application/json"
                    }
                })
                    .then(response => {
                        console.log(response);
                        if (response.data.code === 200) {
                            this.setState({ product: response.data.data });
                        }
                    })
                    .catch(error => {
                        console.log(error);
                    });
            })
            .catch(e => {
                console.log(e);
            })
            .finally(() => {
                this.setLoaderState(false);
            });
    };

    getBindedProductsTo = () => {
        this.setLoaderState(true);
        this.context.authService
            .getAuthHeader()
            .then(authHeader => {
                return axios({
                    url:
                        GEAPP_ADMIN_CONFIG.adminApiUrl + // eslint-disable-line no-undef
                        "/catalogue/products/binding/tree/" +
                        this.props.match.params.productId,
                    method: "GET",
                    headers: {
                        ...authHeader,
                        "Content-Type": "application/json"
                    }
                })
                    .then(response => {
                        console.log(response);
                        if (response.data.code === 200) {
                            this.setState(prev => {
                                return {
                                    tree: {
                                        ...prev.tree,
                                        bindedProductsTo: response.data.data
                                    }
                                };
                            });
                        }
                    })
                    .catch(error => {
                        console.log(error);
                    });
            })
            .catch(e => {
                console.log(e);
            })
            .finally(() => {
                this.setLoaderState(false);
            });
    };

    getBindedProductsFrom = () => {
        this.setLoaderState(true);
        this.context.authService
            .getAuthHeader()
            .then(authHeader => {
                return axios({
                    url:
                        GEAPP_ADMIN_CONFIG.adminApiUrl + // eslint-disable-line no-undef
                        "/catalogue/products/binding/tree/" +
                        this.props.match.params.productId +
                        "?reverse=true",
                    method: "GET",
                    headers: {
                        ...authHeader,
                        "Content-Type": "application/json"
                    }
                })
                    .then(response => {
                        console.log(response);
                        if (response.data.code === 200) {
                            this.setState(prev => {
                                return {
                                    tree: {
                                        ...prev.tree,
                                        bindedProductsFrom: response.data.data
                                    }
                                };
                            });
                        }
                    })
                    .catch(error => {
                        console.log(error);
                    });
            })
            .catch(e => {
                console.log(e);
            })
            .finally(() => {
                this.setLoaderState(false);
            });
    };

    getBindedFiles = () => {
        this.setLoaderState(true);
        this.context.authService
            .getAuthHeader()
            .then(authHeader => {
                return axios({
                    url:
                        GEAPP_ADMIN_CONFIG.adminApiUrl + // eslint-disable-line no-undef
                        "/catalogue/files/fileProduct/fileTreeByProduct/" +
                        this.props.match.params.productId,
                    method: "GET",
                    headers: {
                        ...authHeader,
                        "Content-Type": "application/json"
                    }
                })
                    .then(response => {
                        console.log(response);
                        if (response.data.code === 200) {
                            this.setState(prev => {
                                return {
                                    tree: {
                                        ...prev.tree,
                                        bindedFiles: response.data.data
                                    }
                                };
                            });
                        }
                    })
                    .catch(error => {
                        console.log(error);
                    });
            })
            .catch(e => {
                console.log(e);
            })
            .finally(() => {
                this.setLoaderState(false);
            });
    };

    setValue = (propName, value) => {
        this.setState(prevState => {
            return {
                [propName]: value
            };
        });
    };

    onEditModalClose = () => {
        this.getData();
        this.setValue("editModal", false);
    };

    onEditPhotoClose = () => {
        this.getData();
        this.setValue("managePhotosModal", false);
    };

    onEditBindingClose = () => {
        this.getData();
        this.setValue("manageBindingsModal", false);
    };

    onModalClose = (modal, response) => {
        this.modalState(modal, false, null);
        if (response) {
            // this.getTree();
        }

        if (modal === "fileBinding" && this.state.tree.bindedFiles.length > 0) {
            this.getBindedFiles();
        }

        if ((modal === "binding" || modal === "copyBinding") && this.state.tree.bindedProductsTo.length > 0) {
            this.getBindedProductsTo();
        }
    };

    openTree = (tree, level, id) => {
        this.setState(prev => {
            return {
                openTree: {
                    ...prev.openTree,
                    [tree]: {
                        ...prev.openTree[tree],
                        [level]: prev.openTree[tree][level] === id ? null : id
                    }
                }
            };
        });
    };

    deleteProduct = async () => {
        if (!window.confirm(this.T("deleteProductConfirm"))) {
            return;
        }
        if (!window.confirm(this.T("deleteProductConfirm2"))) {
            return;
        }
        this.setLoaderState(true);
        try {
            const authHeader = await this.context.authService.getAuthHeader();
            await axios({
                url: GEAPP_ADMIN_CONFIG.adminApiUrl + "/v2/catalogue/ProductDelete", // eslint-disable-line no-undef
                method: "POST",
                headers: { ...authHeader, "Content-Type": "application/json" },
                data: {
                    productId: this.props.match.params.productId
                }
            });
            // this.props.history.push({ pathname: "/catalogue/product" });
            window.location = "/catalogue/product";
        } catch (e) {
            console.log(e);
            alert("Error while deleting product");
            this.setLoaderState(false);
        }
    };

    render() {
        let loader = <div />;
        let editModal = <div />;
        let editPhoto = <div />;
        let editBindings = <div />;

        if (this.state.loader) {
            loader = <Loader />;
        }

        if (this.state.editModal) {
            editModal = <EditModal visible={true} productId={this.props.match.params.productId} close={() => this.onEditModalClose()} />;
        }

        if (this.state.managePhotosModal) {
            editPhoto = <EditPhoto parentState={this.state} close={() => this.onEditPhotoClose()} refresh={() => this.getData()} />;
        }

        if (this.state.manageBindingsModal) {
            editBindings = <EditBindings parentState={this.state} close={() => this.onEditBindingClose()} refresh={() => this.getData()} />;
        }

        let bindingModal = <div />;
        if (this.state.modal.binding.open) {
            bindingModal = <BindingModal close={response => this.onModalClose("binding", response)} params={this.state.modal.binding.params} />;
        }

        let copyBindingModal = <div />;
        if (this.state.modal.copyBinding.open) {
            bindingModal = <CopytBindingsModal close={response => this.onModalClose("copyBinding", response)} params={this.state.modal.copyBinding.params} />;
        }

        let fileBindingModal = <div />;
        if (this.state.modal.fileBinding.open) {
            fileBindingModal = <FileBindingModal close={response => this.onModalClose("fileBinding", response)} params={this.state.modal.fileBinding.params} />;
        }

        if (!this.state.product) {
            return <div className="content-basic">{loader}</div>;
        }

        let name = {};

        this.state.product.propertyGroups.forEach(pg => {
            pg.properties.forEach(p => {
                if (p.productPropertyDefinition.productPropertyDefinitionId === 1) {
                    name = p.content;
                }
            });
        });

        let photos = <div />;

        if (this.state.product.photos.length > 0) {
            photos = (
                <div className="content-basic__header">
                    <div className="product-details__photos">
                        {this.state.product.photos.map(photo => {
                            return (
                                <div className={`product-details__photo ${photo.isMain ? "is-main" : ""}`} key={photo.photoId}>
                                    <img
                                        src={`${
                                            GEAPP_ADMIN_CONFIG.photoUrl // eslint-disable-line no-undef
                                        }${photo.filename}`}
                                        alt=""
                                    />
                                </div>
                            );
                        })}
                    </div>
                </div>
            );
        }

        const bindedFiles = this.state.tree.bindedFiles.reduce((p, g) => {
            if (g.files.length === 0) {
                return p;
            }
            p.push(
                <div className="details-tree__main" key={g.fileGroupId}>
                    <div className="details-tree__main-content" onClick={() => this.openTree("bindedFiles", "main", g.fileGroupId)}>
                        <div className="details-tree__main-expand-icon">
                            {this.state.openTree.bindedFiles.main === g.fileGroupId ? "expand_less" : "expand_more"}
                        </div>
                        <div className="details-tree__main-label">
                            {this.T(g.label)} <span className="small-label">{g.files.length}</span>
                        </div>
                    </div>
                    {this.state.openTree.bindedFiles.main === g.fileGroupId
                        ? g.files.map(f => {
                              return (
                                  <div className="details-tree__element" key={f.fileId}>
                                      <div className="details-tree__element-label">{this.T(f.label)}</div>
                                      <div className="details-tree__element-ean" />
                                      <div className="details-tree__element-buttons">
                                          <Link to={`/catalogue/files/${f.fileId}/details`} className="_button-light small">
                                              {this.T("show")}
                                          </Link>
                                      </div>
                                  </div>
                              );
                          })
                        : null}
                </div>
            );
            return p;
        }, []);

        const bindedProductsTo = this.state.tree.bindedProductsTo.reduce((t, c) => {
            if (c.productCount === 0) {
                return t;
            }
            t.push(
                <div className="details-tree__main" key={c.categoryId}>
                    <div className="details-tree__main-content" onClick={() => this.openTree("bindedProductsTo", "main", c.categoryId)}>
                        <div className="details-tree__main-expand-icon">
                            {this.state.openTree.bindedProductsTo.main === c.categoryId ? "expand_less" : "expand_more"}
                        </div>
                        <div className="details-tree__main-label">
                            {this.T(c.label)} <span className="small-label">{c.productCount}</span>
                        </div>
                    </div>
                    {this.state.openTree.bindedProductsTo.main === c.categoryId
                        ? c.categories.reduce((tt, cc) => {
                              if (cc.productCount === 0) {
                                  return tt;
                              }

                              tt.push(
                                  <div className="details-tree__child" key={cc.categoryId}>
                                      <div
                                          className="details-tree__child-content"
                                          onClick={() => this.openTree("bindedProductsTo", "secondary", cc.categoryId)}
                                      >
                                          <div className="details-tree__child-expand-icon">
                                              {this.state.openTree.bindedProductsTo.secondary === cc.categoryId ? "expand_less" : "expand_more"}
                                          </div>
                                          <div className="details-tree__child-label">
                                              {this.T(cc.label)} <span className="small-label">{cc.productCount}</span>
                                          </div>
                                      </div>
                                      {this.state.openTree.bindedProductsTo.secondary === cc.categoryId
                                          ? cc.products.map(p => {
                                                return (
                                                    <div className="details-tree__element" key={p.productId}>
                                                        <div className="details-tree__element-label">{this.T(p.productName)}</div>
                                                        <div className="details-tree__element-ean">
                                                            {p.ean} ({this.T(p.binding.label)})
                                                        </div>
                                                        <div className="details-tree__element-buttons">
                                                            <Link to={`/catalogue/product/${p.productId}/details`} className="_button-light small">
                                                                {this.T("show")}
                                                            </Link>
                                                        </div>
                                                    </div>
                                                );
                                            })
                                          : null}
                                  </div>
                              );
                              return tt;
                          }, [])
                        : null}
                </div>
            );
            return t;
        }, []);

        const bindedProductsFrom = this.state.tree.bindedProductsFrom.reduce((t, c) => {
            if (c.productCount === 0) {
                return t;
            }
            t.push(
                <div className="details-tree__main" key={c.categoryId}>
                    <div className="details-tree__main-content" onClick={() => this.openTree("bindedProductsFrom", "main", c.categoryId)}>
                        <div className="details-tree__main-expand-icon">
                            {this.state.openTree.bindedProductsFrom.main === c.categoryId ? "expand_less" : "expand_more"}
                        </div>
                        <div className="details-tree__main-label">
                            {this.T(c.label)} <span className="small-label">{c.productCount}</span>
                        </div>
                    </div>
                    {this.state.openTree.bindedProductsFrom.main === c.categoryId
                        ? c.categories.reduce((tt, cc) => {
                              if (cc.productCount === 0) {
                                  return tt;
                              }

                              tt.push(
                                  <div className="details-tree__child" key={cc.categoryId}>
                                      <div
                                          className="details-tree__child-content"
                                          onClick={() => this.openTree("bindedProductsFrom", "secondary", cc.categoryId)}
                                      >
                                          <div className="details-tree__child-expand-icon">
                                              {this.state.openTree.bindedProductsFrom.secondary === cc.categoryId ? "expand_less" : "expand_more"}
                                          </div>
                                          <div className="details-tree__child-label">
                                              {this.T(cc.label)} <span className="small-label">{cc.productCount}</span>
                                          </div>
                                      </div>
                                      {this.state.openTree.bindedProductsFrom.secondary === cc.categoryId
                                          ? cc.products.map(p => {
                                                return (
                                                    <div className="details-tree__element" key={p.productId}>
                                                        <div className="details-tree__element-label">{this.T(p.productName)}</div>
                                                        <div className="details-tree__element-ean">
                                                            {p.ean} ({this.T(p.binding.label)})
                                                        </div>
                                                        <div className="details-tree__element-buttons">
                                                            <Link to={`/catalogue/product/${p.productId}/details`} className="_button-light small">
                                                                {this.T("show")}
                                                            </Link>
                                                        </div>
                                                    </div>
                                                );
                                            })
                                          : null}
                                  </div>
                              );
                              return tt;
                          }, [])
                        : null}
                </div>
            );
            return t;
        }, []);

        return (
            <div className="content-basic">
                {loader}
                {editModal}
                {editPhoto}
                {editBindings}
                {bindingModal}
                {fileBindingModal}
                <div className="content-basic__header">
                    <button className="_button-light" onClick={() => this.setValue("editModal", true)}>
                        {this.T("editProduct")}
                    </button>

                    <button className="_button-light" onClick={() => this.setValue("managePhotosModal", true)}>
                        {this.T("editPhotos")}
                    </button>

                    <button
                        className="_button-light"
                        onClick={() => this.modalState("binding", true, { productId: parseInt(this.props.match.params.productId) })}
                    >
                        {this.T("editBindingsTo")}
                    </button>

                    <button
                        className="_button-light"
                        onClick={() =>
                            this.modalState("copyBinding", true, {
                                productId: parseInt(this.props.match.params.productId)
                            })
                        }
                    >
                        {this.T("copyBinding")}
                    </button>

                    <button
                        className="_button-light"
                        onClick={() =>
                            this.modalState("fileBinding", true, {
                                id: parseInt(this.props.match.params.productId)
                            })
                        }
                    >
                        {this.T("editFileBindings")}
                    </button>

                    <button className="_button-light" onClick={() => this.deleteProduct()}>
                        {this.T("deleteProduct")}
                    </button>
                </div>
                <div className="content-basic__header">
                    <div className="product-details">
                        <div className="product-details__row">
                            <div className="product-details__name">
                                <div className="product-details__name-main">{this.state.product.ean}</div>
                                <div className="product-details__name-second">{this.T("ean")}</div>
                            </div>
                        </div>

                        <div className="product-details__row">
                            <div className="product-details__name">
                                <div className="product-details__name-main">{this.T(name)}</div>
                                <div className="product-details__name-second">{this.T("productName")}</div>
                            </div>
                        </div>
                    </div>
                </div>
                {photos}
                <div className="content-basic__header">
                    <div className="product-details">
                        <div className="product-details__row">
                            <div className="product-details__property-group">{this.T("general")}</div>
                        </div>

                        <div className="product-details__row">
                            <div className="product-details__property">
                                <div className="product-details__property-name">{this.T("type")}</div>
                                <div className="product-details__property-value">
                                    <div className="product-details__property-value-main">{this.T(this.state.product.productType.label)}</div>
                                    <div className="product-details__property-value-secondary">{this.T(this.state.product.productType.label, null, true)}</div>
                                </div>
                            </div>
                        </div>

                        <div className="product-details__row">
                            <div className="product-details__property">
                                <div className="product-details__property-name">{this.T("category")}</div>
                                <div className="product-details__property-value">
                                    <div className="product-details__property-value-main">{this.T(this.state.product.category.label)}</div>
                                    <div className="product-details__property-value-secondary">{this.T(this.state.product.category.label, null, true)}</div>
                                </div>
                            </div>
                        </div>

                        {this.state.product.propertyGroups.map(pg => {
                            if (pg.properties.length === 0) {
                                return null;
                            }
                            return (
                                <div className="product-details-group" key={pg.productPropertyGroupId}>
                                    <div className="product-details__row">
                                        <div className="product-details__property-group">{this.T(pg.label)}</div>
                                    </div>
                                    {pg.properties.map(p => {
                                        if (p.productPropertyDefinition.productPropertyDefinitionId === 1) {
                                            return null;
                                        }

                                        return (
                                            <div className="product-details__row" key={p.productPropertyId}>
                                                <div className="product-details__property">
                                                    <div className="product-details__property-name">{this.T(p.productPropertyDefinition.label)}</div>
                                                    <div className="product-details__property-value">
                                                        <div className="product-details__property-value-main">{this.T(p.content)}</div>
                                                        <div className="product-details__property-value-secondary">{this.T(p.content, null, true)}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            );
                        })}
                    </div>
                </div>
                <div className="content-basic__header">
                    <div className="product-details">
                        <div className="product-details__row">
                            <div className="product-details__property-group">{this.T("bindedProductsTo")}</div>
                        </div>
                        <div className="product-details__row">
                            {this.state.tree.bindedProductsTo.length === 0 ? (
                                <button className="_button-light" onClick={() => this.getBindedProductsTo()}>
                                    {this.T("bindedProductsToLoad")}
                                </button>
                            ) : bindedProductsTo.length === 0 ? (
                                <div className="">{this.T("noBindedProductsFrom")}</div>
                            ) : (
                                <div className="details-tree__container">{bindedProductsTo}</div>
                            )}
                        </div>
                    </div>
                </div>
                {/* <div className='content-basic__header'>
                    <div className='product-details'>
                        <div className='product-details__row'>
                            <div className='product-details__property-group'>{this.T('bindedProductsFrom')}</div>
                        </div>
                        <div className='product-details__row'>
                            {this.state.tree.bindedProductsFrom.length === 0 ? (
                                <button className='_button-light' onClick={() => this.getBindedProductsFrom()}>
                                    {this.T('bindedProductsFromLoad')}
                                </button>
                            ) : bindedProductsFrom.length === 0 ? (
                                <div className=''>{this.T('noBindedProductsFrom')}</div>
                            ) : (
                                <div className='details-tree__container'>{bindedProductsFrom}</div>
                            )}
                        </div>
                    </div>
                </div> */}
                <div className="content-basic__header">
                    <div className="product-details">
                        <div className="product-details__row">
                            <div className="product-details__property-group">{this.T("bindedFiles")}</div>
                        </div>
                        <div className="product-details__row">
                            {this.state.tree.bindedFiles.length === 0 ? (
                                <button className="_button-light" onClick={() => this.getBindedFiles()}>
                                    {this.T("bindedFilesLoad")}
                                </button>
                            ) : bindedFiles.length === 0 ? (
                                <div className="">{this.T("noBindedFiles")}</div>
                            ) : (
                                <div className="details-tree__container">{bindedFiles}</div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
CatalogueProductDetails.contextType = AppContext;

export default CatalogueProductDetails;
