import React from 'react';
import './FieldWrapper.scss';

export interface FormFieldWrapperProps {
    label: string;
    description?: string;
    error?: string;
}

const classBase = 'new-form-field-wrapper';

const FormFieldWrapper: React.FC<FormFieldWrapperProps> = props => (
    <div className={`${classBase}__wrapper`}>
        <div className={`${classBase}__label`}>{props.label}</div>
        {props.description && props.description.length > 0 ? (
            <div className={`${classBase}__description`}>{props.description}</div>
        ) : null}
        <div className={`${classBase}__field`}>{props.children}</div>
        {props.error && props.error.length > 0 ? (
            <div className={`${classBase}__error`}>{props.error}</div>
        ) : null}
    </div>
);

export default FormFieldWrapper;
