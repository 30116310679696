import { DataSourceNew } from "../../utils/endpoint/DataSourceNew";
import { DataSourceFunction } from "../../utils/endpoint/DataSourceNew.function.types";

import { PostMagazineStateProductUpdateData } from "../../../../../Api/types/src/api/magazine/PostMagazineStateProductUpdate.types";

export const PostStateProductUpdateDataSource: DataSourceFunction<
    "MagazinePostStateProductUpdate",
    undefined,
    PostMagazineStateProductUpdateData,
    undefined
> = parent =>
    new DataSourceNew(
        {
            name: "MagazinePostStateProductUpdate",
            endpointConfig: {
                url: "/v2/magazine/MagazineStateProductUpdate",
                method: "POST"
            }
        },
        parent
    );
