import React, { Component } from 'react';

import appTranslate from '../../../../appTranslate.json';
import { AppContext } from '../../../../services/context';
import axios from 'axios';

import Loader from '../../../../components/basic/Loader';
import SelectWithButton from '../../../../components/form/SelectWithButton';

import Modal from '../../../../components/modal/Modal';

class ProductEditBindingsModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            reducedProducts: [],
            loader: true
        };
    }

    componentDidMount() {
        this.fetchReducedProducts();
    }

    fetchReducedProducts = () => {
        this.setLoaderState(true);

        this.context.authService
            .getAuthHeader()
            .then(authHeader => {
                return axios({
                    url:
                        GEAPP_ADMIN_CONFIG.adminApiUrl +// eslint-disable-line no-undef
                        '/catalogue/products/product/reducedList',
                    method: 'GET',
                    headers: {
                        ...authHeader,
                        'Content-Type': 'application/json'
                    }
                })
                    .then(response => {
                        console.log(response);
                        this.setState(prevState => {
                            return {
                                reducedProducts: response.data.data,
                                loader: false
                            };
                        });
                    })
                    .catch(error => {
                        console.log(error);
                    });
            })
            .catch(e => {
                console.log(e);
            })
            .finally(() => {
                // this.setLoader('properties', false);
            });
    };

    T = (prop, params) => {
        let p;

        if (typeof prop === 'object') {
            p = prop;
        }
        if (typeof prop === 'string') {
            p = prop
                .split('.')
                .reduce(
                    (obj, a) => obj[a],
                    appTranslate.areas.catalogue.product.editBindings
                );
        }

        return this.context.translateService.getTranslation(p, params);
    };

    setLoaderState = state => {
        this.setState({ loader: state });
    };

    addBinding = (productIdBinded, productBindingTypeId) => {
        this.setLoaderState(true);

        this.context.authService
            .getAuthHeader()
            .then(authHeader => {
                return axios({
                    url:
                        GEAPP_ADMIN_CONFIG.adminApiUrl +// eslint-disable-line no-undef
                        '/catalogue/products/product/addBinding',
                    method: 'POST',
                    headers: {
                        ...authHeader,
                        'Content-Type': 'application/json'
                    },
                    data: {
                        productId: this.props.parentState.product.productId,
                        productIdBinded: productIdBinded,
                        productBindingTypeId: productBindingTypeId
                    }
                })
                    .then(response => {
                        console.log(response);
                        this.setLoaderState(false);
                        this.props.refresh();
                    })
                    .catch(error => {
                        console.log(error);
                    });
            })
            .catch(e => {
                console.log(e);
            })
            .finally(() => {
                // this.setLoader('properties', false);
            });
    };

    removeBinding = (productIdBinded, productBindingTypeId) => {
        this.setLoaderState(true);

        this.context.authService
            .getAuthHeader()
            .then(authHeader => {
                return axios({
                    url:
                        GEAPP_ADMIN_CONFIG.adminApiUrl +// eslint-disable-line no-undef
                        '/catalogue/products/product/removeBinding',
                    method: 'POST',
                    headers: {
                        ...authHeader,
                        'Content-Type': 'application/json'
                    },
                    data: {
                        productId: this.props.parentState.product.productId,
                        productIdBinded: productIdBinded,
                        productBindingTypeId: productBindingTypeId
                    }
                })
                    .then(response => {
                        console.log(response);
                        this.setLoaderState(false);
                        this.props.refresh();
                    })
                    .catch(error => {
                        console.log(error);
                    });
            })
            .catch(e => {
                console.log(e);
            })
            .finally(() => {
                // this.setLoader('properties', false);
            });
    };

    submit = () => {
        this.setLoader('properties', true);
        this.context.authService
            .getAuthHeader()
            .then(authHeader => {
                return axios({
                    url:
                        GEAPP_ADMIN_CONFIG.adminApiUrl +// eslint-disable-line no-undef
                        '/catalogue/products/product/update',
                    method: 'POST',
                    headers: {
                        ...authHeader,
                        'Content-Type': 'application/json'
                    },
                    data: {
                        product: this.prepareData()
                    }
                })
                    .then(response => {
                        console.log(response);
                        this.close(true);
                    })
                    .catch(error => {
                        console.log(error);
                    });
            })
            .catch(e => {
                console.log(e);
            })
            .finally(() => {
                // this.setLoader('properties', false);
            });
    };

    close = result => {
        this.props.close(result);
    };

    render() {
        const footer = (
            <div className='space-between'>
                <button className='_button-light' onClick={() => this.close()}>
                    {this.T('ready')}
                </button>
            </div>
        );

        let content = <Loader />;

        if (!this.state.loader && !this.props.parentState.loader) {
            content = this.props.parentState.product.bindings.map(b => {
                let unbindedProducts = this.state.reducedProducts.reduce(
                    (prev, p) => {
                        if (
                            b.products.findIndex(
                                e => e.productIdBinded === p.productId
                            ) === -1
                        ) {
                            prev.push({
                                value: p.productId,
                                label: this.T(p.name)
                            });
                        }
                        return prev;
                    },
                    []
                );

                return (
                    <div
                        className='product-edit-bindings'
                        key={b.productBindingTypeId}
                    >
                        <div className='product-edit-bindings__group-title'>
                            {this.T(b.label)}
                        </div>
                        {b.products.map(p => {
                            return (
                                <div
                                    className='product-edit-bindings__product'
                                    key={p.productIdBinded}
                                >
                                    <div className='product-edit-bindings__product-ean'>
                                        {p.ean}
                                    </div>
                                    <div className='product-edit-bindings__product-name'>
                                        {this.T(p.name)}
                                    </div>
                                    <div
                                        className='product-edit-bindings__product-delete'
                                        onClick={() =>
                                            this.removeBinding(
                                                p.productIdBinded,
                                                b.productBindingTypeId
                                            )
                                        }
                                    >
                                        <i className='material-icons'>close</i>
                                    </div>
                                </div>
                            );
                        })}
                        <SelectWithButton
                            options={unbindedProducts}
                            onSubmit={productId =>
                                this.addBinding(
                                    productId,
                                    b.productBindingTypeId
                                )
                            }
                            buttonLabel={this.T('add')}
                        />
                    </div>
                );
            });
        }

        return (
            <Modal
                title={this.T('title')}
                close={result => this.close(result)}
                footer={footer}
                content={content}
                visible={true}
            />
        );
    }
}
ProductEditBindingsModal.contextType = AppContext;

export default ProductEditBindingsModal;
