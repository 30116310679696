import { useContext } from "react";
import { LocalizedValue } from "../../../../Api/types/src/helpers/Localize.types";
import { AppContext } from "../services/context";
import { Language } from "../services/translateService";

export function useTranslate() {
    const context = useContext(AppContext);

    return (translateObject: LocalizedValue<string>) => {
        const currentLanguage: Language = context.translateService.currentLanguage();

        const translation: string | undefined = translateObject[currentLanguage] as any;

        if (translation) return translation;

        if (!!translateObject.pl) return translateObject.pl;

        if (!!translateObject.en) return translateObject.en;

        return "";
    };
}
