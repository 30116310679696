import moment from "moment";
import React from "react";
import { Link } from "react-router-dom";

import { AppContext } from "../../../../services/context";
import TO from "./List.json";

import { GetStateListDataSource } from "../../../../endpoints/magazine/GetStateList";
import { DataSourceStorageInitial } from "../../../../utils/endpoint/DataSourceNew";
import { DataSourceFunctionGetStorage } from "../../../../utils/endpoint/DataSourceNew.function.types";

import AddModal from "./AddModal/AddModal";

import Overlay, { OverlayData } from "../../../../components/overlay/Overlay";
import { BasicComponent } from "../../../../utils/BasicComponent/BasicComponent";

interface StocktakingListState {
    datasource: {
        MagazineGetStateList: DataSourceFunctionGetStorage<typeof GetStateListDataSource>;
    };
    modal: {
        add: {
            open: boolean;
        };
    };
}

export default class StocktakingList extends BasicComponent<{}, StocktakingListState> {
    state: StocktakingListState = {
        datasource: {
            MagazineGetStateList: DataSourceStorageInitial
        },
        modal: {
            add: {
                open: false
            }
        }
    };

    static contextType = AppContext;

    protected TranslateObject = TO;

    private dsStateList = GetStateListDataSource(this);

    componentDidMount() {
        this._isMounted = true;
        this.dsStateList.request({ params: {} });
    }

    get overlayData(): OverlayData {
        if (this.dsStateList.state === "idle" || this.dsStateList.state === "pending") {
            return {
                show: true,
                title: "Loading..."
            };
        }
        return {
            show: false
        };
    }

    render() {
        const addModal = !!this.state.modal.add.open ? (
            <AddModal
                onClose={() => this.setModalState("add", false)}
                onDismiss={() => this.setModalState("add", false)}
            />
        ) : null;
        return (
            <div className="content-basic">
                {addModal}
                <div className="content-basic__header">
                    <button className="_button-light" onClick={() => this.setModalState("add", true)}>
                        {this.T("addButton")}
                    </button>
                </div>
                <div className="content-basic__content">
                    <Overlay data={this.overlayData} />
                    <div className="category-tree">
                        {!!this.dsStateList.data
                            ? this.dsStateList.data.magazineStates.map(e => (
                                  <div className="category-tree__wrapper" key={e.magazineStateId}>
                                      <div className="category-tree__main-category">
                                          <div className="category-tree__main-category__label">
                                              {moment(e.stateDate).format("YYYY-MM-DD")} | {e.magazineLocation.label}
                                          </div>

                                          <div className="category-tree__main-category__buttons">
                                              <Link
                                                  to={`/magazine/stocktaking/details?MagazineStateId=${e.magazineStateId}`}
                                                  className="_button-light small"
                                              >
                                                  {this.T("details")}
                                              </Link>
                                          </div>
                                      </div>
                                  </div>
                              ))
                            : null}
                    </div>
                </div>
            </div>
        );
    }

    private setModalState<TName extends keyof StocktakingListState["modal"]>(modalName: TName, state: boolean) {
        this.setState(p => ({
            modal: {
                ...p.modal,
                [modalName]: {
                    ...p.modal[modalName],
                    open: state
                }
            }
        }));
    }
}
