import React from "react";
import FormComponent, { FormComponentConfig } from "../../../../../utils/form/FormComponent";
import TextField, { TextFieldInitialState } from "../../../../../utils/form/fields/TextField";
import DatePicker, {
    DatePickerInitialState,
    DatePickerValidateDate
} from "../../../../../utils/form/fields/DatePicker";
import SelectField, { SelectFieldInitialState } from "../../../../../utils/form/fields/SelectField";
import { MagazineLocation } from "../../../../../../../../Api/types/src/areas/magazine/Location.types";

import TO from "./AddModal.json";

interface FieldList {
    orderDate: DatePicker;
    magazineLocation: SelectField;
}

interface Props {
    magazineLocations: MagazineLocation[];
    T: (obj: any) => string;
}

export default class MagazineStateAddModalForm extends FormComponent<FieldList, Props> {
    state = {
        form: {
            orderDate: DatePickerInitialState(),
            magazineLocation: SelectFieldInitialState(1)
        }
    };

    formFieldsConfig: FormComponentConfig<FieldList> = {
        orderDate: {
            label: () => this.T(TO.stateDate),
            validate: value => DatePickerValidateDate(value)
        },
        magazineLocation: {
            label: () => this.T(TO.magazineLocation),
            options: []
        }
    };

    render() {
        return (
            <div className="new-form-area">
                <DatePicker {...this.fieldProps("orderDate")} />
                <SelectField
                    {...this.fieldProps("magazineLocation")}
                    options={this.props.magazineLocations.map(l => ({
                        id: l.magazineLocationId,
                        label: l.label
                    }))}
                />
            </div>
        );
    }
}
