import React from 'react';
import {
    CustomComponent,
    CustomComponentInitialState,
    CustomComponentState
} from '../../../../../utils/CustomComponent';
import { ModalBaseProps } from '../../../../../utils/Modal';
import ModalWrapper from '../../../../../utils/ModalWrapper';
import {
    CustomComponentModal,
    CustomComponentModalInitialState
} from '../../../../../utils/CustomComponentModal';
import FormComponent from '../../../../../utils/form/FormComponent';

import moment from 'moment';

import EditModalForm from './EditModal.form';
import { DataSource } from '../../../../../utils/dataSource/DataSource';
import { CustomComponentViewStateWithModals } from '../../../../../utils/CustomComponentView';
import DataSourceLoader from '../../../../../utils/DataSourceLoader';
import { MagazineLocation } from '../../../../../../../../Api/types/src/areas/magazine/Location.types';
import { number } from 'prop-types';
import {
    MagazineOrderInsertModel,
    MagazineOrderListModel,
    MagazineOrderUpdateModel
} from '../../../../../../../../Api/types/src/areas/magazine/Order.types';
import TO from './Translate.json';

interface Props {
    magazineOrder: MagazineOrderListModel;
}

interface DataSources {
    magazineLocations: DataSource<MagazineLocation[]>;
    editOrder: DataSource<number>;
}

interface State extends CustomComponentState {
    magazineLocations: MagazineLocation[];
}

export default class AddModal extends CustomComponentModal<{}, DataSources, Props, State> {
    state: State = {
        ...CustomComponentInitialState,
        magazineLocations: []
    };

    protected dataSource: DataSources = {
        magazineLocations: new DataSource<MagazineLocation[]>(this, {
            url: '/magazine/location/list',
            method: 'GET'
        }),
        editOrder: new DataSource<number>(this, {
            url: '/magazine/order/update',
            method: 'POST'
        })
    };

    componentDidMount() {
        this._isMounted = true;
        this.getMagazineLocations();
    }

    getMagazineLocations() {
        this.dataSource.magazineLocations
            .makeRequest()
            .then(data => this._isMounted && this.setState({ magazineLocations: data }));
    }

    editOrder(order: MagazineOrderUpdateModel) {
        return this.dataSource.editOrder.makeRequest({ data: { order: order } });
    }

    private FormRef = React.createRef<EditModalForm>();

    private prepareDate(): MagazineOrderUpdateModel | undefined {
        if (!this.FormRef.current) {
            return undefined;
        }
        const form = this.FormRef.current.state.form;
        console.log(form.orderDate.value);
        console.log(
            moment(form.orderDate.value)
                .utc(true)
                .toDate()
        );
        return {
            magazineOrderId: this.props.customProps.magazineOrder.magazineOrderId,
            orderDate: moment(form.orderDate.value).toDate(),
            magazineLocationId: form.magazineLocation.value,
            expectedShipmentDate: moment(form.shipmentDate.value).toDate()
        };
    }

    private onSubmit() {
        console.log('on submit in modal');
        if (!this.FormRef.current || !this.FormRef.current.onSubmit()) {
            return;
        }

        const data = this.prepareDate();

        if (!data) {
            return;
        }

        this.editOrder(data).then(() => this.props.onClose());
    }

    render() {
        if (!this.props.state) {
            return null;
        }
        return (
            <ModalWrapper
                title={this.T(TO.title)}
                submit={this.T(TO.submit)}
                cancel={this.T(TO.cancel)}
                onSubmit={() => this.onSubmit()}
                onCancel={() => this.props.onDismiss()}
            >
                <DataSourceLoader
                    status={this.state.dataSourcesStatus}
                    language={this.context.translateService.currentLanguage()}
                />
                {this.state.magazineLocations.length > 0 ? (
                    <EditModalForm
                        ref={this.FormRef}
                        magazineLocations={this.state.magazineLocations}
                        magazineOrder={this.props.customProps.magazineOrder}
                    />
                ) : null}
            </ModalWrapper>
        );
    }
}
