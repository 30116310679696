import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';

import appTranslate from '../../../../appTranslate.json';
import Loader from '../../../../components/basic/Loader';
import { AppContext } from '../../../../services/context';
import axios from 'axios';

import EditModal from './EditModal';
import DeleteModal from './DeleteModal';

import ProductCount from './ProductQuantityModal';

class Details extends Component {
    constructor(props) {
        super(props);

        this.magazineSupplyId = this.props.match.params.magazineSupplyId;

        this.state = {
            loader: false,
            supply: null,
            openTree: {
                main: null,
                secondary: null
            },
            modal: {
                edit: {
                    open: false,
                    params: null
                },
                delete: {
                    open: false,
                    params: null
                },
                productCount: {
                    open: false,
                    params: null
                }
            }
        };
    }

    T = (prop, params, r) => {
        return this.context.translateService.translationProxy(
            prop,
            params,
            r,
            appTranslate.areas.magazine.supply.details
        );
    };

    modalState = (modal, state, params) => {
        this.setState(prevState => {
            return {
                modal: {
                    ...prevState.modal,
                    [modal]: {
                        open: state,
                        params: params
                    }
                }
            };
        });
    };

    onModalClose = (modal, response) => {
        this.modalState(modal, false, null);

        if (modal === 'delete') {
            if (response) {
                window.location = '/magazine/supply';
            }
            return;
        }

        if (response) {
            this.getData();
        }
    };

    componentDidMount() {
        this.getData();
    }

    setLoaderState = state => {
        this.setState({ loader: state });
    };

    modalState = (modal, state, params) => {
        this.setState(prevState => {
            return {
                modal: {
                    ...prevState.modal,
                    [modal]: {
                        open: state,
                        params: params
                    }
                }
            };
        });
    };

    openTree = (level, id) => {
        this.setState(prev => {
            return {
                openTree: {
                    ...prev.openTree,
                    [level]: prev.openTree[level] === id ? null : id
                }
            };
        });
    };

    getData = () => {
        this.setLoaderState(true);
        this.context.authService
            .getAuthHeader()
            .then(authHeader => {
                return axios({
                    url:
                        GEAPP_ADMIN_CONFIG.adminApiUrl +// eslint-disable-line no-undef
                        '/magazine/supply/details/' +
                        this.props.match.params.magazineSupplyId,
                    method: 'GET',
                    headers: {
                        ...authHeader,
                        'Content-Type': 'application/json'
                    }
                })
                    .then(response => {
                        console.log(response);
                        if (response.data.code === 200) {
                            this.setState({ supply: response.data.data });
                        }
                    })
                    .catch(error => {
                        console.log(error);
                    });
            })
            .catch(e => {
                console.log(e);
            })
            .finally(() => {
                this.setLoaderState(false);
            });
    };

    render() {
        let loader = <div />;
        if (this.state.loader) {
            loader = <Loader />;
        }

        if (!this.state.supply) {
            return <div className='content-basic'>{loader}</div>;
        }

        let editModal = <div />;
        if (this.state.modal.edit.open) {
            editModal = (
                <EditModal
                    close={response => this.onModalClose('edit', response)}
                    params={this.state.modal.edit.params}
                />
            );
        }

        let deleteModal = <div />;
        if (this.state.modal.delete.open) {
            deleteModal = (
                <DeleteModal
                    close={response => this.onModalClose('delete', response)}
                    params={this.state.modal.delete.params}
                />
            );
        }

        let productCount = <div />;
        if (this.state.modal.productCount.open) {
            productCount = (
                <ProductCount
                    close={response => this.onModalClose('productCount', response)}
                    params={this.state.modal.productCount.params}
                />
            );
        }

        return (
            <div className='content-basic'>
                {loader}
                {editModal}
                {deleteModal}
                {productCount}
                <div className='content-basic__header'>
                    <button
                        className='_button-light'
                        onClick={() =>
                            this.modalState('edit', true, {
                                id: this.magazineSupplyId,
                                supply: this.state.supply
                            })
                        }
                    >
                        {this.T('editButton')}
                    </button>

                    <button
                        className='_button-light'
                        onClick={() =>
                            this.modalState('delete', true, {
                                id: this.magazineSupplyId,
                                supply: this.state.supply
                            })
                        }
                    >
                        {this.T('deleteButton')}
                    </button>

                    <button
                        className='_button-light'
                        onClick={() =>
                            this.modalState('productCount', true, {
                                id: this.magazineSupplyId,
                                supply: this.state.supply
                            })
                        }
                    >
                        {this.T('changeButton')}
                    </button>
                </div>
                <div className='content-basic__header'>
                    <div className='product-details'>
                        <div className='product-details__row'>
                            <div className='product-details__name'>
                                <div className='product-details__name-main'>
                                    {moment(this.state.supply.supplyDate).format('YYYY-MM-DD')}
                                </div>
                                <div className='product-details__name-second'>
                                    {this.T('supplyDate')}
                                </div>
                            </div>
                        </div>
                        <div className='product-details__row'>
                            <div className='product-details__name'>
                                <div className='product-details__name-main'>
                                    {this.state.supply.magazineLocationLabel}
                                </div>
                                <div className='product-details__name-second'>
                                    {this.T('magazineLocation')}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='content-basic__header'>
                    <div className='product-details'>
                        <div className='product-details__row'>
                            <div className='product-details__property-group'>
                                {this.T('products')}
                            </div>
                        </div>
                        <div className='product-details__row'>
                            <table>
                                <thead>
                                    <tr>
                                        <td>{this.T('table.no')}</td>
                                        <td>{this.T('table.productName')}</td>
                                        <td>P/N</td>
                                        <td>{this.T('table.category')}</td>
                                        <td>{this.T('table.expireDate')}</td>
                                        <td>{this.T('table.productCount')}</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.supply.products.map((p, i) => (
                                        <React.Fragment key={p.productId}>
                                            <tr className='row-divider'>
                                                <td rowSpan={p.quantity.length}>{i + 1}</td>
                                                <td rowSpan={p.quantity.length}>
                                                    {this.T(p.productName)}
                                                </td>
                                                <td rowSpan={p.quantity.length}>{p.ean}</td>
                                                <td rowSpan={p.quantity.length}>
                                                    {this.T(p.mainCategoryLabel)} >{' '}
                                                    {this.T(p.categoryLabel)}
                                                </td>
                                                <td>
                                                    {p.quantity.length > 0 &&
                                                    p.quantity[0].isExpiration &&
                                                    p.quantity[0].expireDate
                                                        ? moment(p.quantity[0].expireDate).format(
                                                              'YYYY-MM-DD'
                                                          )
                                                        : '---'}
                                                </td>
                                                <td>{p.quantity[0].quantity}</td>
                                            </tr>
                                            {p.quantity.length > 1
                                                ? p.quantity.map((q, i) => {
                                                      if (i === 0) {
                                                          return null;
                                                      }
                                                      let date = moment(q.expireDate).format(
                                                          'YYYY-MM-DD'
                                                      );
                                                      return (
                                                          <tr key={p.productId + date}>
                                                              <td>{date}</td>
                                                              <td>{q.quantity}</td>
                                                          </tr>
                                                      );
                                                  })
                                                : null}
                                        </React.Fragment>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
Details.contextType = AppContext;

export default Details;
