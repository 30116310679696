import React, { Component } from 'react';

import appTranslate from '../../../../appTranslate.json';
import Loader from '../../../../components/basic/Loader';
import { AppContext } from '../../../../services/context';
import axios from 'axios';

import AddGroupModal from './AddGroupModal';
import EditGroupModal from './EditGroupModal';
import DeleteGroupModal from './DeleteGroupModal';

import AddPropertyModal from './AddPropertyModal';
import EditPropertyModal from './EditPropertyModal';
import DeletePropertyModal from './DeletePropertyModal';
// import EditModal from './EditModal';
// import DeleteModal from './DeleteModal';

class List extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loader: false,
            tree: [],
            modal: {
                addGroup: {
                    open: false,
                    params: null
                },
                editGroup: {
                    open: false,
                    params: null
                },
                deleteGroup: {
                    open: false,
                    params: null
                },
                addProperty: {
                    open: false,
                    params: null
                },
                editProperty: {
                    open: false,
                    params: null
                },
                deleteProperty: {
                    open: false,
                    params: null
                }
            }
        };
    }

    componentDidMount() {
        this.getTree();
    }

    T = (prop, params, r) => {
        return this.context.translateService.translationProxy(
            prop,
            params,
            r,
            appTranslate.areas.catalogue.property.list
        );
    };

    setLoaderState = state => {
        this.setState({ loader: state });
    };

    getTree = () => {
        this.setLoaderState(true);
        this.context.authService
            .getAuthHeader()
            .then(authHeader => {
                return axios({
                    url: GEAPP_ADMIN_CONFIG.adminApiUrl + '/catalogue/products/propertyDefinition/tree',// eslint-disable-line no-undef
                    method: 'GET',
                    headers: {
                        ...authHeader,
                        'Content-Type': 'application/json'
                    }
                })
                    .then(response => {
                        console.log(response);
                        if (response.data.code === 200) {
                            this.setState({ tree: response.data.data });
                        }
                    })
                    .catch(error => {
                        console.log(error);
                    });
            })
            .catch(e => {
                console.log(e);
            })
            .finally(() => {
                this.setLoaderState(false);
            });
    };

    modalState = (modal, state, params) => {
        this.setState(prevState => {
            return {
                modal: {
                    ...prevState.modal,
                    [modal]: {
                        open: state,
                        params: params
                    }
                }
            };
        });
    };

    onModalClose = (modal, response) => {
        this.modalState(modal, false, null);
        if (response) {
            this.getTree();
        }
    };

    switchMainCategory = id => {
        this.setState(prevState => {
            return {
                tree: prevState.tree.map(e => {
                    return {
                        ...e,
                        isOpen: e.productPropertyGroupId === id ? !e.isOpen : false
                    };
                })
            };
        });
    };

    render() {
        let loader = <div />;
        if (this.state.loader) {
            loader = <Loader />;
        }

        let addGroupModal = <div />;
        if (this.state.modal.addGroup.open) {
            addGroupModal = (
                <AddGroupModal
                    close={response => this.onModalClose('addGroup', response)}
                    params={this.state.modal.addGroup.params}
                />
            );
        }

        let editGroupModal = <div />;
        if (this.state.modal.editGroup.open) {
            editGroupModal = (
                <EditGroupModal
                    close={response => this.onModalClose('editGroup', response)}
                    params={this.state.modal.editGroup.params}
                />
            );
        }

        let deleteGroupModal = <div />;
        if (this.state.modal.deleteGroup.open) {
            deleteGroupModal = (
                <DeleteGroupModal
                    close={response => this.onModalClose('deleteGroup', response)}
                    params={this.state.modal.deleteGroup.params}
                />
            );
        }

        let addPropertyModal = <div />;
        if (this.state.modal.addProperty.open) {
            addPropertyModal = (
                <AddPropertyModal
                    close={response => this.onModalClose('addProperty', response)}
                    params={this.state.modal.addProperty.params}
                />
            );
        }

        let editPropertyModal = <div />;
        if (this.state.modal.editProperty.open) {
            editPropertyModal = (
                <EditPropertyModal
                    close={response => this.onModalClose('editProperty', response)}
                    params={this.state.modal.editProperty.params}
                />
            );
        }

        let deletePropertyModal = <div />;
        if (this.state.modal.deleteProperty.open) {
            deletePropertyModal = (
                <DeletePropertyModal
                    close={response => this.onModalClose('deleteProperty', response)}
                    params={this.state.modal.deleteProperty.params}
                />
            );
        }

        return (
            <div className='content-basic'>
                {loader}
                {addGroupModal}
                {editGroupModal}
                {deleteGroupModal}
                {addPropertyModal}
                {editPropertyModal}
                {deletePropertyModal}
                <div className='content-basic__header'>
                    <button className='_button-light' onClick={() => this.modalState('addGroup', true, null)}>
                        {this.T('addButton')}
                    </button>
                </div>
                <div className='content-basic__content'>
                    <div className='category-tree'>
                        {this.state.tree.reduce((p, e) => {
                            p.push(
                                <div className='category-tree__wrapper' key={e.productPropertyGroupId}>
                                    <div className='category-tree__main-category'>
                                        <div
                                            className='category-tree__main-category__switch'
                                            onClick={() => {
                                                this.switchMainCategory(e.productPropertyGroupId);
                                            }}
                                        >
                                            {e.isOpen ? 'expand_less' : 'expand_more'}
                                        </div>
                                        <div
                                            className='category-tree__main-category__label'
                                            onClick={() => {
                                                this.switchMainCategory(e.productPropertyGroupId);
                                            }}
                                        >
                                            {this.T(e.label)}
                                        </div>

                                        <div className='category-tree__main-category__count'>
                                            {this.T('table.categoryCount')}: {e.properties.length}
                                        </div>

                                        <div className='category-tree__main-category__buttons'>
                                            <button
                                                className='_button-light small'
                                                onClick={() =>
                                                    this.modalState('addProperty', true, {
                                                        productPropertyGroupId: e.productPropertyGroupId
                                                    })
                                                }
                                            >
                                                {this.T('table.addSubcategory')}
                                            </button>
                                            <button
                                                className='_button-light small'
                                                onClick={() =>
                                                    this.modalState('editGroup', true, {
                                                        productPropertyGroupId: e.productPropertyGroupId
                                                    })
                                                }
                                            >
                                                {this.T('table.edit')}
                                            </button>
                                            <button
                                                className={`_button-light small ${
                                                    e.properties.length > 0 ? 'disabled' : ''
                                                }`}
                                                onClick={() => {
                                                    if (e.properties.length > 0) {
                                                        return;
                                                    }
                                                    this.modalState('deleteGroup', true, {
                                                        productPropertyGroupId: e.productPropertyGroupId
                                                    });
                                                }}
                                                title={
                                                    e.properties.length > 0 ? this.T('table.deleteDisabledReason') : ''
                                                }
                                            >
                                                {this.T('table.delete')}
                                            </button>
                                        </div>
                                    </div>
                                    {e.isOpen
                                        ? e.properties.map(pp => {
                                              if (pp.productPropertyDefinitionId === 1) {
                                                  return null;
                                              }
                                              return (
                                                  <div
                                                      className='category-tree__child-category'
                                                      key={pp.productPropertyDefinitionId}
                                                  >
                                                      <div className='category-tree__child-category__label'>
                                                          {this.T(pp.label)}
                                                      </div>

                                                      <div className='category-tree__child-category__buttons'>
                                                          <button
                                                              className='_button-light small'
                                                              onClick={() =>
                                                                  this.modalState('editProperty', true, {
                                                                      productPropertyDefinitionId:
                                                                          pp.productPropertyDefinitionId
                                                                  })
                                                              }
                                                          >
                                                              {this.T('table.edit')}
                                                          </button>
                                                          <button
                                                              className='_button-light small'
                                                              onClick={() =>
                                                                  this.modalState('deleteProperty', true, {
                                                                      productPropertyDefinitionId:
                                                                          pp.productPropertyDefinitionId
                                                                  })
                                                              }
                                                          >
                                                              {this.T('table.delete')}
                                                          </button>
                                                      </div>
                                                  </div>
                                              );
                                          })
                                        : null}
                                </div>
                            );
                            return p;
                        }, [])}
                    </div>
                </div>
            </div>
        );
    }
}
List.contextType = AppContext;

export default List;
