import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import appTranslate from '../../../../appTranslate.json';
import Loader from '../../../../components/basic/Loader';
import { AppContext } from '../../../../services/context';
import axios from 'axios';

import moment from 'moment';

import AddModal from './AddModal';

class List extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loader: 0,
            tree: [],
            location: [],
            currentLocation: -1,
            modal: {
                add: {
                    open: false,
                    params: null
                }
            },
            treeOpen: null
        };
    }

    componentDidMount() {
        this.getLocation();
        this.getTree();
    }

    T = (prop, params, r) => {
        return this.context.translateService.translationProxy(
            prop,
            params,
            r,
            appTranslate.areas.magazine.supply.list
        );
    };

    setLoaderState = state => {
        this.setState(prev => ({
            loader: state ? prev.loader + 1 : prev.loader > 0 ? prev.loader - 1 : 0
        }));
    };

    getLocation = () => {
        this.setLoaderState(true);
        this.context.authService
            .getAuthHeader()
            .then(authHeader => {
                return axios({
                    url: GEAPP_ADMIN_CONFIG.adminApiUrl + '/magazine/location/list',// eslint-disable-line no-undef
                    method: 'GET',
                    headers: {
                        ...authHeader,
                        'Content-Type': 'application/json'
                    }
                })
                    .then(response => {
                        console.log(response);
                        if (response.data.code === 200) {
                            this.setState({ location: response.data.data });
                        }
                    })
                    .catch(error => {
                        console.log(error);
                    });
            })
            .catch(e => {
                console.log(e);
            })
            .finally(() => {
                this.setLoaderState(false);
            });
    };

    getTree = () => {
        this.setLoaderState(true);
        this.context.authService
            .getAuthHeader()
            .then(authHeader => {
                return axios({
                    url:
                        GEAPP_ADMIN_CONFIG.adminApiUrl +// eslint-disable-line no-undef
                        '/magazine/supply/list' +
                        (this.state.currentLocation !== -1 ? '/' + this.state.currentLocation : ''),
                    method: 'GET',
                    headers: {
                        ...authHeader,
                        'Content-Type': 'application/json'
                    }
                })
                    .then(response => {
                        console.log(response);
                        if (response.data.code === 200) {
                            this.setState({ tree: response.data.data });
                        }
                    })
                    .catch(error => {
                        console.log(error);
                    });
            })
            .catch(e => {
                console.log(e);
            })
            .finally(() => {
                this.setLoaderState(false);
            });
    };

    modalState = (modal, state, params) => {
        this.setState(prevState => {
            return {
                modal: {
                    ...prevState.modal,
                    [modal]: {
                        open: state,
                        params: params
                    }
                }
            };
        });
    };

    onModalClose = (modal, response) => {
        this.modalState(modal, false, null);
        if (response) {
            this.getTree();
        }
    };

    onLocationChange = locationId => {
        locationId = parseInt(locationId);
        this.setState({ currentLocation: locationId }, () => {
            this.getTree();
        });
    };

    render() {
        let loader = <div />;
        if (this.state.loader > 0) {
            loader = <Loader />;
        }

        let addModal = <div />;
        if (this.state.modal.add.open) {
            addModal = (
                <AddModal
                    close={response => this.onModalClose('add', response)}
                    params={this.state.modal.add.params}
                />
            );
        }

        return (
            <div className='content-basic'>
                {loader}
                {addModal}
                <div className='content-basic__header'>
                    <button
                        className='_button-light'
                        onClick={() => this.modalState('add', true, null)}
                    >
                        {this.T('addButton')}
                    </button>
                </div>
                <div className='content-basic__header'>
                    <div className='select-field__wrapper'>
                        <select
                            className='field select-field'
                            value={this.state.currentLocation}
                            onChange={event => this.onLocationChange(event.target.value)}
                        >
                            <option value={-1}>{this.T('allLocations')}</option>
                            {this.state.location.map(l => (
                                <option value={l.magazineLocationId} key={l.magazineLocationId}>
                                    {l.label}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>
                <div className='content-basic__content'>
                    <div className='category-tree'>
                        {this.state.tree.reduce((p, e) => {
                            p.push(
                                <div className='category-tree__wrapper' key={e.magazineSupplyId}>
                                    <div className='category-tree__main-category'>
                                        <div className='category-tree__main-category__label'>
                                            {moment(e.supplyDate).format('YYYY-MM-DD')} | {e.magazineLocationLabel}
                                        </div>

                                        <div className='category-tree__main-category__count'>
                                            {this.T('productCount')} {e.productCount}
                                        </div>

                                        <div className='category-tree__main-category__buttons'>
                                            <Link
                                                to={`/magazine/supply/${
                                                    e.magazineSupplyId
                                                }/details`}
                                                className='_button-light small'
                                            >
                                                {this.T('table.details')}
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            );
                            return p;
                        }, [])}
                    </div>
                </div>
            </div>
        );
    }
}
List.contextType = AppContext;

export default List;
