import React from 'react';
import { CustomComponent } from '../../../../../utils/CustomComponent';
import Translation from './Translation.json';

export interface MagazineOrderDetailsHeaderProps {
    onEdit: () => void;
    onDelete: () => void;
    onStatusChange: () => void;
    onProductChange: () => void;
    T: CustomComponent['T'];
}

const Element: React.FC<MagazineOrderDetailsHeaderProps> = props => (
    <div className='content-basic__header'>
        <button className='_button-light' onClick={() => props.onEdit()}>
            {props.T(Translation.header.edit)}
        </button>

        <button className='_button-light' onClick={() => props.onDelete()}>
            {props.T(Translation.header.delete)}
        </button>

        <button className='_button-light' onClick={() => props.onStatusChange()}>
            {props.T(Translation.header.statusChange)}
        </button>

        <button className='_button-light' onClick={() => props.onProductChange()}>
            {props.T(Translation.header.changeProduct)}
        </button>
    </div>
);

export default Element;
