import React, { Component } from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import { RouteComponentProps } from "react-router";

import List from "./List";

class StockMain extends Component<RouteComponentProps> {
    render() {
        return (
            <Switch>
                <Route path={`${this.props.match.path}`} component={List} />
            </Switch>
        );
    }
}

export default StockMain;
