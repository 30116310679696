import React from "react";
import FormFieldBase from "../FieldBase";
import FormFieldWrapper from "../FieldWrapper";

export type SelectFieldValue = number;

const classBase = "form-field";

export function SelectFieldInitialState(value?: SelectFieldValue) {
    return {
        value: value || -1,
        error: [] as string[]
    };
}

export interface SelectFieldOption {
    id: SelectFieldValue;
    label: string;
}

export interface SelectFieldProps {
    options: SelectFieldOption[];
}

export default class SelectField extends FormFieldBase<SelectFieldValue, SelectFieldProps> {
    render() {
        return (
            <FormFieldWrapper {...this.props}>
                <select
                    className="form-field__select"
                    value={this.props.value}
                    onChange={e => this.props.valueChange(parseInt(e.target.value))}
                    onFocus={() => this.props.onFocus && this.props.onFocus()}
                    onBlur={() => this.props.onBlur && this.props.onBlur()}
                >
                    {this.props.options.map(o => (
                        <option value={o.id} key={o.id}>
                            {o.label}
                        </option>
                    ))}
                </select>
            </FormFieldWrapper>
        );
    }
}
