import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import DataSourceLoader from '../../../../../utils/DataSourceLoader';

import Translation from './Translation.json';

import {
    CustomComponentView,
    CustomComponentViewInitialState,
    CustomComponentViewStateWithModals
} from '../../../../../utils/CustomComponentView';
import { DataSource } from '../../../../../utils/dataSource/DataSource';

import {
    MagazineOrderListModel,
    MagazineOrderProductModel,
    MagazineOrderDetailsModel
} from '../../../../../../../../Api/types/src/areas/magazine/Order.types';

import { ContractOrderListModel } from '../../../../../../../../Api/types/src/areas/contract/Order.types';

import Header from './Header';
import ContractOrder from './ContractOrder';
import MagazineOrder from './MagazineOrder';
import Products from './Products';

import EditModal from '../EditModal/EditModal';
import DeleteModal from '../DeleteModal/DeleteModal';
import StatusModal from '../EditStatus/EditStatus';
import ProductsModal from '../EditProducts/EditProduct';

interface StateData {
    magazineOrder: MagazineOrderListModel | undefined;
    contractOrder: ContractOrderListModel | undefined;
    products: MagazineOrderProductModel[];
}

interface MathParams {
    magazineOrderId: string;
}

interface State extends CustomComponentViewStateWithModals<Modals>, StateData {}

interface DataSources {
    details: DataSource<MagazineOrderDetailsModel>;
}

interface Modals {
    editModal: EditModal;
    deleteModal: DeleteModal;
    statusModal: StatusModal;
    productsModal: ProductsModal;
}

export class List extends CustomComponentView<
    Modals,
    DataSources,
    RouteComponentProps<MathParams>,
    State
> {
    state: State = {
        ...CustomComponentViewInitialState,
        modals: {
            editModal: {
                state: false,
                props: undefined
            },
            deleteModal: {
                state: false,
                props: undefined
            },
            statusModal: {
                state: false,
                props: undefined
            },
            productsModal: {
                state: false,
                props: undefined
            }
        },
        magazineOrder: undefined,
        contractOrder: undefined,
        products: []
    };

    magazineOrderId: number = parseInt(this.props.match.params.magazineOrderId);

    ObjectTranslation = Translation;

    protected dataSource: DataSources = {
        details: new DataSource(this, {
            url: '/magazine/order/details/' + this.magazineOrderId,
            method: 'GET'
        })
    };

    protected modalHandlers = {
        editModal: {
            onClose: () => {
                this.getDetails();
            }
        },
        statusModal: {
            onClose: () => {
                this.getDetails();
            }
        },
        productsModal: {
            onClose: () => {
                this.getDetails();
            }
        }
    };

    componentDidMount() {
        this._isMounted = true;
        this.getDetails();
    }

    getDetails() {
        this.dataSource.details.makeRequest().then(details =>
            this.setState({
                magazineOrder: details.magazineOrder,
                contractOrder: details.contractOrder,
                products: details.products
            })
        );
    }

    render() {
        return (
            <div className='content-basic'>
                {this.state.modals.editModal.state ? (
                    <EditModal {...this.modalProps('editModal')} />
                ) : null}

                {this.state.modals.deleteModal.state ? (
                    <DeleteModal {...this.modalProps('deleteModal')} />
                ) : null}
                {this.state.modals.statusModal.state ? (
                    <StatusModal {...this.modalProps('statusModal')} />
                ) : null}
                {this.state.modals.productsModal.state ? (
                    <ProductsModal {...this.modalProps('productsModal')} />
                ) : null}

                <DataSourceLoader
                    status={this.state.dataSourcesStatus}
                    language={this.context.translateService.currentLanguage()}
                />
                <Header
                    onEdit={() =>
                        this.state.magazineOrder &&
                        this.modalOpen('editModal', { magazineOrder: this.state.magazineOrder })
                    }
                    onDelete={() =>
                        this.state.magazineOrder &&
                        this.modalOpen('deleteModal', {
                            magazineOrderId: this.state.magazineOrder.magazineOrderId
                        })
                    }
                    onStatusChange={() =>
                        this.state.magazineOrder &&
                        this.modalOpen('statusModal', { magazineOrder: this.state.magazineOrder })
                    }
                    onProductChange={() =>
                        this.state.magazineOrder &&
                        this.modalOpen('productsModal', { magazineOrder: this.state.magazineOrder })
                    }
                    T={value => this.T(value)}
                />
                {this.state.contractOrder ? (
                    <ContractOrder T={value => this.T(value)} details={this.state.contractOrder} />
                ) : null}
                {this.state.magazineOrder ? (
                    <MagazineOrder T={value => this.T(value)} details={this.state.magazineOrder} />
                ) : null}
                <Products products={this.state.products} T={value => this.T(value)} />
            </div>
        );
    }
}

export default List;
