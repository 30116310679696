import { DataSourceNew } from "../../utils/endpoint/DataSourceNew";
import { DataSourceFunction } from "../../utils/endpoint/DataSourceNew.function.types";

import { PostMagazineStateInsertData } from "../../../../../Api/types/src/api/magazine/PostMagazineStateInsert.types";

export const PostStateInsertDataSource: DataSourceFunction<
    "MagazinePostStateInsert",
    undefined,
    PostMagazineStateInsertData,
    undefined
> = parent =>
    new DataSourceNew(
        {
            name: "MagazinePostStateInsert",
            endpointConfig: {
                url: "/v2/magazine/MagazineStateInsert",
                method: "POST"
            }
        },
        parent
    );
