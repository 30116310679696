import React from "react";
import FormFieldBase, { FormFieldBaseExtractTValue, FormFieldBaseExtractProps } from "./FieldBase";
import { AppContext } from "../../services/context";
import { ParamsType, TranslateObject } from "../../services/translateService";
import { type } from "os";

export interface FormComponentStateFormField<TValue> {
    value: TValue;
    error: string[];
}

export type FormComponentStateForm<TFieldList> = {
    [F in keyof TFieldList]: TFieldList[F] extends FormFieldBase<infer TValue>
        ? FormComponentStateFormField<TValue>
        : never;
};

export interface FormComponentState<TFieldList> {
    form: FormComponentStateForm<TFieldList>;
}

export interface FormComponentFieldConfig<TValue> {
    label: string | (() => string);
    onFocus?: () => void;
    onBlur?: () => void;
    validate?: (value: TValue) => string[];
}

export type FormComponentConfig<TFieldList> = {
    [F in keyof TFieldList]: TFieldList[F] extends FormFieldBase<infer TValue, infer TProps>
        ? FormComponentFieldConfig<TValue> & TProps
        : never;
};

export default abstract class FormComponent<
    TFieldList,
    P = {},
    S = {},
    SS = any,
    FN extends keyof TFieldList = keyof TFieldList
> extends React.Component<P, S & FormComponentState<TFieldList>, SS> {
    abstract formFieldsConfig: FormComponentConfig<TFieldList>;
    static contextType = AppContext;
    context!: React.ContextType<typeof AppContext>;

    protected ObjectTranslation: any = null;

    protected T(prop: string | TranslateObject | undefined, params?: ParamsType, r?: boolean) {
        if (!prop) {
            console.warn("Prop passes to CustomComponent T function was undefined");
            return "";
        }
        return this.context.translateService.translationProxy(prop, params, r, this.ObjectTranslation);
    }

    valueChange<TValue extends FormFieldBaseExtractTValue<TFieldList[FN]>>(fieldName: FN, value: TValue) {
        this.setState(prev => ({
            form: { ...prev.form, [fieldName]: { ...prev.form[fieldName], value: value } }
        }));
    }

    onBlur(fieldName: FN) {
        console.log("onBlur");
        this.validateField(fieldName);
    }

    onFocus(fieldName: FN) {
        console.log("onFocus");
    }

    validateField(fieldName: FN): boolean {
        if (!this.formFieldsConfig[fieldName].validate) {
            return true;
        }
        const errors = this.formFieldsConfig[fieldName].validate!(this.state.form[fieldName].value);

        this.setState(prev => ({
            form: { ...prev.form, [fieldName]: { ...prev.form[fieldName], error: errors } }
        }));

        return errors.length === 0;
    }

    validateAllFields(): boolean {
        const keys = Object.keys(this.formFieldsConfig) as Array<FN>;

        return keys.reduce((p, k) => {
            return this.validateField(k) && p;
        }, true as boolean);
    }

    onSubmit() {
        return this.validateAllFields();
    }

    fieldProps<TValue extends FormFieldBaseExtractTValue<TFieldList[FN]>>(fieldName: FN) {
        const label = this.formFieldsConfig[fieldName].label;

        return {
            value: this.state.form[fieldName].value as TValue,
            label: typeof label === "function" ? label() : label,
            valueChange: (value: any) => this.valueChange(fieldName, value),
            onBlur: () => this.onBlur(fieldName),
            onFocus: () => this.onFocus(fieldName),
            error: this.state.form[fieldName].error.join(". ")
        };
    }
}
