import React from 'react';
import { CustomComponent } from '../../../../../utils/CustomComponent';
import Translation from './Translation.json';
import moment from 'moment';
import { ContractOrderListModel } from '../../../../../../../../Api/types/src/areas/contract/Order.types';

import DetailsRow from '../../../../../utils/ViewDetails/DetailsRow';
import { MagazineOrderListModel } from '../../../../../../../../Api/types/src/areas/magazine/Order.types';

export interface Props {
    details: MagazineOrderListModel;
    T: CustomComponent['T'];
}

const TO = Translation.magazineOrderDetails;

const Element: React.FC<Props> = ({ details, T }) => (
    <div className='content-basic__header'>
        <div className='product-details'>
            <DetailsRow value={details.magazineOrderId} label={T(TO.orderId)} />
            <DetailsRow
                value={moment(details.orderDate).format('YYYY-MM-DD')}
                label={T(TO.orderDate)}
            />
            <DetailsRow
                value={moment(details.expectedShipmentDate).format('YYYY-MM-DD')}
                label={T(TO.shipmentDate)}
            />
            <DetailsRow value={T(details.magazineOrderStatusLabel)} label={T(TO.status)} />
            <DetailsRow value={details.magazineLocationLabel} label={T(TO.magazineLocation)} />
        </div>
    </div>
);

export default Element;
