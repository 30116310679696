import { DataSourceNew } from "../../utils/endpoint/DataSourceNew";
import { DataSourceFunction } from "../../utils/endpoint/DataSourceNew.function.types";

import {
    GetMagazineProductBarcodeFindParams,
    GetMagazineProductBarcodeFindResponse
} from "../../../../../Api/types/src/api/magazine/GetMagazineProductBarcodeFind.types";

export const GetProductBarcodeFindDataSource: DataSourceFunction<
    "MagazineGetProductBarcodeFind",
    GetMagazineProductBarcodeFindParams,
    undefined,
    GetMagazineProductBarcodeFindResponse
> = parent =>
    new DataSourceNew(
        {
            name: "MagazineGetProductBarcodeFind",
            endpointConfig: {
                url: "/v2/magazine/MagazineProductBarcodeFind",
                method: "GET"
            }
        },
        parent
    );
