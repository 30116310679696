import BasicFormComponent from '../../../../components/basicFormComponent/BasicFormComponent';
import React from 'react';

import appTranslate from '../../../../appTranslate.json';
import axios from 'axios';
import moment from 'moment';

import { emptyObject } from '../../../../services/translateService';

import TextField from '../../../../components/form/TextField';
import CheckBox from '../../../../components/form/CheckBox';
import SelectField from '../../../../components/form/SelectField';

import { checkNotEmptyText, checkValidDate } from '../../../../helpers/validator/Validator';

import Modal from '../../../../components/modal/Modal';
import Loader from '../../../../components/basic/Loader';

class AddModal extends BasicFormComponent {
    TObject = appTranslate.areas.magazine.supply.addModal;

    constructor(props) {
        super(props);

        this.state = {
            form: {
                magazineLocationId: {
                    value: undefined
                },
                supplyDate: {
                    value: moment().format('YYYY-MM-DD'),
                    error: '',
                    checks: [checkValidDate]
                }
            },
            locations: [],
            loader: false
        };
    }

    componentDidMount() {
        this.getLocations();
    }

    getLocations = () => {
        this.setLoader(true);
        this.context.authService
            .getAuthHeader()
            .then(authHeader => {
                return axios({
                    url: GEAPP_ADMIN_CONFIG.adminApiUrl + '/magazine/location/list',// eslint-disable-line no-undef
                    method: 'GET',
                    headers: {
                        ...authHeader,
                        'Content-Type': 'application/json'
                    }
                })
                    .then(response => {
                        console.log(response);
                        if (response.status === 200 && response.data.code === 200) {
                            const data = response.data.data;
                            this.setLoader(false);
                            this.setState(prevState => {
                                return {
                                    locations: data,
                                    form: {
                                        ...prevState.form,
                                        magazineLocationId: {
                                            ...prevState.form.magazineLocationId,
                                            value: data[0].magazineLocationId
                                        }
                                    }
                                };
                            });
                        }
                    })
                    .catch(error => {
                        console.log(error);
                    });
            })
            .catch(e => {
                console.log(e);
            })
            .finally(() => {
                // this.setLoader('properties', false);
            });
    };

    prepareData = () => {
        return {
            magazineLocationId: this.state.form.magazineLocationId.value,
            supplyDate: this.state.form.supplyDate.value
        };
    };

    onSubmit = () => {
        console.log(this.validateFields());
        if (this.validateFields()) {
            console.log(this.prepareData());
            this.submit();
        }
    };

    submit = () => {
        this.setLoader(true);
        this.context.authService
            .getAuthHeader()
            .then(authHeader => {
                return axios({
                    url: GEAPP_ADMIN_CONFIG.adminApiUrl + '/magazine/supply/insert',// eslint-disable-line no-undef
                    method: 'POST',
                    headers: {
                        ...authHeader,
                        'Content-Type': 'application/json'
                    },
                    data: {
                        supply: this.prepareData()
                    }
                })
                    .then(response => {
                        console.log(response);
                        this.props.close(true);
                    })
                    .catch(error => {
                        console.log(error);
                    });
            })
            .catch(e => {
                console.log(e);
            })
            .finally(() => {
                // this.setLoader('properties', false);
            });
    };

    render() {
        const footer = (
            <div className='space-between'>
                <button className='_button-light' onClick={() => this.onSubmit()}>
                    {this.T('save')}
                </button>

                <button className='_button-light' onClick={() => this.props.close(false)}>
                    {this.T('cancel')}
                </button>
            </div>
        );

        let loader = null;
        if (this.state.loader) {
            loader = <Loader />;
        }

        const content = (
            <div>
                {loader}

                <SelectField
                    label={this.T('magazineLocation')}
                    value={this.state.form.magazineLocationId.value}
                    options={this.state.locations.map(e => {
                        return {
                            value: e.magazineLocationId,
                            label: `${e.label}`
                        };
                    })}
                    onInput={value => this.onInputValue('magazineLocationId', value)}
                />

                <TextField
                    field={this.state.form.supplyDate}
                    type='date'
                    onInput={value => this.onInputValue('supplyDate', value)}
                    onBlur={() => this.validateField('supplyDate')}
                    label={this.T('supplyDate')}
                />
            </div>
        );
        return (
            <Modal
                title={this.T('title')}
                close={result => this.props.close(result)}
                footer={footer}
                content={content}
                visible={true}
            />
        );
    }
}

export default AddModal;
