import axios from 'axios';

import moment from 'moment';
import App, { AppState } from '../App';

export interface SessionModel {
    sessionId: number;
    sessionToken: string;
    expireDate: Date;
}

export interface IdentityModel {
    identityId: number;
    refreshToken: string;
    session: SessionModel;
}

export interface IdentityRoleModel extends IdentityModel {
    roleId: number;
}

export interface AuthHeader {
    GESessionId: number;
    GESessionToken: string;
}

export class AuthService {
    private parent: App;
    constructor(parent: App) {
        this.parent = parent;
    }

    isSessionActive() {
        if (!this.parent.state.session) {
            return false;
        }

        return true;
    }

    getRoleId = () => {
        return this.parent.state.session && this.parent.state.session.roleId
            ? this.parent.state.session.roleId
            : 2;
    };

    tryToLogin(login: string, password: string) {
        return new Promise((resolve, reject) => {
            axios({
                url: GEAPP_ADMIN_CONFIG.adminApiUrl + '/authorize/login',
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                data: {
                    login: login,
                    password: password
                }
            })
                .then(response => {
                    if (response.data.code === 200) {
                        console.log(response.data);
                        this.parent.setState({ session: response.data.data });
                        this.saveSession();
                    } else {
                        console.log('login error');
                        console.log(response.data);
                        reject(response.data.error.systemMessage);
                    }
                })
                .catch(error => {
                    console.log(error);
                    console.log(error.response);
                    reject('Błąd logowania');
                });
        });
    }

    tryToLogout() {
        return new Promise((resolve, reject) => {
            if (
                !this.parent.state.session ||
                !this.parent.state.session.identityId ||
                !this.parent.state.session.refreshToken
            ) {
                this.parent.setState({ session: undefined });
                this.saveSession();
                reject('No active session');
                return;
            }

            axios({
                url: GEAPP_ADMIN_CONFIG.adminApiUrl + '/authorize/logout',
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                data: {
                    identityId: this.parent.state.session.identityId,
                    refreshToken: this.parent.state.session.refreshToken
                }
            })
                .then(() => {
                    this.parent.setState({ session: undefined });
                    this.saveSession();
                })
                .catch(error => {
                    console.log(error);
                    console.log(error.response);
                });
        });
    }

    loadSession(): IdentityRoleModel | undefined {
        try {
            let sessionData = window.localStorage.getItem('geAppSession');
            if (!sessionData) {
                return undefined;
            }
            let session = JSON.parse(sessionData);
            return session;
        } catch {
            return undefined;
        }
    }

    saveSession() {
        window.localStorage.setItem('geAppSession', JSON.stringify(this.parent.state.session));
    }

    getAuthHeader(): Promise<AuthHeader> {
        return this.tryToRefreshSession().then(
            () =>
                ({
                    GESessionId: this.parent.state.session!.session.sessionId,
                    GESessionToken: this.parent.state.session!.session.sessionToken
                } as AuthHeader)
        );
    }

    tryToRefreshSession() {
        return new Promise((resolve, reject) => {
            if (
                !this.parent.state.session ||
                !this.parent.state.session.identityId ||
                !this.parent.state.session.refreshToken ||
                !this.parent.state.session.session
            ) {
                this.parent.setState({ session: undefined });
                this.saveSession();
                return reject('No active session');
            }

            if (moment().isAfter(this.parent.state.session.session.expireDate)) {
                axios({
                    url: GEAPP_ADMIN_CONFIG.adminApiUrl + '/authorize/refreshSession',
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    data: {
                        identityId: this.parent.state.session.identityId,
                        refreshToken: this.parent.state.session.refreshToken
                    }
                })
                    .then(response => {
                        if (response.data.code === 200) {
                            console.log(response.data);
                            this.parent.setState(
                                prevState => {
                                    return {
                                        session: {
                                            ...prevState.session!,
                                            session: response.data.data
                                        }
                                    };
                                },
                                () => {
                                    this.saveSession();
                                    resolve();
                                }
                            );
                        } else {
                            this.parent.setState({ session: undefined });
                            this.saveSession();
                            return reject('No active session');
                        }
                    })
                    .catch(error => {
                        reject(error);
                    });
            } else {
                resolve();
            }
        });
    }
}
