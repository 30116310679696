import React, { Component } from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import { RouteComponentProps } from "react-router";

import Details from "./Details/Details";
import List from "./List";

class StocktakingMain extends Component<RouteComponentProps> {
    render() {
        return (
            <Switch>
                <Route path={`${this.props.match.path}/details`} component={Details} />

                <Route path={`${this.props.match.path}`} component={List} />
            </Switch>
        );
    }
}

export default StocktakingMain;
