import React from "react";
import ModalWrapper from "../../../../../../utils/ModalWrapper";

import { ProductListItem } from "../../../../../../../../../Api/types/src/domain/catalogue/ProductList.types";

import { DataSourceStorageInitial } from "../../../../../../utils/endpoint/DataSourceNew";
import { DataSourceFunctionGetStorage } from "../../../../../../utils/endpoint/DataSourceNew.function.types";
import { GetStateDetailsDataSource } from "../../../../../../endpoints/catalogue/GetProductSearch";
import { BasicComponent } from "../../../../../../utils/BasicComponent/BasicComponent";

import TO from "./FindBarcodeModal.json";

export interface FindBarcodeModalProps {
    onClose: (barcode: string) => void;
    onDismiss: () => void;
}

export interface FindBarcodeModalState {
    searchValue: string;
}

export default class FindBarcodeModal extends BasicComponent<FindBarcodeModalProps, FindBarcodeModalState> {
    protected TranslateObject = {};

    state: FindBarcodeModalState = {
        searchValue: ""
    };

    render() {
        return (
            <ModalWrapper
                title={this.T(TO.title)}
                submit={this.T(TO.close)}
                cancel={this.T(TO.cancel)}
                onSubmit={() => this.onClose()}
                onCancel={() => this.props.onDismiss()}
            >
                <div className="_add-product-modal">
                    <div className="_add-product-modal__searchbar">
                        <input
                            className="_add-product-modal__searchbar-input full-round"
                            type="text"
                            value={this.state.searchValue}
                            onChange={e => this.setState({ searchValue: e.target.value })}
                            onKeyDown={e => this.onKeyDown(e as any)}
                        />
                    </div>
                </div>
            </ModalWrapper>
        );
    }

    private onKeyDown(e: KeyboardEvent) {
        if (e.key === "Enter") {
            this.onClose();
        }
    }

    private onClose() {
        this.props.onClose(this.state.searchValue);
    }
}
