import React from "react";
import Loader from "../basic/Loader";

import "./Overlay.scss";

export interface OverlayData {
    show?: boolean;
    title?: string;
    text?: string;
    child?: React.ReactNode;
    loader?: boolean;
}

const Overlay: React.FC<{ data: OverlayData }> = ({ data: { show, title, text, child, loader }, children }) => {
    if (!show) {
        return null;
    }
    return (
        <div className="overlay-new">
            {loader ? (
                <div className="ovwerlay-new__loader">
                    <div className="loader-small" />
                </div>
            ) : null}
            {title ? <div className="overlay-new__title">{title}</div> : null}
            {text ? <div className="overlay-new__text">{text}</div> : null}
            {child ? <div className="overlay-new__children">{child}</div> : null}
            <div className="overlay-new__children">{children}</div>
        </div>
    );
};

export default Overlay;
