import React from 'react';
import { MagazineOrderGroup } from '../../../../../../../../Api/types/src/areas/magazine/Order.types';
import './ListList.scss';

import ElementParent from './ListListParent';
import ElementChild from './ListListChild';
import Translation from './ListList.json';
import List from './List';

interface Props {
    elements: MagazineOrderGroup[];
    treeOpen: number | undefined;
    treeOpenChange: List['treeOpenChange'];
    T: List['T'];
    addShipment: (id: number) => void;
}

const Element: React.FC<Props> = ({ elements, treeOpen, treeOpenChange, T, addShipment }) => (
    <div className='_magazine-order-list-list__wrapper'>
        <div className='_magazine-order-list-list__header'>
            <div className='_magazine-order-list-list__header-icon' />
            <div className='_magazine-order-list-list__header-id'>{T(Translation.id)}</div>
            <div className='_magazine-order-list-list__header-contractor'>
                {T(Translation.contractor)}
            </div>
            <div className='_magazine-order-list-list__header-destination'>
                {T(Translation.destination)}
            </div>

            <div className='_magazine-order-list-list__header-created'>
                {T(Translation.created)}
            </div>

            <div className='_magazine-order-list-list__header-shipment-date'>
                {T(Translation.shipmentDate)}
            </div>

            <div className='_magazine-order-list-list__header-status'>{T(Translation.status)}</div>
            <div className='_magazine-order-list-list__header-products'>
                {T(Translation.products)}
            </div>
        </div>
        {elements.map(e => (
            <React.Fragment key={e.contractOrder.contractOrderId}>
                <ElementParent
                    key={e.contractOrder.contractOrderId}
                    element={e.contractOrder}
                    treeOpen={treeOpen}
                    treeOpenChange={treeOpenChange}
                    shippedProductCount={e.magazineOrderList.reduce(
                        (p, o) => p + o.productCount,
                        0
                    )}
                    T={T}
                    addShipment={addShipment}
                />
                {treeOpen === e.contractOrder.contractOrderId
                    ? e.magazineOrderList.map(o => (
                          <ElementChild key={o.magazineOrderId} element={o} T={T} />
                      ))
                    : null}
            </React.Fragment>
        ))}
    </div>
);

export default Element;
