import { CustomComponent } from '../CustomComponent';
import { AuthHeader } from '../../services/authService';

import Axios from 'axios';

export interface RequestError {
    message: string;
    systemMessage?: any;
}

export type ResponseCode =
    /** Response ok, data returned */
    | 200
    /** Response ok, entity created */
    | 201
    /** Response ok, no data returned */
    | 204
    /** Bad request */
    | 400
    /** Unathorized request */
    | 403
    /** Entity does not exists */
    | 404
    /** Provided data does not contain all requires fields */
    | 422
    /** Internal server error | Unknown error */
    | 500;

export interface RequestResponse {
    code: ResponseCode;
    data?: any;
    error?: RequestError;
}

export interface DataSourceConfig extends DataSourceConfigOptional {
    /** Endpoint URL which will be used */
    url: string;
    /** Method used to make request */
    method: DataSourceMethod;
}

export interface DataSourceConfigOptional {
    /** Object containing data that will be passed to endpoint in url (after ? mark) */
    params?: { [key: string]: string | number | undefined };
    /** Object containing data that will be passed to endpoint in body - only POST requests */
    data?: any;
    /** Callback function handling change of dataSource status */
    onStatusChange?: (state?: DataSourceStatus) => void | undefined;
}

export type DataSourceMethod = 'GET' | 'POST';

export type DataSourceStatus = 'INITIAL' | 'READY' | 'PENDING' | 'ERROR';

export class DataSource<ReturnType> {
    private parent: CustomComponent;
    private config: DataSourceConfig;

    private _status: DataSourceStatus;

    constructor(parent: CustomComponent, config: DataSourceConfig) {
        this.parent = parent;
        this.config = config;

        this._status = 'INITIAL';
    }

    get status(): DataSourceStatus {
        return this._status;
    }

    private changeState(status: DataSourceStatus, config: DataSourceConfig) {
        this._status = status;
        if (config && config.onStatusChange) {
            config.onStatusChange(status);
        } else if (this.parent.onStatusChange) {
            this.parent.onStatusChange();
        }
    }

    public makeRequest(config?: DataSourceConfigOptional): Promise<ReturnType> {
        const currentConfig: DataSourceConfig = { ...this.config, ...config };

        this.changeState('PENDING', currentConfig);

        return this.parent.context.authService
            .getAuthHeader()
            .then((authHeader: AuthHeader) => {
                return Axios({
                    url: GEAPP_ADMIN_CONFIG.adminApiUrl + currentConfig.url,
                    method: currentConfig.method,
                    headers: { ...authHeader, 'Content-Type': 'application/json' },
                    ...(currentConfig && currentConfig.params
                        ? { params: currentConfig.params }
                        : null),
                    ...(currentConfig && currentConfig.data ? { data: currentConfig.data } : null)
                })
                    .then(response => {
                        console.log(response);
                        const data: RequestResponse = response.data;
                        if (!data || !data.code) {
                            throw new Error('Invalid response');
                        }

                        if ([200, 201, 204].includes(data.code)) {
                            this.changeState('READY', currentConfig);
                            return data.data;
                        }

                        throw new Error(JSON.stringify(data));
                    })
                    .catch(e => {
                        this.changeState('ERROR', currentConfig);
                        throw e;
                    });
            })
            .catch((e: any) => {
                console.log('error catched');
                console.warn(e);
                this.changeState('ERROR', currentConfig);

                throw e;
                // throw e;
            });
    }
}
