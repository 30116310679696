import BasicFormComponent from '../../../../components/basicFormComponent/BasicFormComponent';
import React from 'react';

import appTranslate from '../../../../appTranslate.json';
import axios from 'axios';
import moment from 'moment';

import './ProductQuantityModal.scss';

import TextField from '../../../../components/form/TextField';
import CheckBox from '../../../../components/form/CheckBox';

import { checkNotEmptyText, checkValidDate } from '../../../../helpers/validator/Validator';

import Modal from '../../../../components/modal/Modal';
import Loader from '../../../../components/basic/Loader';

class ContractProductQuantityModal extends BasicFormComponent {
    TObject = appTranslate.areas.magazine.supply.productCountModal;

    constructor(props) {
        super(props);

        this.state = {
            products: this.props.params.supply.products,
            searchValue: '',
            searchResults: [],
            searchRequest: false,
            form: {},
            loader: false
        };
    }

    search = () => {};

    onSubmit = () => {
        console.log(this.checkForm());
        console.log(this.prepareData());
        //this.submit();
        if (this.checkForm()) {
            this.submit();
        }
    };

    onFieldChange = (productId, index, field, value, parser) => {
        this.setState(prev => ({
            products: prev.products.map(p =>
                p.productId === productId
                    ? {
                          ...p,
                          quantity: p.quantity.map((q, i) =>
                              i === index
                                  ? {
                                        ...q,
                                        [field]: parser ? parser(value) : value
                                    }
                                  : q
                          )
                      }
                    : p
            )
        }));
    };

    checkForm = () => {
        let valid = true;

        this.state.products.forEach(p => {
            let keys = {};
            p.quantity.forEach(q => {
                const date = moment(q.expireDate);
                if (!date.isValid()) {
                    valid = false;
                } else {
                    const index = date.format('YYYYMMDD');

                    if (keys[index]) {
                        valid = false;
                    }
                    keys[index] = true;
                }
            });
        });

        this.setState(prev => ({
            products: prev.products.map(p => {
                let keys = {};
                return {
                    ...p,
                    quantity: p.quantity.map(q => {
                        let error = '';
                        const date = moment(q.expireDate);
                        if (!date.isValid()) {
                            error = 'Invalid date';
                        } else {
                            const index = date.format('YYYYMMDD');
                            if (keys[index]) {
                                error = 'Duplicated date';
                            }
                            keys[index] = true;
                        }
                        return {
                            ...q,
                            error: error
                        };
                    })
                };
            })
        }));
        return valid;
    };

    prepareData = () => {
        return {
            magazineSupplyId: parseInt(this.props.params.id),
            products: this.state.products.map(p => ({
                productId: parseInt(p.productId),
                quantity: p.quantity.map(q => ({
                    quantity: parseInt(q.quantity),
                    expireDate: q.isExpiration ? q.expireDate : undefined
                }))
            }))
        };
    };

    submit = () => {
        this.setLoader(true);
        this.context.authService
            .getAuthHeader()
            .then(authHeader => {
                return axios({
                    url: GEAPP_ADMIN_CONFIG.adminApiUrl + '/magazine/supply/updateProducts',// eslint-disable-line no-undef
                    method: 'POST',
                    headers: {
                        ...authHeader,
                        'Content-Type': 'application/json'
                    },
                    data: {
                        data: this.prepareData()
                    }
                })
                    .then(response => {
                        console.log(response);
                        this.props.close(true);
                    })
                    .catch(error => {
                        console.log(error);
                    });
            })
            .catch(e => {
                console.log(e);
            })
            .finally(() => {
                // this.setLoader('properties', false);
            });
    };

    onSearchInput = value => {
        this.setState(prev => ({ searchValue: value }));
    };

    onSearchInputKeyPress = event => {
        if (event.key === 'Enter') {
            this.onSearchSearch();
        }
    };

    onSearchClear = () => {
        this.setState(prev => ({ searchValue: '', searchResults: [], searchRequest: false }));
    };

    onSearchSearch = () => {
        if (this.state.searchValue === '') {
            return;
        }
        this.setLoader(true);
        this.context.authService
            .getAuthHeader()
            .then(authHeader => {
                return axios({
                    url:
                        GEAPP_ADMIN_CONFIG.adminApiUrl +// eslint-disable-line no-undef
                        '/catalogue/products/product/search/' +
                        this.state.searchValue,
                    method: 'GET',
                    headers: {
                        ...authHeader,
                        'Content-Type': 'application/json'
                    }
                })
                    .then(response => {
                        console.log(response);
                        if (response.status === 200 && response.data.code === 200) {
                            const data = response.data.data;
                            this.setLoader(false);
                            this.setState(prevState => {
                                return {
                                    searchResults: data,
                                    searchRequest: true
                                };
                            });
                        }
                    })
                    .catch(error => {
                        console.log(error);
                    });
            })
            .catch(e => {
                console.log(e);
            })
            .finally(() => {
                // this.setLoader('properties', false);
            });
    };

    onQuantityChange = (productId, quantity) => {
        if (quantity !== '') {
            quantity = parseInt(quantity);
            if (!Number.isInteger(quantity)) {
                return;
            }

            if (quantity < 0) {
                quantity = quantity * -1;
            }
        }
        this.setState(prev => ({
            products: prev.products.map(p =>
                p.productId === productId ? { ...p, quantity: { ...p.quantity, new: quantity } } : p
            )
        }));
    };

    onQuantityBlur = productId => {
        this.setState(prev => ({
            products: prev.products.map(p =>
                p.productId === productId &&
                (p.quantity.new === '' || p.quantity.new < p.quantity.used)
                    ? { ...p, quantity: { ...p.quantity, new: p.quantity.used } }
                    : p
            )
        }));
    };

    onProductRemove = productId => {
        this.setState(prev => {
            return {
                products: prev.products.filter(p => p.productId !== productId)
            };
        });
    };

    onProductAdd = p => {
        const product = {
            ...p,
            quantity: p.isExpiration ? [] : [{ quantity: 0 }]
        };
        this.setState(prev => ({ products: [...prev.products, product] }));
    };

    onQuantityAdd = productId => {
        const quantity = {
            expireDate: moment().format('YYYY-MM-DD'),
            isExpiration: true,
            quantity: 0
        };
        this.setState(prev => ({
            products: prev.products.map(p => {
                if (p.productId === productId) {
                    return {
                        ...p,
                        quantity: [...p.quantity, quantity]
                    };
                }
                return p;
            })
        }));
    };

    onQuantityRemove = (productId, index) => {
        this.setState(prev => ({
            products: prev.products.map(p =>
                p.productId === productId
                    ? {
                          ...p,
                          quantity: p.quantity.filter(
                              (q, i) => i !== index || q.isExpiration === false
                          )
                      }
                    : p
            )
        }));
    };

    render() {
        const footer = (
            <div className='space-between'>
                <button className='_button-light' onClick={() => this.onSubmit()}>
                    {this.T('save')}
                </button>

                <button className='_button-light' onClick={() => this.props.close(false)}>
                    {this.T('cancel')}
                </button>
            </div>
        );

        let loader = null;
        if (this.state.loader) {
            loader = <Loader />;
        }

        const content = (
            <div>
                {loader}

                <div className='_contract-product-query-modal__products__wrapper'>
                    {this.state.products.map(p => (
                        <div className='_magazine-supply-product-modal__product' key={p.productId}>
                            <div className='_magazine-supply-product-modal__product-info'>
                                <div className='_magazine-supply-product-modal__product-info-name'>
                                    {this.T(p.productName)}
                                </div>
                                <div className='_magazine-supply-product-modal__product-info-ean'>
                                    {p.ean}
                                </div>
                                <div className='_magazine-supply-product-modal__product-info-category'>
                                    {this.T(p.mainCategoryLabel)} > {this.T(p.categoryLabel)}
                                </div>
                            </div>
                            <div className='_magazine-supply-product-modal__product-quantities'>
                                <div className='_magazine-supply-product-modal__product-quantity'>
                                    {p.isExpiration ? (
                                        <div className='_magazine-supply-product-modal__product-quantity-field'>
                                            <div className='_magazine-supply-product-modal__product-quantity-field-label'>
                                                Data ważności
                                            </div>
                                        </div>
                                    ) : null}

                                    <div className='_magazine-supply-product-modal__product-quantity-field'>
                                        <div className='_magazine-supply-product-modal__product-quantity-field-label'>
                                            Liczba sztuk
                                        </div>
                                    </div>
                                    <div className='_magazine-supply-product-modal__product-quantity-remove' />
                                </div>
                                {p.quantity.map((q, index) => (
                                    <div
                                        className='_magazine-supply-product-modal__product-quantity'
                                        key={p.productId + index}
                                    >
                                        {q.isExpiration ? (
                                            <div className='_magazine-supply-product-modal__product-quantity-field'>
                                                <input
                                                    value={q.expireDate}
                                                    type='date'
                                                    className='_magazine-supply-product-modal__product-quantity-field-input'
                                                    name={`expireDate-${p.productId}-${index}`}
                                                    onChange={e =>
                                                        this.onFieldChange(
                                                            p.productId,
                                                            index,
                                                            'expireDate',
                                                            e.target.value
                                                        )
                                                    }
                                                />

                                                {q.error ? (
                                                    <div className='_magazine-supply-product-modal__product-quantity-field-error'>
                                                        {q.error}
                                                    </div>
                                                ) : null}
                                            </div>
                                        ) : null}

                                        <div className='_magazine-supply-product-modal__product-quantity-field'>
                                            <input
                                                type='text'
                                                value={q.quantity}
                                                className='_magazine-supply-product-modal__product-quantity-field-input'
                                                onChange={e =>
                                                    this.onFieldChange(
                                                        p.productId,
                                                        index,
                                                        'quantity',
                                                        e.target.value,
                                                        value => {
                                                            return Number.isNaN(parseInt(value))
                                                                ? ''
                                                                : parseInt(value);
                                                        }
                                                    )
                                                }
                                            />
                                        </div>
                                        <div className='_magazine-supply-product-modal__product-quantity-remove'>
                                            {q.isExpiration ? (
                                                <div
                                                    className='_magazine-supply-product-modal__product-quantity-remove-button'
                                                    onClick={() =>
                                                        this.onQuantityRemove(p.productId, index)
                                                    }
                                                >
                                                    close
                                                </div>
                                            ) : null}
                                        </div>
                                    </div>
                                ))}

                                {p.isExpiration ? (
                                    <div
                                        className='_magazine-supply-product-modal__product-quantity-add'
                                        onClick={() => this.onQuantityAdd(p.productId)}
                                    >
                                        dodaj
                                    </div>
                                ) : null}
                            </div>
                            <div
                                className='_magazine-supply-product-modal__product-remove'
                                onClick={() => this.onProductRemove(p.productId)}
                            >
                                <div className='_magazine-supply-product-modal__product-remove-button'>
                                    close
                                </div>
                            </div>
                        </div>
                    ))}
                    {this.state.products.length === 0 ? (
                        <div className='_contract-product-query-modal__products__empty'>
                            {this.T('productEmpty')}
                        </div>
                    ) : null}
                </div>

                <div className='_contract-product-query-modal__search-field__wrapper'>
                    <input
                        className='_contract-product-query-modal__search-field__input'
                        type='text'
                        value={this.state.searchValue}
                        onChange={event => this.onSearchInput(event.target.value)}
                        onKeyPress={event => this.onSearchInputKeyPress(event)}
                    />
                    <button
                        className='_contract-product-query-modal__search-field__button-search'
                        onClick={() => this.onSearchSearch()}
                    >
                        {this.T('searchButton')}
                    </button>
                    <button
                        className='_contract-product-query-modal__search-field__button-clear'
                        onClick={() => this.onSearchClear()}
                    >
                        {this.T('clearButton')}
                    </button>
                </div>
                <div className='_contract-product-query-modal__search-results__wrapper'>
                    {this.state.searchResults.map(p => (
                        <div
                            className='_contract-product-query-modal__search-result'
                            key={p.productId}
                        >
                            <div className='_contract-product-query-modal__search-result__info'>
                                <div className='_contract-product-query-modal__search-result__info-name'>
                                    {this.T(p.productName)}
                                </div>
                                <div className='_contract-product-query-modal__search-result__info-ean'>
                                    {p.ean}
                                </div>
                                <div className='_contract-product-query-modal__search-result__info-category'>
                                    {this.T(p.mainCategoryLabel)} > {this.T(p.categoryLabel)}
                                </div>
                            </div>

                            <div className='_contract-product-query-modal__search-result__buttons'>
                                {this.state.products.findIndex(e => e.productId === p.productId) ===
                                -1 ? (
                                    <button onClick={() => this.onProductAdd(p)}>add</button>
                                ) : null}
                            </div>
                        </div>
                    ))}
                    {this.state.searchRequest && this.state.searchResults.length === 0 ? (
                        <div className='_contract-product-query-modal__search-results__empty'>
                            {this.T('resultsEmpty')}
                        </div>
                    ) : null}
                    {!this.state.searchRequest ? (
                        <div className='_contract-product-query-modal__search-results__empty'>
                            {this.T('searchToAdd')}
                        </div>
                    ) : null}
                </div>
            </div>
        );
        return (
            <Modal
                title={this.T('title')}
                close={result => this.props.close(result)}
                footer={footer}
                content={content}
                visible={true}
            />
        );
    }
}

export default ContractProductQuantityModal;
