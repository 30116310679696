import React, { useContext, useEffect, useState } from "react";
import { LocalizedValue } from "../../../../../../../Api/types/src/helpers/Localize.types";
import { GetCategoryTree } from "../../../../endpoints/catalogue/GetCategoryTree";
import { PostCategoryReorder } from "../../../../endpoints/catalogue/PostCategoryReorder";
import { useDataSource, ConfigToData } from "../../../../hooks/useDataSource";
import { useTranslate } from "../../../../hooks/useTranslate";
import ModalWrapper from "../../../../utils/ModalWrapper";
import Loader from "../../../../components/basic/Loader";

import TO from "./Translate.json";

import "./CatalogueCategoriesReorder.scss";

interface Props {
    close: (response: boolean) => void;
}

interface Order {
    [key: number]: number;
}

const CatalogueCategoriesReorder: React.FC<Props> = ({ close }) => {
    const T = useTranslate();

    const dsGetCategoryTree = useDataSource(GetCategoryTree);
    const dsPostCategoryReorder = useDataSource(PostCategoryReorder);

    const [order, setOrder] = useState<Order>({});

    useEffect(() => {
        load();
        return () => {
            dsGetCategoryTree.cancel();
            dsPostCategoryReorder.cancel();
        };
    }, []);

    useEffect(() => {
        if (dsPostCategoryReorder.state.status === "completed") {
            close(true);
        }
    }, [dsPostCategoryReorder.state]);

    useEffect(() => {
        if (dsGetCategoryTree.state.status !== "completed") return;

        console.log("generate order");

        setOrder(
            dsGetCategoryTree.state.response.data.reduce((o, c, indexMain) => {
                o[c.categoryId] = indexMain;
                for (let i = 0; i < c.categories.length; i++) {
                    o[c.categories[i].categoryId] = i;
                }
                console.log(o);
                return o;
            }, {} as Order)
        );
    }, [dsGetCategoryTree.state]);

    const categories =
        dsGetCategoryTree.state.status === "completed"
            ? dsGetCategoryTree.state.response.data
                  .sort((a, b) => order[a.categoryId] - order[b.categoryId])
                  .map((c) => ({
                      ...c,
                      categories: c.categories.sort((a, b) => order[a.categoryId] - order[b.categoryId]),
                  }))
            : [];

    const showLoader = dsGetCategoryTree.state.status !== "completed" || dsPostCategoryReorder.state.status !== "idle";

    return (
        <ModalWrapper
            title={T(TO.reorder.title)}
            submit={T(TO.reorder.submit)}
            cancel={T(TO.reorder.cancel)}
            onSubmit={() => submit()}
            onCancel={() => close(false)}
        >
            {dsGetCategoryTree.state.status === "completed"
                ? categories.map((category, index) => (
                      <CategoryItem
                          key={category.categoryId}
                          category={category}
                          indexMain={index}
                          reorderMain={reorderCategoryMain}
                          reorderChild={reorderCategory}
                      />
                  ))
                : null}
            {showLoader ? <Loader /> : null}
        </ModalWrapper>
    );

    function submit() {
        const keys = Object.keys(order).map((e) => parseInt(e));
        const data: ConfigToData<typeof PostCategoryReorder> = {
            list: keys.map((categoryId) => ({ categoryId: categoryId, ordered: order[categoryId] })),
        };

        console.log(data);

        dsPostCategoryReorder.load({ data: data });
    }

    async function load() {
        await dsGetCategoryTree.load({});
    }

    function reorderCategoryMain(index: number, direction: -1 | 1) {
        let newIndex = index + direction;
        if (newIndex < 0 || newIndex >= categories.length) return;

        const currentItem = categories[index];
        const replaceItem = categories[newIndex];

        setOrder((p) => ({ ...p, [replaceItem.categoryId]: index, [currentItem.categoryId]: newIndex }));
    }

    function reorderCategory(indexMain: number, index: number, direction: -1 | 1) {
        let newIndex = index + direction;
        if (newIndex < 0 || newIndex >= categories[indexMain].categories.length) return;

        const currentItem = categories[indexMain].categories[index];
        const replaceItem = categories[indexMain].categories[newIndex];

        setOrder((p) => ({ ...p, [replaceItem.categoryId]: index, [currentItem.categoryId]: newIndex }));
    }
};

export default CatalogueCategoriesReorder;

interface Category {
    categoryId: number;
    label: LocalizedValue<string>;
    ordered: number;
    categories?: Category[];
}

interface CategoryItemProps {
    category: Category;
    indexMain: number;
    reorderMain: (index: number, direction: -1 | 1) => void;
    reorderChild: (indexMain: number, index: number, direction: -1 | 1) => void;
}

const CategoryItem: React.FC<CategoryItemProps> = ({ category, indexMain, reorderChild, reorderMain }) => {
    const T = useTranslate();

    const hasChildren = !!category.categories && category.categories.length > 0;

    const [isOpen, setIsOpen] = useState(false);

    return (
        <div className="category-reorder-item__wrapper">
            <div className="category-reorder-item__element">
                <button className="category-reorder-item__button" onClick={() => reorderMain(indexMain, -1)}>
                    arrow_upward
                </button>
                <button className="category-reorder-item__button" onClick={() => reorderMain(indexMain, 1)}>
                    arrow_downward
                </button>
                <div className="category-reorder-item__title" onClick={() => setIsOpen((p) => !p)}>
                    {T(category.label)}
                </div>
                {hasChildren ? (
                    <button className="category-reorder-item__button" onClick={() => setIsOpen((p) => !p)}>
                        {isOpen ? "keyboard_arrow_up" : "keyboard_arrow_down"}
                    </button>
                ) : null}
            </div>
            {hasChildren && isOpen ? (
                <div className="category-reorder-item__children">
                    {category.categories!.map((c, i) => (
                        <CategoryItem
                            key={c.categoryId}
                            category={c}
                            reorderMain={(index, direction) => reorderChild(indexMain, index, direction)}
                            indexMain={i}
                            reorderChild={() => {}}
                        />
                    ))}
                </div>
            ) : null}
        </div>
    );
};
