import React from "react";
import ModalWrapper from "../../../../../../utils/ModalWrapper";

import moment from "moment";

import "./SelectDateModal.scss";

import AddModalForm from "./SelectDateModal.form";
import { MagazineStateInsert } from "../../../../../../../../../Api/types/src/domain/magazine/MagazineState.types";

import { GetLocationListDataSource } from "../../../../../../endpoints/magazine/GetLocationList";
import { PostStateInsertDataSource } from "../../../../../../endpoints/magazine/PostStateInsert";
import { DataSourceStorageInitial } from "../../../../../../utils/endpoint/DataSourceNew";
import { DataSourceFunctionGetStorage } from "../../../../../../utils/endpoint/DataSourceNew.function.types";
import { BasicComponent } from "../../../../../../utils/BasicComponent/BasicComponent";

import TO from "./SelectDateModal.json";
import { ProductListItem } from "../../../../../../../../../Api/types/src/domain/catalogue/ProductList.types";

export interface SelectDateModalProps {
    product: ProductListItem;
    isExpiration: boolean;
    expireDate?: string;
    count?: string;
}

export interface SelectDateModalResponseData {
    productId: number;
    isExpiration: boolean;
    expireDate?: string;
    count: number;
}

export interface MagazineStateSelectDateModalProps {
    props: SelectDateModalProps;
    onClose: (data: SelectDateModalResponseData) => void;
    onDismiss: () => void;
}

export default class SelectDateModal extends BasicComponent<MagazineStateSelectDateModalProps> {
    protected TranslateObject = {};

    private FormRef = React.createRef<AddModalForm>();

    render() {
        return (
            <ModalWrapper
                title={this.T(TO.title)}
                submit={this.T(TO.add)}
                cancel={this.T(TO.cancel)}
                onSubmit={() => this.onSubmit()}
                onCancel={() => this.props.onDismiss()}
            >
                <div className="select-date-modal__product-details">
                    <div className="select-date-modal__product-details-name">
                        {this.T(this.props.props.product.productName)}
                    </div>
                    <div className="select-date-modal__product-details-ean">{this.props.props.product.ean}</div>
                </div>
                <AddModalForm ref={this.FormRef} initial={this.props.props} onEnter={() => this.onSubmit()} />
            </ModalWrapper>
        );
    }

    private prepareDate(): SelectDateModalResponseData | undefined {
        if (!this.FormRef.current) {
            return undefined;
        }
        const form = this.FormRef.current.state.form;
        return {
            productId: this.props.props.product.productId,
            isExpiration: this.props.props.isExpiration,
            expireDate: this.props.props.isExpiration ? moment(form.expireDate.value).format("YYYY-MM-DD") : undefined,
            count: parseInt(form.count.value)
        };
    }

    private async onSubmit() {
        if (!this.FormRef.current || !this.FormRef.current.onSubmit()) {
            return;
        }

        const data = this.prepareDate();

        if (!data) {
            return;
        }

        this.props.onClose(data);
    }
}
