import React from 'react';
import FormComponent, { FormComponentConfig } from '../../../../../utils/form/FormComponent';
import TextField, { TextFieldInitialState } from '../../../../../utils/form/fields/TextField';
import DatePicker, {
    DatePickerInitialState,
    DatePickerValidateDate
} from '../../../../../utils/form/fields/DatePicker';
import SelectField, { SelectFieldInitialState } from '../../../../../utils/form/fields/SelectField';
import moment from 'moment';
import { MagazineLocation } from '../../../../../../../../Api/types/src/areas/magazine/Location.types';
import { MagazineOrderListModel } from '../../../../../../../../Api/types/src/areas/magazine/Order.types';

interface FieldList {
    orderDate: DatePicker;
    shipmentDate: DatePicker;
    magazineLocation: SelectField;
}

interface Props {
    magazineLocations: MagazineLocation[];
    magazineOrder: MagazineOrderListModel;
}

export default class AddModalForm extends FormComponent<FieldList, Props> {
    state = {
        form: {
            orderDate: DatePickerInitialState(
                moment(this.props.magazineOrder.orderDate).format('YYYY-MM-DD')
            ),
            shipmentDate: DatePickerInitialState(
                moment(this.props.magazineOrder.expectedShipmentDate).format('YYYY-MM-DD')
            ),
            magazineLocation: SelectFieldInitialState(this.props.magazineOrder.magazineLocationId)
        }
    };

    formFieldsConfig: FormComponentConfig<FieldList> = {
        orderDate: {
            label: 'Order date',
            validate: value => DatePickerValidateDate(value)
        },
        shipmentDate: {
            label: 'Shipment date',
            validate: value => DatePickerValidateDate(value)
        },
        magazineLocation: {
            label: 'Magazine location',
            options: []
        }
    };

    render() {
        return (
            <div>
                <DatePicker {...this.fieldProps('orderDate')} />
                <DatePicker {...this.fieldProps('shipmentDate')} />
                <SelectField
                    {...this.fieldProps('magazineLocation')}
                    options={this.props.magazineLocations.map(l => ({
                        id: l.magazineLocationId,
                        label: l.label
                    }))}
                />
            </div>
        );
    }
}
