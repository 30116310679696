import React from "react";
import ModalWrapper from "../../../../../utils/ModalWrapper";

import moment from "moment";

import AddModalForm from "./AddModal.form";
import { MagazineStateInsert } from "../../../../../../../../Api/types/src/domain/magazine/MagazineState.types";

import { GetLocationListDataSource } from "../../../../../endpoints/magazine/GetLocationList";
import { PostStateInsertDataSource } from "../../../../../endpoints/magazine/PostStateInsert";
import { DataSourceStorageInitial } from "../../../../../utils/endpoint/DataSourceNew";
import { DataSourceFunctionGetStorage } from "../../../../../utils/endpoint/DataSourceNew.function.types";
import { BasicComponent } from "../../../../../utils/BasicComponent/BasicComponent";

import TO from "./AddModal.json";

export interface MagazineStateAddModalProps {
    onClose: () => void;
    onDismiss: () => void;
}

export interface MagazineStateAddModalState {
    datasource: {
        MagazineGetLocationList: DataSourceFunctionGetStorage<typeof GetLocationListDataSource>;
        MagazinePostStateInsert: DataSourceFunctionGetStorage<typeof PostStateInsertDataSource>;
    };
}

export default class MagazineStateAddModal extends BasicComponent<
    MagazineStateAddModalProps,
    MagazineStateAddModalState
> {
    state: MagazineStateAddModalState = {
        datasource: {
            MagazineGetLocationList: DataSourceStorageInitial,
            MagazinePostStateInsert: DataSourceStorageInitial
        }
    };

    protected TranslateObject = {};

    private dsMagazineLocation = GetLocationListDataSource(this);
    private dsMagazineStateInsert = PostStateInsertDataSource(this);

    private FormRef = React.createRef<AddModalForm>();

    componentDidMount() {
        this._isMounted = true;
        this.dsMagazineLocation.request({});
    }

    render() {
        return (
            <ModalWrapper
                title={this.T(TO.title)}
                submit={this.T(TO.add)}
                cancel={this.T(TO.cancel)}
                onSubmit={() => this.onSubmit()}
                onCancel={() => this.props.onDismiss()}
            >
                {!!this.dsMagazineLocation.data && this.dsMagazineLocation.data.length > 0 ? (
                    <AddModalForm
                        ref={this.FormRef}
                        magazineLocations={this.dsMagazineLocation.data}
                        T={t => this.T(t)}
                    />
                ) : null}
            </ModalWrapper>
        );
    }

    private prepareDate(): MagazineStateInsert | undefined {
        if (!this.FormRef.current) {
            return undefined;
        }
        const form = this.FormRef.current.state.form;
        return {
            stateDate: moment(form.orderDate.value).format("YYYY-MM-DD"),
            magazineLocationId: form.magazineLocation.value
        };
    }

    private async onSubmit() {
        console.log("on submit in modal");
        if (!this.FormRef.current || !this.FormRef.current.onSubmit()) {
            return;
        }

        const data = this.prepareDate();

        if (!data) {
            return;
        }

        console.log(data);

        if (this.dsMagazineStateInsert.state !== "idle") {
            return;
        }
        this.addOrder(data);
    }

    private async addOrder(data: MagazineStateInsert) {
        try {
            await this.dsMagazineStateInsert.request({
                data: data
            });
            this.props.onClose();
        } catch (e) {}
    }
}
