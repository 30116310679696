import React from 'react';
import { FieldBase } from './FieldBase';

import './TextField.scss';

class TextField extends FieldBase {
    render() {
        return (
            <div
                className={
                    '_text-field ' +
                    (this.props.error && this.props.error.length > 0
                        ? '_text-field--is-error'
                        : '')
                }
            >
                <input
                    className='_text-field__input'
                    value={this.props.field.value}
                    type={this.props.type ? this.props.type : 'text'}
                    name={this.props.fieldName}
                    onChange={e => this.onInput(e.target.value)}
                    onBlur={() => this.onBlur()}
                    onFocus={() => this.onFocus()}
                />
                <div className='_text-field__label'>{this.props.label}</div>
                <div className='_text-field__error'>{this.props.field.error}</div>
            </div>
        );
    }
}

export default TextField;
