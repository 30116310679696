import React from "react";
import { AppContext } from "../../services/context";

export abstract class BasicComponent<P = {}, S = {}, SS = any> extends React.Component<P, S, SS> {
    static contextType = AppContext;
    public context!: React.ContextType<typeof AppContext>;

    public _isMounted = false;

    protected abstract TranslateObject: any;

    T = (prop: any, params?: any, r?: any) => {
        return this.context.translateService.translationProxy(prop, params, r, this.TranslateObject);
    };

    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }
}
