import React from 'react';
import { CustomComponent } from '../../../../../utils/CustomComponent';
import Translation from './Translation.json';
import moment from 'moment';
import { ContractOrderListModel } from '../../../../../../../../Api/types/src/areas/contract/Order.types';

import DetailsRow from '../../../../../utils/ViewDetails/DetailsRow';

export interface Props {
    details: ContractOrderListModel;
    T: CustomComponent['T'];
}

const TO = Translation.contractOrderDetails;

const Element: React.FC<Props> = ({ details, T }) => (
    <div className='content-basic__header'>
        <div className='product-details'>
            <DetailsRow value={details.contractOrderId} label={T(TO.orderId)} />
            <DetailsRow value={T(details.contractOrderStatus.label)} label={T(TO.orderStatus)} />
            <DetailsRow
                value={moment(details.shipmentDate).format('YYYY-MM-DD')}
                label={T(TO.shipmentDate)}
            />
            <DetailsRow value={details.company.companyName} label={T(TO.contractor)} />
            <DetailsRow
                value={
                    <React.Fragment>
                        {details.destination.label}
                        <br />
                        {details.destination.street} {details.destination.houseNumber}
                        <br /> {details.destination.postalCode} {details.destination.country}
                    </React.Fragment>
                }
                label={T(TO.destination)}
            />
        </div>
    </div>
);

export default Element;
