import React from 'react';
import {
    CustomComponent,
    CustomComponentInitialState,
    CustomComponentState
} from '../../../../../utils/CustomComponent';
import { ModalBaseProps } from '../../../../../utils/Modal';
import ModalWrapper from '../../../../../utils/ModalWrapper';
import {
    CustomComponentModal,
    CustomComponentModalInitialState
} from '../../../../../utils/CustomComponentModal';
import FormComponent from '../../../../../utils/form/FormComponent';

import moment from 'moment';

import EditModalForm from './EditStatus.form';

import { DataSource } from '../../../../../utils/dataSource/DataSource';
import { CustomComponentViewStateWithModals } from '../../../../../utils/CustomComponentView';
import DataSourceLoader from '../../../../../utils/DataSourceLoader';
import { MagazineLocation } from '../../../../../../../../Api/types/src/areas/magazine/Location.types';
import { number } from 'prop-types';
import {
    MagazineOrderInsertModel,
    MagazineOrderListModel,
    MagazineOrderUpdateModel
} from '../../../../../../../../Api/types/src/areas/magazine/Order.types';
import {
    MagazineOrderStatusModel,
    MagazineOrderStatusAddParams
} from '../../../../../../../../Api/types/src/areas/magazine/OrderStatus.types';
import TO from './Translate.json';

interface Props {
    magazineOrder: MagazineOrderListModel;
}

interface DataSources {
    statusList: DataSource<MagazineOrderStatusModel[]>;
    editStatus: DataSource<undefined>;
}

interface State extends CustomComponentState {
    statusList: MagazineOrderStatusModel[];
}

export default class AddModal extends CustomComponentModal<{}, DataSources, Props, State> {
    state: State = {
        ...CustomComponentInitialState,
        statusList: []
    };

    protected dataSource: DataSources = {
        statusList: new DataSource(this, {
            url: '/magazine/order/status/list',
            method: 'GET'
        }),
        editStatus: new DataSource(this, {
            url: '/magazine/order/updateStatus',
            method: 'POST'
        })
    };

    componentDidMount() {
        this._isMounted = true;
        this.getStatusList();
    }

    getStatusList() {
        this.dataSource.statusList
            .makeRequest()
            .then(data => this._isMounted && this.setState({ statusList: data }));
    }

    updateStatus(params: MagazineOrderStatusAddParams) {
        return this.dataSource.editStatus.makeRequest({ data: { params: params } });
    }

    private FormRef = React.createRef<EditModalForm>();

    private prepareDate(): MagazineOrderStatusAddParams | undefined {
        if (!this.FormRef.current) {
            return undefined;
        }
        const form = this.FormRef.current.state.form;

        return {
            magazineOrderId: this.props.customProps.magazineOrder.magazineOrderId,
            magazineOrderStatusId: form.magazineOrderStatusId.value,
            reason: form.reason.value,
            adminId: -1
        };
    }

    private onSubmit() {
        console.log('on submit in modal');
        if (!this.FormRef.current || !this.FormRef.current.onSubmit()) {
            return;
        }

        const data = this.prepareDate();

        if (!data) {
            return;
        }

        this.updateStatus(data).then(() => this.props.onClose());
    }

    render() {
        if (!this.props.state) {
            return null;
        }
        return (
            <ModalWrapper
                title={this.T(TO.title)}
                submit={this.T(TO.submit)}
                cancel={this.T(TO.cancel)}
                onSubmit={() => this.onSubmit()}
                onCancel={() => this.props.onDismiss()}
            >
                <DataSourceLoader
                    status={this.state.dataSourcesStatus}
                    language={this.context.translateService.currentLanguage()}
                />
                {this.state.statusList.length > 0 ? (
                    <EditModalForm
                        ref={this.FormRef}
                        statusList={this.state.statusList}
                        magazineOrder={this.props.customProps.magazineOrder}
                    />
                ) : null}
            </ModalWrapper>
        );
    }
}
