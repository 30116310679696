import React from "react";
import FormFieldBase from "../FieldBase";
import FormFieldWrapper from "../FieldWrapper";
import moment from "moment";

export type DatePickerValue = string;

const classBase = "form-field";

export function DatePickerInitialState(value?: DatePickerValue) {
    return {
        value: value || moment().format("YYYY-MM-DD"),
        error: [] as string[]
    };
}

export default class DatePicker extends FormFieldBase<DatePickerValue> {
    render() {
        return (
            <FormFieldWrapper {...this.props}>
                <input
                    className={`${classBase}__date-picker-input`}
                    type="date"
                    value={this.props.value}
                    onChange={e => this.props.valueChange(e.target.value)}
                    onFocus={() => this.props.onFocus && this.props.onFocus()}
                    onBlur={() => this.props.onBlur && this.props.onBlur()}
                    onKeyDown={e => this.onKey(e)}
                    id={this.props.id}
                />
            </FormFieldWrapper>
        );
    }
}

export function DatePickerValidateDate(value: string): string[] {
    if (!value || value === "" || !moment(value).isValid() || moment(value).year() > moment().year() + 10) {
        return ["Date must be valid"];
    } else {
        console.log(moment(value).format("YYYY-MM-DD"));
    }
    return [];
}
