import React from 'react';
import {
    CustomComponentState,
    CustomComponent,
    CustomComponentInitialState
} from './CustomComponent';
import {
    CustomComponentModal,
    CustomComponentModalExtractProps,
    CustomComponentModalExtractReturnType,
    CustomComponentModalExtractPropsComponent
} from './CustomComponentModal';
import { string } from 'prop-types';

export type CustomComponentViewStateModal<T extends CustomComponentModal> =
    | {
          state: true;
          props: CustomComponentModalExtractProps<T>;
      }
    | { state: false; props: undefined };

export interface CustomComponentViewModalHandler<M extends CustomComponentModal> {
    onClose?: (response?: CustomComponentModalExtractReturnType<M>) => void;
    onDismiss?: () => void;
}

export type CustomComponentViewStateModals<T> = {
    [M in keyof T]: T[M] extends CustomComponentModal ? CustomComponentViewStateModal<T[M]> : never
};

export type CustomComponentViewModalHandlers<T> = {
    [M in keyof T]?: T[M] extends CustomComponentModal
        ? CustomComponentViewModalHandler<T[M]>
        : never
};

export interface CustomComponentViewState extends CustomComponentState {
    treeOpen: number | undefined;
}

export interface CustomComponentViewStateWithModals<TModalList> extends CustomComponentViewState {
    modals: CustomComponentViewStateModals<TModalList>;
}

export const CustomComponentViewInitialState: CustomComponentViewState = {
    ...CustomComponentInitialState,
    treeOpen: undefined
};

type EnsureCustomComponentModal<T> = T extends CustomComponentModal ? T : never;

export class CustomComponentView<
    TModalList,
    TDataSource = {},
    P = {},
    S extends CustomComponentViewStateWithModals<TModalList> = CustomComponentViewStateWithModals<
        TModalList
    >,
    SS = any,
    TModalName extends keyof TModalList = keyof TModalList
> extends CustomComponent<TDataSource, P, S, SS> {
    protected modalHandlers?: CustomComponentViewModalHandlers<TModalList>;

    treeOpenChange(id: number) {
        this.setState(prev => ({ treeOpen: prev.treeOpen === id ? undefined : id }));
    }

    modalOpen<T extends TModalName, M extends EnsureCustomComponentModal<TModalList[T]>>(
        modalName: T,
        props: CustomComponentModalExtractProps<M>
    ) {
        document.body.classList.add('no-scroll');

        this.setState(prev => ({
            modals: {
                ...prev.modals,
                [modalName]: {
                    state: true,
                    props: props
                }
            }
        }));
    }

    private modalClose(modalName: TModalName) {
        document.body.classList.remove('no-scroll');

        this.setState(prev => ({
            modals: {
                ...prev.modals,
                [modalName]: {
                    state: false,
                    props: undefined
                }
            }
        }));
    }

    onModalClose<
        T extends TModalName,
        M extends EnsureCustomComponentModal<TModalList[T]>,
        R extends CustomComponentModalExtractReturnType<M>
    >(modalName: T, response: R) {
        if (
            this.modalHandlers &&
            this.modalHandlers[modalName] &&
            this.modalHandlers[modalName]!.onClose
        ) {
            (this.modalHandlers[modalName] as any).onClose(response);
        }

        this.modalClose(modalName);
    }

    onModalDismiss<T extends TModalName>(modalName: T) {
        if (
            this.modalHandlers &&
            this.modalHandlers[modalName] &&
            this.modalHandlers[modalName]!.onDismiss
        ) {
            (this.modalHandlers[modalName] as any).onDismiss();
        }

        this.modalClose(modalName);
    }

    modalProps<T extends TModalName>(modalName: T): any {
        return {
            state: this.state.modals[modalName].state,
            customProps: this.state.modals[modalName].props,
            onClose: (response: any) => this.onModalClose(modalName, response),
            onDismiss: () => this.onModalDismiss(modalName)
        };
    }
}
