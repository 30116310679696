import BasicFormComponent from "../../../../components/basicFormComponent/BasicFormComponent";
import React from "react";

import appTranslate from "../../../../appTranslate.json";
import axios from "axios";
import moment from "moment";

import { emptyObject } from "../../../../services/translateService";

import TextField from "../../../../components/form/TextField";
import CheckBox from "../../../../components/form/CheckBox";
import SelectField from "../../../../components/form/SelectField";

import { checkNotEmptyText, checkValidDate } from "../../../../helpers/validator/Validator";

import Modal from "../../../../components/modal/Modal";
import Loader from "../../../../components/basic/Loader";

class AddModal extends BasicFormComponent {
    TObject = appTranslate.areas.contracts.contract.addModal;

    constructor(props) {
        super(props);

        this.state = {
            form: {
                companyId: {
                    value: undefined
                },
                label: { value: "", error: "", checks: [checkNotEmptyText] },
                contractStart: { value: moment().format("YYYY-MM-DD"), error: "", checks: [checkValidDate] },
                isTimeLimited: { value: false },
                contractEnd: {
                    value: moment().format("YYYY-MM-DD"),
                    error: "",
                    checks: [
                        field => {
                            return this.state.form.isTimeLimited.value ? checkValidDate(field) : [];
                        }
                    ]
                }
            },
            companies: [],
            loader: false
        };
    }

    componentDidMount() {
        this.getCompanies();
    }

    getCompanies = () => {
        this.setLoader(true);
        this.context.authService
            .getAuthHeader()
            .then(authHeader => {
                return axios({
                    url: GEAPP_ADMIN_CONFIG.adminApiUrl + "/company/company/list", // eslint-disable-line no-undef
                    method: "GET",
                    headers: {
                        ...authHeader,
                        "Content-Type": "application/json"
                    }
                })
                    .then(response => {
                        console.log(response);
                        if (response.status === 200 && response.data.code === 200) {
                            const data = response.data.data;
                            this.setLoader(false);
                            this.setState(prevState => {
                                return {
                                    companies: data,
                                    form: {
                                        ...prevState.form,
                                        companyId: {
                                            ...prevState.form.companyId,
                                            value: data[0].companyId
                                        }
                                    }
                                };
                            });
                        }
                    })
                    .catch(error => {
                        console.log(error);
                    });
            })
            .catch(e => {
                console.log(e);
            })
            .finally(() => {
                // this.setLoader('properties', false);
            });
    };

    prepareData = () => {
        return {
            companyId: this.state.form.companyId.value,
            label: this.state.form.label.value,
            isTimeLimited: this.state.form.isTimeLimited.value,
            contractStart: this.state.form.contractStart.value,
            contractEnd: this.state.form.contractEnd.value,
            isTerminated: false
        };
    };

    onSubmit = () => {
        console.log(this.validateFields());
        if (this.validateFields()) {
            this.submit();
        }
    };

    submit = () => {
        this.setLoader(true);
        this.context.authService
            .getAuthHeader()
            .then(authHeader => {
                return axios({
                    url: GEAPP_ADMIN_CONFIG.adminApiUrl + "/contract/contract/insert",// eslint-disable-line no-undef
                    method: "POST",
                    headers: {
                        ...authHeader,
                        "Content-Type": "application/json"
                    },
                    data: {
                        contract: this.prepareData()
                    }
                })
                    .then(response => {
                        console.log(response);
                        this.props.close(true);
                    })
                    .catch(error => {
                        console.log(error);
                    });
            })
            .catch(e => {
                console.log(e);
            })
            .finally(() => {
                // this.setLoader('properties', false);
            });
    };

    render() {
        const footer = (
            <div className="space-between">
                <button className="_button-light" onClick={() => this.onSubmit()}>
                    {this.T("save")}
                </button>

                <button className="_button-light" onClick={() => this.props.close(false)}>
                    {this.T("cancel")}
                </button>
            </div>
        );

        let loader = null;
        if (this.state.loader) {
            loader = <Loader />;
        }

        const content = (
            <div>
                {loader}

                <SelectField
                    label={this.T("company")}
                    value={this.state.form.companyId.value}
                    options={this.state.companies.map(e => {
                        return {
                            value: e.companyId,
                            label: `${e.companyName} (${e.nip})`
                        };
                    })}
                    onInput={value => this.onInputValue("companyId", value)}
                />

                <TextField
                    field={this.state.form.label}
                    onInput={value => this.onInputValue("label", value)}
                    onBlur={() => this.validateField("label")}
                    label={this.T("label")}
                />

                <TextField
                    field={this.state.form.contractStart}
                    type="date"
                    onInput={value => this.onInputValue("contractStart", value)}
                    onBlur={() => this.validateField("contractStart")}
                    label={this.T("contractStart")}
                />

                <CheckBox
                    value={this.state.form.isTimeLimited.value}
                    onInput={value => this.onInputValue("isTimeLimited", value)}
                    label={this.T("timeLimited")}
                />

                {this.state.form.isTimeLimited.value ? (
                    <TextField
                        field={this.state.form.contractEnd}
                        type="date"
                        onInput={value => this.onInputValue("contractEnd", value)}
                        onBlur={() => this.validateField("contractEnd")}
                        label={this.T("contractEnd")}
                    />
                ) : null}
            </div>
        );
        return (
            <Modal
                title={this.T("title")}
                close={result => this.props.close(result)}
                footer={footer}
                content={content}
                visible={true}
            />
        );
    }
}

export default AddModal;
