import React, { Component } from 'react';
import './App.scss';

import { BrowserRouter as Router } from 'react-router-dom';

import { AppContext } from './services/context';
import { AuthService, IdentityRoleModel } from './services/authService';

import Login from './views/Login';

import Main from './views/Main';
import { TranslateService, Language } from './services/translateService';

export interface AppState {
    loader: boolean;
    session: IdentityRoleModel | undefined;
    language: Language;
}

export type AppContext = App['appContext'];

class App extends Component<{}, AppState> {
    appContext = {
        authService: new AuthService(this),
        translateService: new TranslateService(this)
    };

    state: AppState = {
        loader: true,
        session: this.appContext.authService.loadSession(),
        language: this.appContext.translateService.getLanguage()
    };

    render() {
        if (this.appContext.authService.isSessionActive()) {
            return (
                <AppContext.Provider value={this.appContext}>
                    <Router>
                        <Main />
                    </Router>
                </AppContext.Provider>
            );
        } else {
            return (
                <AppContext.Provider value={this.appContext}>
                    <Router>
                        <Login />
                    </Router>
                </AppContext.Provider>
            );
        }
    }
}

export default App;
