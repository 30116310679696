import {
    GetProductSearchParams,
    GetProductSearchResponse,
} from "../../../../../Api/types/src/api/catalogue/GetProductSearch.types";
import { DataSourceConfig } from "../../hooks/useDataSource";

export const GetProductSearch: DataSourceConfig<
    GetProductSearchParams,
    undefined,
    { data: GetProductSearchResponse }
> = {
    url: "/v2/catalogue/ProductSearch",
    method: "GET",
};
