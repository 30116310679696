import App from '../App';
import { LocalizedValue } from '../../../../Api/types/src/helpers/Localize.types';

export type Language = 'pl' | 'en';

export interface TranslateObject extends LocalizedValue<string> {}

export type ParamsType = Array<TranslateObject | string>;

export class TranslateService {
    localStorageKey = 'GEAppCurrentLanguage';

    parent: App;
    languages: Language[] = ['pl', 'en'];

    constructor(parent: App) {
        this.parent = parent;
    }

    currentLanguage() {
        return this.parent.state.language;
    }

    getEmptyObject() {
        return {
            pl: '',
            en: ''
        };
    }

    setLanguage(language: Language) {
        this.parent.setState({ language: language });
        window.localStorage.setItem(this.localStorageKey, language);
    }

    getLanguage(): Language {
        const localLanguage = window.localStorage.getItem(this.localStorageKey);

        if (!localLanguage) {
            return 'pl';
        }
        if (this.languages.includes(localLanguage as any)) {
            return localLanguage as Language;
        }

        return 'pl';
    }

    translationProxy(
        property: string | TranslateObject,
        params?: ParamsType,
        r?: boolean,
        baseObject?: any
    ) {
        let p;

        if (typeof property === 'object') {
            p = property;
        }

        if (typeof property === 'string') {
            p = property.split('.').reduce((obj, a) => obj[a], baseObject);
        }

        if (r) {
            return this.getTranslationR(p, params);
        }
        return this.getTranslation(p, params);
    }

    getTranslation(property: TranslateObject, params?: ParamsType, lang?: Language) {
        if (!lang) {
            lang = this.parent.state.language;
        }
        if (!property[lang]) {
            console.log('Translate error');
            return '';
        }
        let translation = property[lang];

        if (!translation) {
            return '';
        }

        if (params && params.length > 0) {
            params.forEach((p, index) => {
                let t = p;
                if (typeof p === 'object') {
                    t = this.getTranslation(p);
                }
                translation = translation!.replace(`$-${index}`, t as string);
            });
        }

        return translation;
    }

    getTranslationR(property: TranslateObject, params?: ParamsType) {
        const lang = this.parent.state.language === 'pl' ? 'en' : 'pl';
        return this.getTranslation(property, params, lang);
    }
}

export function emptyObject(baseValue: any) {
    return {
        pl: baseValue,
        en: baseValue,
        error: ''
    };
}
