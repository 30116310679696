import React, { Component } from "react";
import { Link } from "react-router-dom";

import appTranslate from "../../../../appTranslate.json";
import Loader from "../../../../components/basic/Loader";
import { AppContext } from "../../../../services/context";
import axios from "axios";

import ChangeFileModal from "./Import";

class Main extends Component {
    constructor(props) {
        super(props);

        this.fileId = this.props.match.params.fileId;

        this.state = {
            loader: false,
            modal: {
                changeFile: {
                    open: false,
                    params: null
                }
            }
        };
    }

    modalState = (modal, state, params) => {
        this.setState(prevState => {
            return {
                modal: {
                    ...prevState.modal,
                    [modal]: {
                        open: state,
                        params: params
                    }
                }
            };
        });
    };

    onModalClose = modal => {
        this.modalState(modal, false, null);
    };

    setLoaderState = state => {
        this.setState({ loader: state });
    };

    exportFile = () => {
        this.setLoaderState(true);
        this.context.authService
            .getAuthHeader()
            .then(authHeader => {
                return axios({
                    url: GEAPP_ADMIN_CONFIG.adminApiUrl + "/catalogue/products/product/getProductExcel",// eslint-disable-line no-undef
                    method: "GET",
                    headers: {
                        ...authHeader
                    },
                    responseType: "blob"
                })
                    .then(response => {
                        const url = window.URL.createObjectURL(new Blob([response.data]));
                        const link = document.createElement("a");
                        link.href = url;
                        link.setAttribute("download", "Produkty.xlsx"); //or any other extension
                        document.body.appendChild(link);
                        link.click();
                    })
                    .catch(error => {
                        console.log(error);
                    });
            })
            .catch(e => {
                console.log(e);
            })
            .finally(() => {
                this.setLoaderState(false);
            });
    };

    render() {
        let loader = <div />;
        if (this.state.loader) {
            loader = <Loader />;
        }

        let changeFileModal = <div />;
        if (this.state.modal.changeFile.open) {
            changeFileModal = (
                <ChangeFileModal
                    close={response => this.onModalClose("changeFile", response)}
                    params={this.state.modal.changeFile.params}
                />
            );
        }

        return (
            <div className="content-basic">
                {loader}
                {changeFileModal}
                <div className="content-basic__header">
                    <button className="_button-light" onClick={() => this.exportFile()}>
                        Export .xlsx
                    </button>

                    {/* <button
                        className='_button-light'
                        onClick={() =>
                            this.modalState('changeFile', true, {
                                id: this.fileId
                            })
                        }
                    >
                        {this.T('changeFile')}
                    </button>

                    <button
                        className='_button-light'
                        onClick={() =>
                            this.modalState('changePreview', true, {
                                id: this.fileId
                            })
                        }
                    >
                        {this.T('changePreview')}
                    </button> */}

                    <button className="_button-light" onClick={() => this.modalState("changeFile", true, undefined)}>
                        Import .xlsx
                    </button>
                </div>
            </div>
        );
    }
}
Main.contextType = AppContext;

export default Main;
