import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import './ListListChild.scss';
import { MagazineOrderListModel } from '../../../../../../../../Api/types/src/areas/magazine/Order.types';
import { CustomComponent } from '../../../../../utils/CustomComponent';

const classBase = '_magazine-order-list-child';

interface Props {
    element: MagazineOrderListModel;
    T: CustomComponent['T'];
}

const Element: React.FC<Props> = ({ element, T }) => (
    <div className={`${classBase}__wrapper`}>
        <div className={`${classBase}__space-1`} />
        <div className={`${classBase}__id`}>{element.magazineOrderId}</div>
        <div className={`${classBase}__space-2`} />
        <div className={`${classBase}__magazine-location`}>{element.magazineLocationLabel}</div>
        <div className={`${classBase}__created`}>
            {moment(element.createdAt).format('YYYY-MM-DD')}
        </div>
        <div className={`${classBase}__shipment-date`}>
            {moment(element.orderDate).format('YYYY-MM-DD')}
        </div>
        <div className={`${classBase}__status`}>{T(element.magazineOrderStatusLabel)}</div>
        <div className={`${classBase}__products`}>{element.productCount}</div>
        <div className={`${classBase}__buttons`}>
            <Link
                to={`/magazine/order/${element.magazineOrderId}/details`}
                className='button-light small icon'
            >
                search
            </Link>
        </div>
    </div>
);

export default Element;
