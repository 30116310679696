import React from "react";
import moment from "moment";

import "./List.scss";

import { AppContext } from "../../../../services/context";
import TO from "./List.json";

import Overlay, { OverlayData } from "../../../../components/overlay/Overlay";

import { GetStockListDataSource } from "../../../../endpoints/magazine/GetStockList";
import { DataSourceStorageInitial } from "../../../../utils/endpoint/DataSourceNew";
import { DataSourceFunctionGetStorage } from "../../../../utils/endpoint/DataSourceNew.function.types";
import { BasicComponent } from "../../../../utils/BasicComponent/BasicComponent";

interface OpenedProduct {
    isOpen: boolean;
    locations: {
        [key: number]: boolean;
    };
}

interface OpenedProducts {
    [key: number]: OpenedProduct;
}

interface StockListState {
    datasource: {
        MagazineGetStockList: DataSourceFunctionGetStorage<typeof GetStockListDataSource>;
    };
    opened: OpenedProducts;
}

export default class StockList extends BasicComponent<{}, StockListState> {
    state: StockListState = {
        datasource: {
            MagazineGetStockList: DataSourceStorageInitial
        },
        opened: {}
    };
    static contextType = AppContext;

    private dsStockList = GetStockListDataSource(this);

    componentDidMount() {
        this._isMounted = true;
        this.dsStockList.request({});
    }

    protected TranslateObject = TO;

    get overlayData(): OverlayData {
        if (this.dsStockList.state === "idle" || this.dsStockList.state === "pending") {
            return {
                show: true,
                title: "Loading stock..."
            };
        }
        return {
            show: false
        };
    }

    render() {
        return (
            <div className="content-basic">
                <Overlay data={this.overlayData} />
                <div className="_magazine-stock-list">
                    {!!this.dsStockList.data
                        ? this.dsStockList.data.stockList.map(item => (
                              <div className="_magazine-stock-list__product" key={item.product.productId}>
                                  <div className="_magazine-stock-list__product-content" onClick={() => this.setOpenProduct(item.product.productId)}>
                                      <div className="_magazine-stock-list__product-content-title">{this.T(item.product.productName)}</div>
                                      <div className="_magazine-stock-list__product-content-ean">{item.product.ean}</div>
                                      <div className="_magazine-stock-list__product-content-count">
                                          {item.magazineLocations.reduce(
                                              (sum, location) => sum + location.counts.reduce((locationsum, count) => locationsum + count.totalQuantity, 0),
                                              0
                                          )}
                                      </div>
                                      <div className="_magazine-stock-list__product-content-arrow">
                                          {this.isOpenProduct(item.product.productId) ? "keyboard_arrow_up" : "keyboard_arrow_down"}
                                      </div>
                                  </div>
                                  {this.isOpenProduct(item.product.productId) ? (
                                      <div className="_magazine-stock-list__product-locations">
                                          {item.magazineLocations.map(location => (
                                              <div className="_magazine-stock-list__product-location" key={location.magazineLocation.magazineLocationId}>
                                                  <div className="_magazine-stock-list__product-location-content">
                                                      <div className="_magazine-stock-list__product-location-content-title">
                                                          {location.magazineLocation.label}
                                                      </div>
                                                      <div className="_magazine-stock-list__product-location-content-count">
                                                          {location.counts.reduce((sum, count) => sum + count.totalQuantity, 0)}
                                                      </div>
                                                      {location.counts.length > 1 || (!!location.counts[0] && !!location.counts[0].expireDate) ? (
                                                          <div
                                                              className="_magazine-stock-list__product-location-content-arrow"
                                                              onClick={() =>
                                                                  this.setOpenLocation(item.product.productId, location.magazineLocation.magazineLocationId)
                                                              }
                                                          >
                                                              {this.isOpenLocation(item.product.productId, location.magazineLocation.magazineLocationId)
                                                                  ? "keyboard_arrow_up"
                                                                  : "keyboard_arrow_down"}
                                                          </div>
                                                      ) : (
                                                          <div className="_magazine-stock-list__product-location-content-arrow-space"></div>
                                                      )}
                                                  </div>
                                                  {this.isOpenLocation(item.product.productId, location.magazineLocation.magazineLocationId) &&
                                                  (location.counts.length > 1 || (!!location.counts[0] && !!location.counts[0].expireDate)) ? (
                                                      <div className="_magazine-stock-list__product-location-counts">
                                                          {location.counts.map(count => (
                                                              <div className="_magazine-stock-list__product-location-count" key={count.expireDate}>
                                                                  <div className="_magazine-stock-list__product-location-count-date">
                                                                      {moment(count.expireDate).format("YYYY-MM-DD")}
                                                                  </div>
                                                                  <div className="_magazine-stock-list__product-location-count-count">
                                                                     {count.totalQuantity}
                                                                  </div>
                                                              </div>
                                                          ))}
                                                      </div>
                                                  ) : null}
                                              </div>
                                          ))}
                                      </div>
                                  ) : null}
                              </div>
                          ))
                        : null}
                </div>
            </div>
        );
    }

    private isOpenProduct(productId: number): boolean {
        return !!this.state.opened[productId] && !!this.state.opened[productId].isOpen;
    }

    private isOpenLocation(productId: number, locationId: number): boolean {
        return !!this.state.opened[productId] && !!this.state.opened[productId].isOpen && !!this.state.opened[productId].locations[locationId];
    }

    private setOpenProduct(productId: number) {
        this.setState(p => {
            if (!p.opened[productId]) {
                return {
                    opened: {
                        ...p.opened,
                        [productId]: {
                            isOpen: true,
                            locations: {}
                        }
                    }
                };
            }
            return {
                opened: {
                    ...p.opened,
                    [productId]: {
                        ...p.opened[productId],
                        isOpen: !p.opened[productId].isOpen
                    }
                }
            };
        });
    }

    private setOpenLocation(productId: number, locationId: number) {
        this.setState(p => {
            if (!p.opened[productId]) {
                return {
                    opened: {
                        ...p.opened,
                        [productId]: {
                            isOpen: true,
                            locations: {
                                [locationId]: true
                            }
                        }
                    }
                };
            }
            return {
                opened: {
                    ...p.opened,
                    [productId]: {
                        ...p.opened[productId],
                        locations: {
                            ...p.opened[productId].locations,
                            [locationId]: !p.opened[productId].locations[locationId]
                        }
                    }
                }
            };
        });
    }
}
