import React from 'react';
import { CustomComponentState, CustomComponent } from './CustomComponent';
import { RouteComponentProps } from 'react-router';

export interface CustomComponentModalProps<TProps, TRetrunValue> {
    customProps: TProps;
    state: boolean;
    onClose: (result?: TRetrunValue) => void;
    onDismiss: () => void;
}

export const CustomComponentModalInitialState: { state: boolean; props: any } = {
    state: false,
    props: undefined
};

export type CustomComponentModalExtractPropsComponent<T> = T extends CustomComponentModal<
    infer TReturnValue,
    any,
    infer P
>
    ? CustomComponentModalProps<P, TReturnValue>
    : never;

export type CustomComponentModalExtractProps<T> = T extends CustomComponentModal<
    any,
    any,
    infer TProps
>
    ? TProps
    : never;

export type CustomComponentModalExtractReturnType<T> = T extends CustomComponentModal<
    infer TReturnType
>
    ? TReturnType
    : never;

export class CustomComponentModal<
    TRetrunValue = any,
    TDataSource = {},
    P = {},
    S = {},
    SS = any
> extends CustomComponent<
    TDataSource,
    CustomComponentModalProps<P, TRetrunValue> & RouteComponentProps,
    S & CustomComponentState,
    SS
> {}
