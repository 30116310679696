import { DataSourceNew } from "../../utils/endpoint/DataSourceNew";
import { DataSourceFunction } from "../../utils/endpoint/DataSourceNew.function.types";

import {
    GetProductSearchParams,
    GetProductSearchResponse
} from "../../../../../Api/types/src/api/catalogue/GetProductSearch.types";

export const GetStateDetailsDataSource: DataSourceFunction<
    "CatalogueGetProductSearch",
    GetProductSearchParams,
    undefined,
    GetProductSearchResponse
> = parent =>
    new DataSourceNew(
        {
            name: "CatalogueGetProductSearch",
            endpointConfig: {
                url: "/v2/catalogue/ProductSearch",
                method: "GET"
            }
        },
        parent
    );
