import React, { Component } from 'react';
import { AppContext } from '../services/context';
import { DataSource, DataSourceStatus } from './dataSource/DataSource';
import { stat } from 'fs';
import { ParamsType, TranslateObject } from '../services/translateService';

export interface CustomComponentState {
    dataSourcesStatus: DataSourcesStatus;
}

export const CustomComponentInitialState: CustomComponentState = {
    dataSourcesStatus: 'NODATASOURCE'
};

export type CustomComponentDataSourceBase = {
    [index: string]: DataSource<any>;
};

export type CustomComponentDataSource<TDataSource> = { [K in keyof TDataSource]: TDataSource[K] };

export type DataSourcesStatus = DataSourceStatus | 'NODATASOURCE';

export class CustomComponent<
    TDataSource = {},
    P = {},
    S extends CustomComponentState = CustomComponentState,
    SS = any
> extends Component<P, S, SS> {
    static contextType = AppContext;
    context!: React.ContextType<typeof AppContext>;

    protected dataSource?: CustomComponentDataSource<TDataSource>;

    protected ObjectTranslation: any = null;

    protected T(prop: string | TranslateObject | undefined, params?: ParamsType, r?: boolean) {
        if (!prop) {
            console.warn('Prop passes to CustomComponent T function was undefined');
            return '';
        }
        return this.context.translateService.translationProxy(
            prop,
            params,
            r,
            this.ObjectTranslation
        );
    }

    protected _isMounted = false;

    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    onStatusChange() {
        console.log('onStatusChange');
        this.setDataSourcesStatus();
    }

    protected setDataSourcesStatus() {
        if (!this._isMounted) {
            return;
        }
        this.setState({ dataSourcesStatus: this.getDataSourcesStatus() });
    }

    protected getDataSourcesStatus(): DataSourcesStatus {
        if (!this.dataSource) {
            return 'NODATASOURCE';
        }

        const keys = Object.keys(this.dataSource);

        if (keys.length === 0) {
            return 'NODATASOURCE';
        }

        const status = {
            initial: 0,
            pending: 0,
            ready: 0,
            error: 0
        };

        let t: keyof TDataSource;

        keys.forEach(key => {
            switch ((this.dataSource as any)[key].status as DataSourcesStatus) {
                case 'INITIAL':
                    status.initial++;
                    break;

                case 'PENDING':
                    status.pending++;
                    break;

                case 'READY':
                    status.ready++;
                    break;

                case 'ERROR':
                    status.error++;
                    break;
            }
        });

        if (status.error > 0) return 'ERROR';
        if (status.pending > 0) return 'PENDING';
        if (status.ready > 0) return 'READY';

        return 'INITIAL';
    }
}
