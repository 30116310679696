import React from 'react';

import DataSourceLoader from '../../../../../utils/DataSourceLoader';

import Translation from './List.json';

import {
    CustomComponentView,
    CustomComponentViewState,
    CustomComponentViewInitialState,
    CustomComponentViewStateWithModals
} from '../../../../../utils/CustomComponentView';
import { DataSource } from '../../../../../utils/dataSource/DataSource';

import {
    MagazineGroupOrderListFilter,
    MagazineOrderGroup
} from '../../../../../../../../Api/types/src/areas/magazine/Order.types';

import { CompanyModel } from '../../../../../../../../Api/types/src/areas/company/Company.types';
import { ContractOrderStatusModel } from '../../../../../../../../Api/types/src/areas/contract/OrderStatus.types';

import Header from './ListHeader';
import ListList from './ListList';
import AddModal from '../AddModal/AddModal';
import { CustomComponentModalInitialState } from '../../../../../utils/CustomComponentModal';

interface StateData {
    companies: CompanyModel[];
    contractOrderStatusList: ContractOrderStatusModel[];
    orderGroupList: MagazineOrderGroup[];
}

interface StateFilter {
    filterCompanyId: number;
    filterStatusId: number;
}

export interface StateView extends StateData, StateFilter {}

interface ModalList {
    add: AddModal;
}

interface State extends CustomComponentViewStateWithModals<ModalList>, StateView {}

interface DataSources {
    companies: DataSource<CompanyModel[]>;
    contractOrderStatusList: DataSource<ContractOrderStatusModel[]>;
    magazineOrderGroupList: DataSource<MagazineOrderGroup[]>;
}

export class List extends CustomComponentView<ModalList, DataSources, {}, State> {
    state: State = {
        ...CustomComponentViewInitialState,
        companies: [],
        contractOrderStatusList: [],
        orderGroupList: [],
        filterCompanyId: -1,
        filterStatusId: -1,
        modals: {
            add: { ...CustomComponentModalInitialState }
        }
    };

    ObjectTranslation = Translation;

    protected modalHandlers = {
        add: {
            onClose: () => {
                console.log('modal closed');
                this.getMagazineOrderGroupList();
            },
            onDismiss: () => {
                console.log('modal dismissed');
            }
        }
    };

    protected dataSource: DataSources = {
        companies: new DataSource<CompanyModel[]>(this, {
            url: '/company/company/list',
            method: 'GET'
        }),
        contractOrderStatusList: new DataSource<ContractOrderStatusModel[]>(this, {
            url: '/contract/order/status/list',
            method: 'GET'
        }),
        magazineOrderGroupList: new DataSource<MagazineOrderGroup[]>(this, {
            url: '/magazine/order/listGroup',
            method: 'GET'
        })
    };

    componentDidMount() {
        this._isMounted = true;
        this.getCompanies();
        this.getContractOrderStatusList();
        this.getMagazineOrderGroupList();
    }

    getCompanies() {
        this.dataSource.companies.makeRequest().then(companies => this.setState({ companies }));
    }

    getContractOrderStatusList() {
        this.dataSource.contractOrderStatusList
            .makeRequest()
            .then(statusList => this.setState({ contractOrderStatusList: statusList }));
    }

    getMagazineOrderGroupList() {
        const filters: MagazineGroupOrderListFilter = {
            companyId: this.state.filterCompanyId > 0 ? this.state.filterCompanyId : undefined,
            contractOrderStatusId:
                this.state.filterStatusId > 0 ? this.state.filterStatusId : undefined
        };
        this.dataSource.magazineOrderGroupList
            .makeRequest({
                params: { ...filters }
            })
            .then(orderGroups => this.setState({ orderGroupList: orderGroups }));
    }

    changeFilter(prop: keyof StateFilter, newFilterValue: number) {
        this.setState(
            prev => ({ ...prev, [prop]: newFilterValue }),
            () => this.getMagazineOrderGroupList()
        );
    }

    render() {
        return (
            <div className='content-basic'>
                {this.state.modals.add.state ? <AddModal {...this.modalProps('add')} /> : null}
                <DataSourceLoader
                    status={this.state.dataSourcesStatus}
                    language={this.context.translateService.currentLanguage()}
                />
                <Header
                    state={this.state}
                    T={prop => this.T(prop)}
                    changeFilter={(prop, newFilterValue) => this.changeFilter(prop, newFilterValue)}
                />
                <ListList
                    elements={this.state.orderGroupList}
                    treeOpen={this.state.treeOpen}
                    treeOpenChange={id => this.treeOpenChange(id)}
                    T={prop => this.T(prop)}
                    addShipment={id => this.modalOpen('add', { contractOrderId: id })}
                />
            </div>
        );
    }
}

export default List;
