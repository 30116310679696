import BasicFormComponent from "../../../../components/basicFormComponent/BasicFormComponent";
import React from "react";
import { Link } from "react-router-dom";

import appTranslate from "../../../../appTranslate.json";
import axios from "axios";

import Modal from "../../../../components/modal/Modal";
import Loader from "../../../../components/basic/Loader";

class SearchModal extends BasicFormComponent {
    TObject = appTranslate.areas.catalogue.productNew.searchModal;

    constructor(props) {
        super(props);

        this.state = {
            loader: 0,
            searched: false,
            results: [],
            searchValue: "",
        };
    }

    setLoader = (value) => {
        this.setState((prevState) => {
            let l = prevState.loader + (value ? 1 : -1);
            if (l < 0) {
                l = 0;
            }
            return {
                loader: l,
            };
        });
    };

    fetchResults = () => {
        if (this.state.searchValue.length < 3) {
            return;
        }
        this.setLoader(true);
        this.context.authService
            .getAuthHeader()
            .then((authHeader) => {
                return axios({
                    url:
                        GEAPP_ADMIN_CONFIG.adminApiUrl + "/catalogue/products/product/search/" + this.state.searchValue, // eslint-disable-line no-undef
                    method: "GET",
                    headers: {
                        ...authHeader,
                        "Content-Type": "application/json",
                    },
                })
                    .then((response) => {
                        console.log(response);
                        if (response.status === 200 && response.data.code === 200) {
                            this.setLoader(false);
                            this.setState({
                                results: response.data.data,
                                searched: true,
                            });
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            })
            .catch((e) => {
                console.log(e);
            })
            .finally(() => {
                // this.setLoader('properties', false);
            });
    };

    openTree = (level, id) => {
        this.setState((prev) => {
            return {
                treeOpen: {
                    ...prev.treeOpen,
                    [level]: prev.treeOpen[level] === id ? null : id,
                },
            };
        });
    };

    changeSearchValue = (value) => {
        this.setState({
            searchValue: value,
        });
    };

    render() {
        const footer = (
            <div className="space-between">
                <button className="_button-light" onClick={() => this.props.close(false)}>
                    {this.T("cancel")}
                </button>
            </div>
        );

        let loader = null;
        if (this.state.loader > 0) {
            loader = <Loader />;
        }

        const content = (
            <div className="binding-modal__container">
                {loader}
                <div className="_search-input">
                    <input
                        type="text"
                        value={this.state.searchValue}
                        onChange={(event) => this.changeSearchValue(event.target.value)}
                        className="_search-input__input"
                    />
                    <button className="_button-light _search-input-button" onClick={() => this.fetchResults()}>
                        {this.T("searchButton")}
                    </button>
                </div>
                {this.state.results.length === 0 ? (
                    this.state.searched ? (
                        <div className="_search-cta">{this.T("noBinding")}</div>
                    ) : (
                        <div className="_search-cta">{this.T("searchCta")}</div>
                    )
                ) : (
                    this.state.results.map((p) => {
                        return (
                            <div className="_search-result__wrapper" key={p.productId}>
                                <div className="_search-result__info">
                                    <div className="_search-result__name">{this.T(p.productName)}</div>
                                    <div className="_search-result__ean">{p.ean}</div>
                                    <div className="_search-result__type">{this.T(p.productTypeLabel)}</div>
                                    <div className="_search-result__category">
                                        {this.T(p.mainCategoryLabel)} {">"} {this.T(p.categoryLabel)}
                                    </div>
                                </div>

                                <div className="_search-result__button">
                                    <Link
                                        to={`/catalogue/product/${p.productId}/details`}
                                        className="_button-light small"
                                    >
                                        {this.T("show")}
                                    </Link>
                                </div>
                            </div>
                        );
                    })
                )}
            </div>
        );
        return (
            <Modal
                title={this.T("title")}
                close={(result) => this.props.close(result)}
                footer={footer}
                content={content}
                visible={true}
            />
        );
    }
}

export default SearchModal;
