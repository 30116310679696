import { DataSourceConfig } from "../../hooks/useDataSource";

interface ListItem {
    categoryId: number;
    ordered: number;
}

interface Data {
    list: ListItem[];
}

export const PostCategoryReorder: DataSourceConfig<undefined, Data, undefined> = {
    url: "/catalogue/category/reorder",
    method: "POST",
};
