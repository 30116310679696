import React from 'react';
import { StateView, List } from './List';

const Element: React.FC<{
    state: StateView;
    changeFilter: List['changeFilter'];
    T: List['T'];
}> = ({ state, changeFilter, T }) => {
    return (
        <div className='content-basic__header'>
            <div className='select-field__wrapper right-space'>
                <select
                    className='field select-field'
                    value={state.filterCompanyId}
                    onChange={event =>
                        changeFilter('filterCompanyId', parseInt(event.target.value))
                    }
                >
                    <option value={-1}>{T('allCompanies')}</option>
                    {state.companies.map(l => (
                        <option value={l.companyId} key={l.companyId}>
                            {l.companyName}
                        </option>
                    ))}
                </select>
            </div>

            <div className='select-field__wrapper right-space'>
                <select
                    className='field select-field'
                    value={state.filterStatusId}
                    onChange={event => changeFilter('filterStatusId', parseInt(event.target.value))}
                >
                    <option value={-1}>{T('allStatus')}</option>
                    {state.contractOrderStatusList.map(l => (
                        <option value={l.contractOrderStatusId} key={l.contractOrderStatusId}>
                            {T(l.label)}
                        </option>
                    ))}
                </select>
            </div>
        </div>
    );
};
export default Element;
