import React from "react";
import FormComponent, { FormComponentConfig } from "../../../../../../utils/form/FormComponent";
import TextField, { TextFieldInitialState } from "../../../../../../utils/form/fields/TextField";
import DatePicker, {
    DatePickerInitialState,
    DatePickerValidateDate
} from "../../../../../../utils/form/fields/DatePicker";
import SelectField, { SelectFieldInitialState } from "../../../../../../utils/form/fields/SelectField";
import { MagazineLocation } from "../../../../../../../../../Api/types/src/areas/magazine/Location.types";

import { SelectDateModalProps } from "./SelectDateModal";

import TO from "./SelectDateModal.json";

interface FieldList {
    expireDate: DatePicker;
    count: TextField;
}

interface Props {
    initial: SelectDateModalProps;
    onEnter?: () => void;
}

export default class SelectDateModalForm extends FormComponent<FieldList, Props> {
    state = {
        form: {
            expireDate: DatePickerInitialState(this.props.initial.expireDate),
            count: TextFieldInitialState(this.props.initial.count)
        }
    };

    componentDidMount() {
        const field = document.getElementById(
            !!this.props.initial.isExpiration ? "select-date-modal-date" : "select-date-modal-count"
        ) as HTMLInputElement | null;

        if (!!field) {
            field.focus();
        }
    }

    formFieldsConfig: FormComponentConfig<FieldList> = {
        expireDate: {
            label: () => this.T(TO.date),
            validate: value => DatePickerValidateDate(value)
        },
        count: {
            label: () => this.T(TO.count),
            validate: value => (Number.isInteger(parseInt(value)) ? [] : ["Not integer"])
        }
    };

    render() {
        return (
            <div className="new-form-area">
                {this.props.initial.isExpiration ? (
                    <DatePicker
                        id="select-date-modal-date"
                        {...this.fieldProps("expireDate")}
                        onEnter={() => this.focusCount()}
                    />
                ) : null}
                <TextField id="select-date-modal-count" {...this.fieldProps("count")} onEnter={() => this.onEnter()} />
            </div>
        );
    }

    private onEnter() {
        if (!!this.props.onEnter) {
            this.props.onEnter();
        }
    }

    private focusCount() {
        const field = document.getElementById("select-date-modal-count") as HTMLInputElement | null;
        if (!!field) {
            field.focus();
        }
    }
}
