import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import appTranslate from '../../../../appTranslate.json';
import Loader from '../../../../components/basic/Loader';
import { AppContext } from '../../../../services/context';
import axios from 'axios';

import EditModal from './ChildEditModal';
import ChangeFileModal from './ChildEditFileModal';
import ChangePreviewModal from './ChildEditPreviewModal';
import FileBindingModal from './ChildFileBindingModal';

class Details extends Component {
    constructor(props) {
        super(props);

        this.fileId = this.props.match.params.fileId;

        this.state = {
            loader: false,
            file: null,
            productTree: [],
            tree: {
                main: null,
                secondary: null
            },
            loaders: {
                productTree: false
            },
            modal: {
                edit: {
                    open: false,
                    params: null
                },
                changeFile: {
                    open: false,
                    params: null
                },
                changePreview: {
                    open: false,
                    params: null
                },
                binding: {
                    open: false,
                    params: null
                }
            }
        };
    }

    T = (prop, params, r) => {
        return this.context.translateService.translationProxy(
            prop,
            params,
            r,
            appTranslate.areas.catalogue.files.details
        );
    };

    modalState = (modal, state, params) => {
        this.setState(prevState => {
            return {
                modal: {
                    ...prevState.modal,
                    [modal]: {
                        open: state,
                        params: params
                    }
                }
            };
        });
    };

    onModalClose = (modal, response) => {
        this.modalState(modal, false, null);
        if (response) {
            this.getData();
            if (this.state.productTree.length !== 0) {
                this.getBindedProducts();
            }
        }
    };

    componentDidMount() {
        this.getData();
    }

    setLoaderState = state => {
        this.setState({ loader: state });
    };

    setLoader = (loader, state) => {
        this.setState(prev => {
            return {
                loaders: {
                    ...prev.loaders,
                    [loader]: state
                }
            };
        });
    };

    modalState = (modal, state, params) => {
        this.setState(prevState => {
            return {
                modal: {
                    ...prevState.modal,
                    [modal]: {
                        open: state,
                        params: params
                    }
                }
            };
        });
    };

    openTree = (level, id) => {
        this.setState(prev => {
            return {
                tree: {
                    ...prev.tree,
                    [level]: prev.tree[level] === id ? null : id
                }
            };
        });
    };

    getData = () => {
        console.log(this.props.match.params.fileId);
        this.setLoaderState(true);
        this.context.authService
            .getAuthHeader()
            .then(authHeader => {
                return axios({
                    url: GEAPP_ADMIN_CONFIG.adminApiUrl + '/catalogue/files/file/details/' + this.props.match.params.fileId,// eslint-disable-line no-undef
                    method: 'GET',
                    headers: {
                        ...authHeader,
                        'Content-Type': 'application/json'
                    }
                })
                    .then(response => {
                        console.log(response);
                        if (response.data.code === 200) {
                            this.setState({ file: response.data.data });
                        }
                    })
                    .catch(error => {
                        console.log(error);
                    });
            })
            .catch(e => {
                console.log(e);
            })
            .finally(() => {
                this.setLoaderState(false);
            });
    };

    getBindedProducts = () => {
        this.setLoaderState(true);
        this.context.authService
            .getAuthHeader()
            .then(authHeader => {
                return axios({
                    url:
                        GEAPP_ADMIN_CONFIG.adminApiUrl +// eslint-disable-line no-undef
                        '/catalogue/files/fileProduct/fileTree/' +
                        this.props.match.params.fileId,
                    method: 'GET',
                    headers: {
                        ...authHeader,
                        'Content-Type': 'application/json'
                    }
                })
                    .then(response => {
                        console.log(response);
                        if (response.data.code === 200) {
                            this.setState({ productTree: response.data.data });
                        }
                    })
                    .catch(error => {
                        console.log(error);
                    });
            })
            .catch(e => {
                console.log(e);
            })
            .finally(() => {
                this.setLoaderState(false);
            });
    };

    render() {
        let loader = <div />;
        if (this.state.loader) {
            loader = <Loader />;
        }

        let editModal = <div />;
        if (this.state.modal.edit.open) {
            editModal = (
                <EditModal
                    close={response => this.onModalClose('edit', response)}
                    params={this.state.modal.edit.params}
                />
            );
        }

        let changeFileModal = <div />;
        if (this.state.modal.changeFile.open) {
            changeFileModal = (
                <ChangeFileModal
                    close={response => this.onModalClose('changeFile', response)}
                    params={this.state.modal.changeFile.params}
                />
            );
        }

        let changePreviewModal = <div />;
        if (this.state.modal.changePreview.open) {
            changePreviewModal = (
                <ChangePreviewModal
                    close={response => this.onModalClose('changePreview', response)}
                    params={this.state.modal.changePreview.params}
                />
            );
        }

        let fileBindingModal = <div />;
        if (this.state.modal.binding.open) {
            fileBindingModal = (
                <FileBindingModal
                    close={response => this.onModalClose('binding', response)}
                    params={this.state.modal.binding.params}
                />
            );
        }

        if (!this.state.file) {
            return <div className='content-basic'>{loader}</div>;
        }

        const bindedProductsTree = this.state.productTree.reduce((t, c) => {
            if (c.productCount === 0) {
                return t;
            }
            const categories =
                this.state.tree.main === c.categoryId
                    ? c.categories.reduce((tt, cc) => {
                          if (cc.productCount === 0) {
                              return tt;
                          }
                          const products = cc.products.map(p => {
                              return (
                                  <div className='details-tree__element' key={p.productId}>
                                      <div className='details-tree__element-label'>{this.T(p.productName)}</div>
                                      <div className='details-tree__element-ean'>{p.ean}</div>
                                      <div className='details-tree__element-buttons'>
                                          <Link
                                              to={`/catalogue/product/${p.productId}/details`}
                                              className='_button-light small'
                                          >
                                              {this.T('showProduct')}
                                          </Link>
                                      </div>
                                  </div>
                              );
                          });
                          tt.push(
                              <div className='details-tree__child' key={cc.categoryId}>
                                  <div
                                      className='details-tree__child-content'
                                      onClick={() => this.openTree('secondary', cc.categoryId)}
                                  >
                                      <div className='details-tree__child-expand-icon'>
                                          {this.state.tree.secondary === cc.categoryId ? 'expand_less' : 'expand_more'}
                                      </div>
                                      <div className='details-tree__child-label'>
                                          {this.T(cc.label)} <span className='small-label'>{cc.productCount}</span>
                                      </div>
                                  </div>
                                  {this.state.tree.secondary === cc.categoryId ? products : null}
                              </div>
                          );
                          return tt;
                      }, [])
                    : null;
            t.push(
                <div className='details-tree__main' key={c.categoryId}>
                    <div className='details-tree__main-content' onClick={() => this.openTree('main', c.categoryId)}>
                        <div className='details-tree__main-expand-icon'>
                            {this.state.tree.main === c.categoryId ? 'expand_less' : 'expand_more'}
                        </div>
                        <div className='details-tree__main-label'>
                            {this.T(c.label)} <span className='small-label'>{c.productCount}</span>
                        </div>
                    </div>
                    {categories}
                </div>
            );
            return t;
        }, []);

        return (
            <div className='content-basic'>
                {loader}
                {editModal}
                {changeFileModal}
                {changePreviewModal}
                {fileBindingModal}
                <div className='content-basic__header'>
                    <button
                        className='_button-light'
                        onClick={() =>
                            this.modalState('edit', true, {
                                id: this.fileId
                            })
                        }
                    >
                        {this.T('editFile')}
                    </button>

                    {/* <button
                        className='_button-light'
                        onClick={() =>
                            this.modalState('changeFile', true, {
                                id: this.fileId
                            })
                        }
                    >
                        {this.T('changeFile')}
                    </button>

                    <button
                        className='_button-light'
                        onClick={() =>
                            this.modalState('changePreview', true, {
                                id: this.fileId
                            })
                        }
                    >
                        {this.T('changePreview')}
                    </button> */}

                    <button
                        className='_button-light'
                        onClick={() =>
                            this.modalState('binding', true, {
                                id: this.fileId
                            })
                        }
                    >
                        {this.T('changeBinding')}
                    </button>
                </div>
                <div className='content-basic__header'>
                    <div className='product-details'>
                        <div className='product-details__row'>
                            <div className='product-details__name'>
                                <div className='product-details__name-main'>{this.T(this.state.file.label)}</div>
                                <div className='product-details__name-second'>{this.T('fileName')}</div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='content-basic__header'>
                    <div className='product-details'>
                        <div className='product-details__row'>
                            <div className='product-details__property-group'>{this.T('file')}</div>
                        </div>
                        <div className='product-details__row'>
                            {/* <a href={GEAPP_ADMIN_CONFIG.fileUrl + this.state.file.fileName}>{this.T('fileDonwload')}</a> */}

                            {this.context.translateService.languages.map(l => {
                                return (
                                    <div className='file-version-lang__wrapper' key={l}>
                                        <div className='file-version-lang__lang'>{l}</div>
                                        <div className='file-version-lang__file'>
                                            {this.state.file.fileName[l] ? (
                                                <a
                                                    href={GEAPP_ADMIN_CONFIG.fileUrl + this.state.file.fileName[l]}// eslint-disable-line no-undef
                                                    className='_button-light small'
                                                >
                                                    {this.T('fileDownload')}
                                                </a>
                                            ) : (
                                                <div className=''>{this.T('noFile')}</div>
                                            )}
                                        </div>
                                        <div className='file-version-lang__preview'>
                                            {this.state.file.filePreviewImageFileName[l] ? (
                                                <img
                                                    src={
                                                        GEAPP_ADMIN_CONFIG.photoUrl + this.state.file.filePreviewImageFileName[l]// eslint-disable-line no-undef
                                                    }
                                                />
                                            ) : (
                                                <div className='file-version-lang__preview-label'>{this.T('noFilePreview')}</div>
                                            )}
                                        </div>
                                        <div className='file-version-lang__buttons'>
                                            <button
                                                className='_button-light'
                                                onClick={() =>
                                                    this.modalState('changeFile', true, {
                                                        id: this.fileId,
                                                        languageCode: l
                                                    })
                                                }
                                            >
                                                {this.T('changeFile')}
                                            </button>

                                            <button
                                                className='_button-light'
                                                onClick={() =>
                                                    this.modalState('changePreview', true, {
                                                        id: this.fileId,
                                                        languageCode: l
                                                    })
                                                }
                                            >
                                                {this.T('changePreview')}
                                            </button>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>

                <div className='content-basic__header'>
                    <div className='product-details'>
                        <div className='product-details__row'>
                            <div className='product-details__property-group'>{this.T('filePreview')}</div>
                        </div>

                        <div className='product-details__row'>
                            {this.state.file.filePreviewImageFileName &&
                            this.state.file.filePreviewImageFileName.length > 0 ? (
                                <img
                                    className='file__preview-image'
                                    src={GEAPP_ADMIN_CONFIG.photoUrl + this.state.file.filePreviewImageFileName}// eslint-disable-line no-undef
                                    alt=''
                                />
                            ) : null}
                        </div>
                    </div>
                </div>

                <div className='content-basic__content'>
                    <div className='product-details'>
                        <div className='product-details__row'>
                            <div className='product-details__property-group'>{this.T('bindedProducts')}</div>
                        </div>

                        <div className='product-details__row'>
                            {this.state.productTree.length === 0 ? (
                                <button className='_button-light' onClick={() => this.getBindedProducts()}>
                                    {this.T('loadBindedProducts')}
                                </button>
                            ) : (
                                <div className='details-tree__container'> {bindedProductsTree}</div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
Details.contextType = AppContext;

export default Details;
