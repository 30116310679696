import { DataSourceNew } from "../../utils/endpoint/DataSourceNew";
import { DataSourceFunction } from "../../utils/endpoint/DataSourceNew.function.types";

import { GetStockListResponse, GetStockListParams } from "../../../../../Api/types/src/api/magazine/GetStockList.types";

export const GetStockListDataSource: DataSourceFunction<
    "MagazineGetStockList",
    GetStockListParams,
    undefined,
    GetStockListResponse
> = parent =>
    new DataSourceNew(
        {
            name: "MagazineGetStockList",
            endpointConfig: {
                url: "/v2/magazine/StockList",
                method: "GET"
            }
        },
        parent
    );
