import axios, { AxiosResponse } from "axios";
import {
    EndpointConfig,
    EndpointRequestArgs,
    EndpointConfigGetResponse,
    EndpointResponseError,
    EndpointResponse
} from "./Endpoint.types";

export class Endpoint<TConfig extends EndpointConfig<any, any, any>> {
    private config: TConfig;

    constructor(config: TConfig) {
        this.config = config;
    }

    public async request(
        args: EndpointRequestArgs<TConfig>,
        headers?: object
    ): Promise<EndpointConfigGetResponse<TConfig>> {
        const requestArgs: { params?: any; data?: any } = args;
        try {
            const response: AxiosResponse<EndpointResponse<EndpointConfigGetResponse<TConfig>>> = await axios({
                url: GEAPP_ADMIN_CONFIG.adminApiUrl + this.config.url,
                method: this.config.method,
                params: requestArgs.params,
                data: requestArgs.data,
                headers: { ...headers, "Content-Type": "application/json" }
            });
            if (response.data.code === 200 || response.data.code === 201 || response.data.code === 204) {
                return response.data.data;
            } else {
                throw response.data;
            }
        } catch (e) {
            const error: EndpointResponseError = {
                code: 500,
                error: {
                    message: "unknown error",
                    systemMessage: e
                }
            };
            throw error;
        }
    }
}
