import BasicFormComponent from '../../../../components/basicFormComponent/BasicFormComponent';
import React from 'react';

import appTranslate from '../../../../appTranslate.json';
import axios, { post } from 'axios';

import { emptyObject } from '../../../../services/translateService';

import TextFieldL from '../../../../components/form/TextFieldL';
import CheckBox from '../../../../components/form/CheckBox';
import SelectField from '../../../../components/form/SelectField';

import { checkNotEmptyTextL } from '../../../../helpers/validator/Validator';

import Modal from '../../../../components/modal/Modal';
import Loader from '../../../../components/basic/Loader';

class ChildAddModal extends BasicFormComponent {
    TObject = appTranslate.areas.catalogue.files.addChildModal;

    constructor(props) {
        super(props);

        this.state = {
            form: {
                label: { ...emptyObject(''), checks: [checkNotEmptyTextL] },
                isVisible: {
                    value: true
                }
            },
            loader: false
        };
    }

    prepareData = () => {
        return {
            fileGroupId: this.props.params.id,
            isVisible: this.state.form.isVisible.value,
            label: this.getFieldL(this.state.form.label)
        };
    };

    onSubmit = () => {
        console.log(this.prepareData());
        if (this.validateFields()) {
            this.submit();
        }
    };

    submit = () => {
        this.setLoader(true);
        this.context.authService
            .getAuthHeader()
            .then(authHeader => {
                return axios({
                    url: GEAPP_ADMIN_CONFIG.adminApiUrl + '/catalogue/files/file/insert',// eslint-disable-line no-undef
                    method: 'POST',
                    headers: {
                        ...authHeader,
                        'Content-Type': 'application/json'
                    },
                    data: {
                        ...this.prepareData()
                    }
                })
                    .then(response => {
                        console.log(response);
                        this.props.close(true);
                    })
                    .catch(error => {
                        console.log(error);
                    });
            })
            .catch(e => {
                console.log(e);
            })
            .finally(() => {
                // this.setLoader('properties', false);
            });
    };

    render() {
        const footer = (
            <div className='space-between'>
                <button className='_button-light' onClick={() => this.onSubmit()}>
                    {this.T('save')}
                </button>

                <button className='_button-light' onClick={() => this.props.close(false)}>
                    {this.T('cancel')}
                </button>
            </div>
        );

        let loader = null;
        if (this.state.loader) {
            loader = <Loader />;
        }

        const content = (
            <div>
                {loader}

                <TextFieldL
                    field={this.state.form.label}
                    onInput={(value, l) => this.onInputValueL('label', l, value)}
                    onBlur={() => this.validateField('label')}
                    label={this.T('categoryName')}
                />
                <CheckBox
                    value={this.state.form.isVisible.value}
                    onInput={value => this.onInputValue('isVisible', value)}
                    label={this.T('isVisible')}
                />
            </div>
        );
        return (
            <Modal
                title={this.T('title')}
                close={result => this.props.close(result)}
                footer={footer}
                content={content}
                visible={true}
            />
        );
    }
}

export default ChildAddModal;
