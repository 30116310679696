import React from 'react';
import { Link } from 'react-router-dom';
import { ContractOrderListModel } from '../../../../../../../../Api/types/src/areas/contract/Order.types';
import './ListListParent.scss';
import Translation from './ListListParent.json';
import moment from 'moment';
import List from './List';

interface Props {
    element: ContractOrderListModel;
    shippedProductCount: number;
    treeOpen: number | undefined;
    treeOpenChange: List['treeOpenChange'];
    T: List['T'];
    addShipment: (id: number) => void;
}

const classBase = '_magazine-order-list-parent';

const Element: React.FC<Props> = ({
    element,
    treeOpen,
    treeOpenChange,
    T,
    shippedProductCount,
    addShipment
}) => (
    <div className={`${classBase}__wrapper`}>
        <div
            className={`${classBase}__open-icon`}
            onClick={() => treeOpenChange(element.contractOrderId)}
        >
            {element.contractOrderId === treeOpen ? 'expand_less' : 'expand_more'}
        </div>
        <div className={`${classBase}__id`}>{element.contractOrderId}</div>
        <div className={`${classBase}__contractor`}>
            {element.company.companyName}
            <br />
            <span className='small'>({element.company.nip})</span>
        </div>
        <div className={`${classBase}__destination`}>
            {element.destination.label}
            <br />
            <span className='small'>
                {element.destination.street} {element.destination.houseNumber}
                <br /> {element.destination.postalCode} {element.destination.city}
            </span>
        </div>
        <div className={`${classBase}__created`}>
            {moment(element.createdAt).format('YYYY-MM-DD')}
        </div>
        <div className={`${classBase}__shipment-date`}>
            {moment(element.shipmentDate).format('YYYY-MM-DD')}
        </div>

        <div className={`${classBase}__status`}>{T(element.contractOrderStatus.label)}</div>
        <div className={`${classBase}__products`}>
            {shippedProductCount} / {element.productCount}
        </div>

        <div className={`${classBase}__buttons`}>
            <button
                className='button-light small icon'
                onClick={() => addShipment(element.contractOrderId)}
            >
                add
            </button>
            <Link
                to={`/contracts/order/${element.contractOrderId}/details`}
                className='button-light small icon'
            >
                search
            </Link>
        </div>
    </div>
);

export default Element;
