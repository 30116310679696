import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import appTranslate from '../../../../appTranslate.json';
import Loader from '../../../../components/basic/Loader';
import { AppContext } from '../../../../services/context';
import axios from 'axios';

import GroupAddModal from './ContractAddModal';
import moment from 'moment';

// import ChildAddModal from './ChildAddModal';
// import ChildEditModal from './ChildEditModal';
// import ChildDeleteModal from './ChildDeleteModal';
// import EditModal from './EditModal';
// import DeleteModal from './DeleteModal';

class List extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loader: false,
            tree: [],
            modal: {
                addGroup: {
                    open: false,
                    params: null
                }
            },
            treeOpen: null
        };
    }

    componentDidMount() {
        this.getTree();
    }

    T = (prop, params, r) => {
        return this.context.translateService.translationProxy(
            prop,
            params,
            r,
            appTranslate.areas.contracts.contract.list
        );
    };

    setLoaderState = state => {
        this.setState({ loader: state });
    };

    getTree = () => {
        this.setLoaderState(true);
        this.context.authService
            .getAuthHeader()
            .then(authHeader => {
                return axios({
                    url: GEAPP_ADMIN_CONFIG.adminApiUrl + '/contract/contract/list',// eslint-disable-line no-undef
                    method: 'GET',
                    headers: {
                        ...authHeader,
                        'Content-Type': 'application/json'
                    }
                })
                    .then(response => {
                        console.log(response);
                        if (response.data.code === 200) {
                            this.setState({ tree: response.data.data });
                        }
                    })
                    .catch(error => {
                        console.log(error);
                    });
            })
            .catch(e => {
                console.log(e);
            })
            .finally(() => {
                this.setLoaderState(false);
            });
    };

    modalState = (modal, state, params) => {
        this.setState(prevState => {
            return {
                modal: {
                    ...prevState.modal,
                    [modal]: {
                        open: state,
                        params: params
                    }
                }
            };
        });
    };

    onModalClose = (modal, response) => {
        this.modalState(modal, false, null);
        if (response) {
            this.getTree();
        }
    };

    render() {
        let loader = <div />;
        if (this.state.loader) {
            loader = <Loader />;
        }

        let addGroupModal = <div />;
        if (this.state.modal.addGroup.open) {
            addGroupModal = (
                <GroupAddModal
                    close={response => this.onModalClose('addGroup', response)}
                    params={this.state.modal.addGroup.params}
                />
            );
        }

        return (
            <div className='content-basic'>
                {loader}
                {addGroupModal}
                <div className='content-basic__header'>
                    <button className='_button-light' onClick={() => this.modalState('addGroup', true, null)}>
                        {this.T('addButton')}
                    </button>
                </div>
                <div className='content-basic__content'>
                    <div className='category-tree'>
                        {this.state.tree.reduce((p, e) => {
                            p.push(
                                <div className='category-tree__wrapper' key={e.contractId}>
                                    <div className='category-tree__main-category'>
                                        <div className='category-tree__main-category__label'>
                                            {e.label} ({moment(e.contractStart).format('YYYY-MM-DD')}
                                            {e.isTimeLimited ? ' - ' + moment(e.contractEnd).format('YYYY-MM-DD') : ''})
                                        </div>

                                        <div className='category-tree__main-category__count'>
                                            {e.company.companyName} ({e.company.nip})
                                        </div>

                                        <div className='category-tree__main-category__buttons'>
                                            <Link
                                                to={`/contracts/contract/${e.contractId}/details`}
                                                className='_button-light small'
                                            >
                                                {this.T('table.details')}
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            );
                            return p;
                        }, [])}
                    </div>
                </div>
            </div>
        );
    }
}
List.contextType = AppContext;

export default List;
