import { LocalizedValue } from "../../../../../Api/types/src/helpers/Localize.types";
import { DataSourceConfig } from "../../hooks/useDataSource";

interface Product {
    productId: number;
    ean: string;
    mainCategory: {
        categoryId: number;
        label: LocalizedValue<string>;
    };
    category: {
        categoryId: number;
        label: LocalizedValue<string>;
    };
    productType: {
        productTypeId: number;
        label: LocalizedValue<string>;
    };
    productName: LocalizedValue<string>;
    isExpiration: boolean;
}

interface Props {
    productTypeId: number;
}

interface Response {
    products: Product[];
}

export const GetStartScreenList: DataSourceConfig<Props, undefined, Response> = {
    url: "/catalogue/startScreen/list",
    method: "GET",
};
