import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import Overlay, { OverlayData } from "../../../../components/overlay/Overlay";
import { GetStartScreenList } from "../../../../endpoints/catalogue/GetStartScreenList";
import { PostStartScreenChange } from "../../../../endpoints/catalogue/PostStartScreenChange";
import { useDataSource } from "../../../../hooks/useDataSource";
import { useTranslate } from "../../../../hooks/useTranslate";
import StartScreenAdd from "./Add";

const StartScreenList: React.FC = () => {
    const T = useTranslate();
    const [productTypeId, setProductTypeId] = useState<1 | 2>(1);
    const dsList = useDataSource(GetStartScreenList);
    const dsChange = useDataSource(PostStartScreenChange);
    const [addModal, setAddModal] = useState(false);

    useEffect(() => {
        dsLoad();
    }, [productTypeId]);

    useEffect(() => {
        if (dsChange.state.status === "completed") {
            dsLoad();
        }
    }, [dsChange.state.status]);

    const list = dsList.state.status === "completed" ? dsList.state.response.products : [];

    const overlay = overlayData();

    return (
        <div className="content-basic">
            <div className="content-basic__header">
                <button className="_button-light" onClick={() => setAddModal(true)}>
                    Dodaj
                </button>
            </div>
            <div className="content-basic__content products-list-content">
                <div className="products-list">
                    <div className="products-list__category-tree">
                        <div
                            className={`products-list__category-tree__child-category ${
                                productTypeId === 1 ? "isSelected" : ""
                            }`}
                            onClick={() => setProductTypeId(1)}
                        >
                            <div className="products-list__category-tree__label">Urządzenia</div>
                            <div className="products-list__category-tree__icon">
                                {productTypeId === 1 ? "check" : "keyboard_arrow_right"}
                            </div>
                        </div>
                        <div
                            className={`products-list__category-tree__child-category ${
                                productTypeId === 2 ? "isSelected" : ""
                            }`}
                            onClick={() => setProductTypeId(2)}
                        >
                            <div className="products-list__category-tree__label">Akcesoria</div>
                            <div className="products-list__category-tree__icon">
                                {productTypeId === 2 ? "check" : "keyboard_arrow_right"}
                            </div>
                        </div>
                    </div>
                    <div className="products-list__product-list" style={{ position: "relative" }}>
                        {list.map((p) => (
                            <div className="products-list__product" key={p.productId}>
                                <div className="products-list__product-id">{p.productId}</div>
                                <div className="products-list__product-name">{T(p.productName)}</div>
                                <div className="products-list__product-type">{T(p.productType.label)}</div>
                                <div className="products-list__product-number">{p.ean}</div>

                                <div className="products-list__product-buttons">
                                    <button
                                        className="_button-light small"
                                        onClick={() => changeProduct(p.productId, false)}
                                    >
                                        Usuń
                                    </button>
                                </div>
                            </div>
                        ))}
                        {list.length === 0 ? <div className="products-list__info">Brak elementów</div> : null}
                        <Overlay data={overlay} />
                    </div>
                </div>
            </div>
            {addModal ? (
                <StartScreenAdd
                    close={() => {
                        setAddModal(false);
                        dsLoad();
                    }}
                />
            ) : null}
        </div>
    );

    function overlayData(): OverlayData {
        if (dsList.state.status === "pending") {
            return {
                show: true,
                loader: true,
                title: "Ładowanie...",
            };
        }

        if (dsList.state.status === "error") {
            return {
                show: true,
                title: "Błąd :(",
                text: "Wystąpił błąd podczas ładowania spróbuj ponownie",
                child: (
                    <div>
                        <button className="_button-light small" onClick={() => dsLoad()}>
                            Załaduj ponownie
                        </button>
                    </div>
                ),
            };
        }

        if (dsChange.state.status === "pending") {
            return {
                show: true,
                loader: true,
                title: "Przetwarzanie...",
            };
        }

        if (dsChange.state.status === "error") {
            return {
                show: true,
                title: "Błąd :(",
                text: "Wystąpił błąd podczas przetwarzanie żądania",
                child: (
                    <div>
                        <button className="_button-light small" onClick={() => dsChange.reset()}>
                            Ok
                        </button>
                    </div>
                ),
            };
        }

        return {
            show: false,
        };
    }

    function dsLoad() {
        dsList.load({ params: { productTypeId: productTypeId } });
    }

    function changeProduct(productId: number, status: boolean) {
        dsChange.load({ data: { productId: productId, status: status } });
    }
};

export default StartScreenList;
