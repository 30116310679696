import { DataSourceConfig } from "../../hooks/useDataSource";

interface Data {
    productId: number;
    status: boolean;
}

export const PostStartScreenChange: DataSourceConfig<undefined, Data, undefined> = {
    url: "/catalogue/startScreen/change",
    method: "POST",
};
