import React from 'react';
import {
    CustomComponent,
    CustomComponentInitialState,
    CustomComponentState
} from '../../../../../utils/CustomComponent';
import { ModalBaseProps } from '../../../../../utils/Modal';
import ModalWrapper from '../../../../../utils/ModalWrapper';
import {
    CustomComponentModal,
    CustomComponentModalInitialState
} from '../../../../../utils/CustomComponentModal';
import FormComponent from '../../../../../utils/form/FormComponent';

import moment from 'moment';

import { DataSource } from '../../../../../utils/dataSource/DataSource';
import { CustomComponentViewStateWithModals } from '../../../../../utils/CustomComponentView';
import DataSourceLoader from '../../../../../utils/DataSourceLoader';
import { MagazineLocation } from '../../../../../../../../Api/types/src/areas/magazine/Location.types';
import { number } from 'prop-types';
import { MagazineOrderListModel } from '../../../../../../../../Api/types/src/areas/magazine/Order.types';

import {
    MagazineOrderProductModel,
    MagazineOrderProductQuantityMagazineOrderModel,
    MagazineOrderProductUpdateParams,
    MagazineOrderProductUpdateProductModel
} from '../../../../../../../../Api/types/src/areas/magazine/OrderProduct.types';
import TO from './Translate.json';

import Row from './ProductRow';

interface Props {
    magazineOrder: MagazineOrderListModel;
}

interface DataSources {
    productList: DataSource<MagazineOrderProductModel[]>;
    editProducts: DataSource<undefined>;
}

interface MagazineOrderProductQuantityMagazineOrderForm
    extends MagazineOrderProductQuantityMagazineOrderModel {
    error: string;
}

export interface MagazineOrderProductForm extends MagazineOrderProductModel {
    formQuantity: MagazineOrderProductQuantityMagazineOrderForm[];
}

interface State extends CustomComponentState {
    productList: MagazineOrderProductForm[];
}

export default class AddModal extends CustomComponentModal<{}, DataSources, Props, State> {
    state: State = {
        ...CustomComponentInitialState,
        productList: []
    };

    protected dataSource: DataSources = {
        productList: new DataSource(this, {
            url:
                '/magazine/order/products/list/' +
                this.props.customProps.magazineOrder.magazineOrderId,
            method: 'GET'
        }),
        editProducts: new DataSource(this, {
            url: '/magazine/order/updateProducts',
            method: 'POST'
        })
    };

    componentDidMount() {
        this._isMounted = true;
        this.getProductsList();
    }

    getProductsList() {
        this.dataSource.productList.makeRequest().then(
            data =>
                this._isMounted &&
                this.setState({
                    productList: data.map(p => ({
                        ...p,
                        formQuantity: p.quantity.map(q => ({ ...q, error: '' }))
                    }))
                })
        );
    }

    updateProducts(params: MagazineOrderProductUpdateParams) {
        return this.dataSource.editProducts
            .makeRequest({ data: { data: params } })
            .then(() => this.props.onClose());
    }

    private onAddQuantity(productId: number) {
        this.setState(prev => ({
            productList: prev.productList.map(p => {
                if (p.product.productId !== productId) {
                    return p;
                }

                if (!p.product.isExpiration && p.formQuantity.length > 0) {
                    return p;
                }

                p.formQuantity.push({
                    expireDate: p.product.isExpiration ? moment().format('YYYY-MM-DD') : undefined,
                    quantity: 0,
                    error: ''
                });

                return p;
            })
        }));
    }

    private onDeleteQuantity(productId: number, index: number) {
        this.setState(prev => ({
            productList: prev.productList.map(p => {
                if (p.product.productId !== productId) {
                    return p;
                }

                p.formQuantity.splice(index, 1);

                return p;
            })
        }));
    }

    private updateProductForm(
        productId: number,
        index: number,
        field: keyof MagazineOrderProductQuantityMagazineOrderForm,
        value: string
    ) {
        this.setState(prev => ({
            productList: prev.productList.map(p => {
                if (p.product.productId !== productId) {
                    return p;
                }

                return {
                    ...p,
                    formQuantity: p.formQuantity.map((fq, i) =>
                        i === index
                            ? {
                                  ...fq,
                                  [field]: value
                              }
                            : fq
                    )
                };
            })
        }));
    }

    private onQuantityDateChange(productId: number, index: number, value: string) {
        this.updateProductForm(productId, index, 'expireDate', value);
    }

    private onQuantityValueChange(productId: number, index: number, value: string) {
        if (value !== '') {
            value = Number.isNaN(parseInt(value)) ? '' : parseInt(value).toFixed(0);
        }
        this.updateProductForm(productId, index, 'quantity', value);
    }

    private checkDates(): boolean {
        let valid = true;

        this.state.productList.forEach(p => {
            const o: { [key: string]: boolean } = {};

            p.formQuantity.forEach((q, i) => {
                if (q.expireDate || q.expireDate === '') {
                    const date = moment(q.expireDate);

                    if (!date.isValid()) {
                        valid = false;
                        this.updateProductForm(
                            p.product.productId,
                            i,
                            'error',
                            this.T(TO.invalidDate)
                        );
                    } else {
                        if (o[date.format('YYYYMMDD')]) {
                            valid = false;
                            this.updateProductForm(
                                p.product.productId,
                                i,
                                'error',
                                this.T(TO.dateError)
                            );
                        } else {
                            o[date.format('YYYYMMDD')] = true;
                            this.updateProductForm(p.product.productId, i, 'error', '');
                        }
                    }
                }
            });
        });
        return valid;
    }

    private prepareDate(): MagazineOrderProductUpdateParams {
        return {
            magazineOrderId: this.props.customProps.magazineOrder.magazineOrderId,
            quantity: this.state.productList.reduce(
                (t, p) => {
                    return [
                        ...t,
                        ...p.formQuantity.map(fq => ({
                            productId: p.product.productId,
                            expireDate: p.product.isExpiration
                                ? moment(fq.expireDate).format('YYYY-MM-DD')
                                : undefined,
                            quantity: Number.isNaN(fq.quantity) ? 0 : fq.quantity
                        }))
                    ];
                },
                [] as MagazineOrderProductUpdateProductModel[]
            )
        };
    }

    private onSubmit() {
        console.log(this.checkDates());

        if (this.checkDates()) {
            this.updateProducts(this.prepareDate());
        }
    }

    render() {
        if (!this.props.state) {
            return null;
        }
        return (
            <ModalWrapper
                title={this.T(TO.title)}
                submit={this.T(TO.submit)}
                cancel={this.T(TO.cancel)}
                onSubmit={() => this.onSubmit()}
                onCancel={() => this.props.onDismiss()}
            >
                <DataSourceLoader
                    status={this.state.dataSourcesStatus}
                    language={this.context.translateService.currentLanguage()}
                />
                {this.state.productList.map(p => (
                    <Row
                        key={p.product.productId}
                        product={p}
                        onAddQuantity={productId => this.onAddQuantity(productId)}
                        onDeleteQuantity={(productId, quantityIndex) =>
                            this.onDeleteQuantity(productId, quantityIndex)
                        }
                        onQuantityDateChange={(productId, quantityIndex, value) =>
                            this.onQuantityDateChange(productId, quantityIndex, value)
                        }
                        onQuantityValueChange={(productId, quantityIndex, value) =>
                            this.onQuantityValueChange(productId, quantityIndex, value)
                        }
                        T={value => this.T(value)}
                    />
                ))}
            </ModalWrapper>
        );
    }
}
