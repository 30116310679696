import React, { Component } from "react";

import { Link, useRouteMatch } from "react-router-dom";

import appTranslate from "./Translate.json";
import { useTranslate } from "../../../hooks/useTranslate";

import { AppContext } from "../../../services/context";

const CatalogueSecondaryNav: React.FC = () => {
    const match = useRouteMatch();
    const T = useTranslate();

    return (
        <nav className="nav-secondary">
            <Link to={`${match.path}/categories`}>{T(appTranslate.categories)}</Link>

            <Link to={`${match.path}/property`}>{T(appTranslate.properties)}</Link>

            <Link to={`${match.path}/files`}>{T(appTranslate.files)}</Link>

            <Link to={`${match.path}/product`}>{T(appTranslate.products)}</Link>

            <Link to={`${match.path}/productIE`}>{T(appTranslate.productsIE)}</Link>

            <Link to={`${match.path}/startScreen`}>{T(appTranslate.startScreen)}</Link>
        </nav>
    );
};

// class CatalogueSecondaryNav extends Component {
//     render() {
//         const match = this.props.parentProps.match;

//         return (

//         );
//     }
// }
// CatalogueSecondaryNav.contextType = AppContext;

export default CatalogueSecondaryNav;
