import {
    DataSourceConfig,
    DataSourceParentForDataSourceConfig,
    DataSourceStorage,
    DataSourceState,
    DataSourceParent
} from "./DataSourceNew.types";
import { Endpoint } from "./Endpoint";
import { EndpointConfig, EndpointRequestArgs, EndpointConfigGetResponse } from "./Endpoint.types";

export const DataSourceStorageInitial: DataSourceStorage<any> = {
    state: "idle"
};

export class DataSourceNew<TName extends string, TEndpointConfig extends EndpointConfig<any, any, any>> {
    private config: DataSourceConfig<TName, TEndpointConfig>;
    private parent: DataSourceParent<TName, EndpointConfigGetResponse<TEndpointConfig>>;
    private endpoint: Endpoint<TEndpointConfig>;

    constructor(
        config: DataSourceConfig<TName, TEndpointConfig>,
        parent: DataSourceParent<TName, EndpointConfigGetResponse<TEndpointConfig>>
    ) {
        this.config = config;
        this.parent = parent;

        this.endpoint = new Endpoint<TEndpointConfig>(this.config.endpointConfig);
    }

    public async request(args: EndpointRequestArgs<TEndpointConfig>) {
        try {
            const authHeader = await this.parent.context.authService.getAuthHeader();
            await this.setState(p => ({ ...p, state: "pending" }));
            const response = await this.endpoint.request(args, authHeader);
            if (!this.parent._isMounted) {
                return response;
            }
            await this.setState(p => ({ ...p, state: "ready", response: response }));
            return response;
        } catch (e) {
            await this.setState(p => ({ ...p, state: "error", error: e }));
        }
    }

    get storage(): DataSourceStorage<EndpointConfigGetResponse<TEndpointConfig>> {
        return this.parent.state.datasource[this.config.name];
    }

    get state(): DataSourceState {
        return this.storage.state;
    }

    get data(): EndpointConfigGetResponse<TEndpointConfig> | undefined {
        return this.storage.response;
    }

    private setState(
        newStateFunction: (
            currentState: DataSourceStorage<EndpointConfigGetResponse<TEndpointConfig>>
        ) => DataSourceStorage<EndpointConfigGetResponse<TEndpointConfig>>
    ): Promise<void> {
        return new Promise(resolve => {
            this.parent.setState(
                p => ({
                    datasource: {
                        ...p.datasource,
                        [this.config.name]: newStateFunction(p.datasource[this.config.name])
                    }
                }),
                () => resolve()
            );
        });
    }
}
