import React from 'react';
import './ModalWrapper.scss';

interface Props {
    title: string;
    submit: string;
    cancel: string;
    onSubmit: () => void;
    onCancel: () => void;
}

const classBase = 'new-modal-wrapper';

const ModalWrapper: React.FC<Props> = props => (
    <div className={`${classBase}__wrapper`}>
        <div className={`${classBase}__background`} />
        <div className={`${classBase}__container`}>
            <div className={`${classBase}__header`}>
                <div className={`${classBase}__header-title`}>{props.title}</div>
                <button className='button-light icon no-border' onClick={() => props.onCancel()}>
                    close
                </button>
            </div>
            <div className={`${classBase}__content`}>{props.children}</div>
            <div className={`${classBase}__footer`}>
                <button className='button-light' onClick={() => props.onSubmit()}>
                    {props.submit}
                </button>
                <button className='button-light' onClick={() => props.onCancel()}>
                    {props.cancel}
                </button>
            </div>
        </div>
    </div>
);

export default ModalWrapper;
