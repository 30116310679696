import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import {
    CustomComponent,
    CustomComponentInitialState,
    CustomComponentState
} from '../../../../../utils/CustomComponent';
import { ModalBaseProps } from '../../../../../utils/Modal';
import ModalWrapper from '../../../../../utils/ModalWrapper';
import {
    CustomComponentModal,
    CustomComponentModalInitialState
} from '../../../../../utils/CustomComponentModal';
import FormComponent from '../../../../../utils/form/FormComponent';

import moment from 'moment';

import TO from './Translate.json';

import { DataSource } from '../../../../../utils/dataSource/DataSource';
import { CustomComponentViewStateWithModals } from '../../../../../utils/CustomComponentView';
import DataSourceLoader from '../../../../../utils/DataSourceLoader';
import { MagazineLocation } from '../../../../../../../../Api/types/src/areas/magazine/Location.types';
import { number } from 'prop-types';
import {
    MagazineOrderInsertModel,
    MagazineOrderListModel,
    MagazineOrderUpdateModel
} from '../../../../../../../../Api/types/src/areas/magazine/Order.types';

interface Props {
    magazineOrderId: number;
}

interface DataSources {
    deleteOrder: DataSource<undefined>;
}

interface State extends CustomComponentState {
    magazineLocations: MagazineLocation[];
}

export default class AddModal extends CustomComponentModal<{}, DataSources, Props, State> {
    state: State = {
        ...CustomComponentInitialState,
        magazineLocations: []
    };

    protected dataSource: DataSources = {
        deleteOrder: new DataSource<undefined>(this, {
            url: '/magazine/order/delete',
            method: 'POST'
        })
    };

    componentDidMount() {
        this._isMounted = true;
    }

    deleteOrder() {
        return this.dataSource.deleteOrder.makeRequest({
            data: { magazineOrderId: this.props.customProps.magazineOrderId }
        });
    }

    private onSubmit() {
        this.deleteOrder().then(() => {
            window.location.href = '/magazine/order';
        });
    }

    render() {
        if (!this.props.state) {
            return null;
        }
        return (
            <ModalWrapper
                title={this.T(TO.title)}
                submit={this.T(TO.submit)}
                cancel={this.T(TO.cancel)}
                onSubmit={() => this.onSubmit()}
                onCancel={() => this.props.onDismiss()}
            >
                <DataSourceLoader
                    status={this.state.dataSourcesStatus}
                    language={this.context.translateService.currentLanguage()}
                />

                <h3>{this.T(TO.question)}</h3>
            </ModalWrapper>
        );
    }
}
