import React from 'react';
import { CustomComponent } from '../../../../../utils/CustomComponent';
import Translation from './Translation.json';
import moment from 'moment';
import { ContractOrderListModel } from '../../../../../../../../Api/types/src/areas/contract/Order.types';

import DetailsRow from '../../../../../utils/ViewDetails/DetailsRow';
import {
    MagazineOrderListModel,
    MagazineOrderProductModel
} from '../../../../../../../../Api/types/src/areas/magazine/Order.types';

export interface Props {
    products: MagazineOrderProductModel[];
    T: CustomComponent['T'];
}

const TO = Translation.products;

const Element: React.FC<Props> = ({ products, T }) => (
    <div className='content-basic__header'>
        <div className='product-details'>
            <div className='product-details__row'>
                <div className='product-details__property-group'>{T(TO.title)}</div>
            </div>
            <div className='product-details__row'>
                <table>
                    <thead>
                        <tr>
                            <td>{T(TO.table.no)}</td>
                            <td>{T(TO.table.productName)}</td>
                            <td>P/N</td>
                            <td>{T(TO.table.category)}</td>
                            <td>{T(TO.table.expireDate)}</td>
                            <td>{T(TO.table.productCount)}</td>
                        </tr>
                    </thead>
                    <tbody>
                        {products.map((p, i) => (
                            <React.Fragment key={p.product.productId}>
                                <tr className='row-divider'>
                                    <td rowSpan={p.quantities.length}>{i + 1}</td>
                                    <td rowSpan={p.quantities.length}>
                                        {T(p.product.productName)}
                                    </td>
                                    <td rowSpan={p.quantities.length}>{p.product.ean}</td>
                                    <td rowSpan={p.quantities.length}>
                                        {T(p.product.mainCategoryLabel)} >{' '}
                                        {T(p.product.categoryLabel)}
                                    </td>
                                    <td>
                                        {p.quantities.length > 0 && p.quantities[0].expireDate
                                            ? moment(p.quantities[0].expireDate).format(
                                                  'YYYY-MM-DD'
                                              )
                                            : '---'}
                                    </td>
                                    <td>{p.quantities[0].quantity}</td>
                                </tr>
                                {p.quantities.length > 1
                                    ? p.quantities.map((q, i) => {
                                          if (i === 0) {
                                              return null;
                                          }
                                          let date = moment(q.expireDate).format('YYYY-MM-DD');
                                          return (
                                              <tr key={p.product.productId + date}>
                                                  <td>{date}</td>
                                                  <td>{q.quantity}</td>
                                              </tr>
                                          );
                                      })
                                    : null}
                            </React.Fragment>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    </div>
);

export default Element;
