import React, { Component } from 'react';

import { Link } from 'react-router-dom';

import appTranslate from './Translate.json';

import { AppContext } from '../../../services/context';

class CatalogueSecondaryNav extends Component {
    render() {
        const match = this.props.parentProps.match;

        return (
            <nav className='nav-secondary'>
                <Link to={`${match.path}/stock`}>
                    {this.context.translateService.getTranslation(
                        appTranslate.state
                    )}
                </Link>

                <Link to={`${match.path}/supply`}>
                    {this.context.translateService.getTranslation(
                        appTranslate.supply
                    )}
                </Link>

                <Link to={`${match.path}/order`}>
                    {this.context.translateService.getTranslation(
                        appTranslate.order
                    )}
                </Link>

                <Link to={`${match.path}/stocktaking`}>
                    {this.context.translateService.getTranslation(
                        appTranslate.stocktaking
                    )}
                </Link>

            </nav>
        );
    }
}
CatalogueSecondaryNav.contextType = AppContext;

export default CatalogueSecondaryNav;
