import React from "react";
import FormFieldWrapper, { FormFieldWrapperProps } from "./FieldWrapper";

export type FormFieldBaseExtractTValue<T> = T extends FormFieldBase<infer TValue> ? TValue : never;

export interface FormFieldBaseProps<TValue> {
    value: TValue;
    valueChange: (value: TValue) => void;
    onFocus?: () => void;
    onBlur?: () => void;
    onEnter?: () => void;
    id?: string;
}

export type FormFieldBasePropsUnion<TValue, TProps> = FormFieldBaseProps<TValue> & FormFieldWrapperProps & TProps;

export type FormFieldBaseExtractProps<T> = T extends FormFieldBase<infer TValue, infer TProps>
    ? FormFieldBasePropsUnion<TValue, TProps>
    : never;

export default class FormFieldBase<TValue, TProps = {}> extends React.Component<
    FormFieldBasePropsUnion<TValue, TProps>
> {
    protected onKey(e: React.KeyboardEvent) {
        if (e.key === "Enter" && !!this.props.onEnter) {
            this.props.onEnter();
        }
    }
}
