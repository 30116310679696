import BasicFormComponent from '../../../../components/basicFormComponent/BasicFormComponent';
import React from 'react';

import appTranslate from '../../../../appTranslate.json';
import axios, {post} from 'axios';

import { emptyObject } from '../../../../services/translateService';

import TextFieldL from '../../../../components/form/TextFieldL';
import CheckBox from '../../../../components/form/CheckBox';
import SelectField from '../../../../components/form/SelectField';

import { checkNotEmptyTextL } from '../../../../helpers/validator/Validator';

import Modal from '../../../../components/modal/Modal';
import Loader from '../../../../components/basic/Loader';

class EditModal extends BasicFormComponent {
    TObject = appTranslate.areas.catalogue.files.changeFileModal;

    constructor(props) {
        super(props);

        this.state = {
            file: null,
            loader: false
        };
    }


    prepareData = () => {
        let formData = new FormData();
        formData.append('file', this.state.file);
        formData.append('fileId', this.props.params.id);
        formData.append('languageCode', this.props.params.languageCode);
        return formData;
    };

    onSubmit = () => {
        this.submit();
    };

    submit = () => {
        const formData = this.prepareData();
        this.setLoader(true);
        this.context.authService
            .getAuthHeader()
            .then(authHeader => {
                return post(
                    GEAPP_ADMIN_CONFIG.adminApiUrl +// eslint-disable-line no-undef
                        '/catalogue/files/file/updateFile',
                    formData,
                    {
                        headers: {
                            ...authHeader,
                            'content-type': 'multipart/form-data'
                        }
                    }
                )
                    .then(response => {
                        console.log(response);
                        this.props.close(true);
                    })
                    .catch(error => {
                        console.log(error);
                    });
            })
            .catch(e => {
                console.log(e);
            })
            .finally(() => {
                // this.setLoader('properties', false);
            });
    };

    onFileInput = file => {
        console.log(file);
        this.setState(prevState => {
            return {
                file: file
            };
        });
    };

    render() {
        const footer = (
            <div className='space-between'>
                <button className='_button-light' onClick={() => this.onSubmit()}>
                    {this.T('save')}
                </button>

                <button className='_button-light' onClick={() => this.props.close(false)}>
                    {this.T('cancel')}
                </button>
            </div>
        );

        let loader = null;
        if (this.state.loader) {
            loader = <Loader />;
        }

        const content = (
            <div>
                {loader}
                <div className='form-file-upload__wrapper'>
                    <div className='form-file-upload__input-wrapper'>
                        <div className='form-file-upload__input-placeholder'>
                            <i className='material-icons'>cloud_upload</i>
                            <span className='filename'>
                                {this.state.file ? this.state.file.name : this.T('selectFile')}
                            </span>
                        </div>
                        <input
                            type='file'
                            className='form-file-upload__input'
                            accept="application/pdf"
                            onChange={event => this.onFileInput(event.target.files[0])}
                        />
                    </div>
                </div>
            </div>
        );
        return (
            <Modal
                title={this.T('title')}
                close={result => this.props.close(result)}
                footer={footer}
                content={content}
                visible={true}
            />
        );
    }
}

export default EditModal;
