import React, { Component } from 'react';
import { Route, Switch, RouteComponentProps } from 'react-router-dom';

import List from './List/List';
import Details from './Details/Details';

class Main extends Component<RouteComponentProps> {
    render() {
        return (
            <Switch>
                <Route
                    path={`${this.props.match.path}/:magazineOrderId/details`}
                    component={Details}
                />

                <Route path={`${this.props.match.path}`} component={List} />
            </Switch>
        );
    }
}

export default Main;
