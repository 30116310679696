import React from 'react';
import './Loader.scss';

const Loader: React.FC = () => (
    <div className='new-loader__wrapper'>
        <div className='new-loader__content'>
            <div className='new-loader__circle' />
            <div className='new-loader__circle' />
            <div className='new-loader__circle' />
        </div>
    </div>
);

export default Loader;
