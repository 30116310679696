import BasicFormComponent from '../../../../components/basicFormComponent/BasicFormComponent';
import React from 'react';

import appTranslate from '../../../../appTranslate.json';
import axios from 'axios';

import { emptyObject } from '../../../../services/translateService';

import Modal from '../../../../components/modal/Modal';
import Loader from '../../../../components/basic/Loader';

class DeleteModal extends BasicFormComponent {
    TObject = appTranslate.areas.catalogue.files.deleteChildModal;

    constructor(props) {
        super(props);

        this.state = {
            form: {
                label: { ...emptyObject('') }
            },
            loader: true
        };
    }

    componentDidMount() {
        this.fetchCategoryId();
    }

    fetchCategoryId = () => {
        this.setLoader(true);
        this.context.authService
            .getAuthHeader()
            .then(authHeader => {
                return axios({
                    url: GEAPP_ADMIN_CONFIG.adminApiUrl + '/catalogue/files/file/details/' + this.props.params.id,// eslint-disable-line no-undef
                    method: 'GET',
                    headers: {
                        ...authHeader,
                        'Content-Type': 'application/json'
                    }
                })
                    .then(response => {
                        console.log(response);
                        if (response.status === 200 && response.data.code === 200) {
                            const category = response.data.data;
                            this.setLoader(false);
                            this.setState(prevState => {
                                return {
                                    form: {
                                        ...prevState.form,
                                        label: {
                                            ...prevState.form.label,
                                            ...this.getFieldL(category.label)
                                        }
                                    }
                                };
                            });
                        }
                    })
                    .catch(error => {
                        console.log(error);
                    });
            })
            .catch(e => {
                console.log(e);
            })
            .finally(() => {
                // this.setLoader('properties', false);
            });
    };

    prepareData = () => {
        return {
            fileId: this.props.params.id
        };
    };

    onSubmit = () => {
        this.submit();
    };

    submit = () => {
        this.setLoader(true);
        this.context.authService
            .getAuthHeader()
            .then(authHeader => {
                return axios({
                    url: GEAPP_ADMIN_CONFIG.adminApiUrl + '/catalogue/files/file/delete',// eslint-disable-line no-undef
                    method: 'POST',
                    headers: {
                        ...authHeader,
                        'Content-Type': 'application/json'
                    },
                    data: {
                        ...this.prepareData()
                    }
                })
                    .then(response => {
                        console.log(response);
                        this.props.close(true);
                    })
                    .catch(error => {
                        console.log(error);
                    });
            })
            .catch(e => {
                console.log(e);
            })
            .finally(() => {
                // this.setLoader('properties', false);
            });
    };

    render() {
        const footer = (
            <div className='space-between'>
                <button className='_button-light' onClick={() => this.onSubmit()}>
                    {this.T('save')}
                </button>

                <button className='_button-light' onClick={() => this.props.close(false)}>
                    {this.T('cancel')}
                </button>
            </div>
        );

        let loader = null;
        if (this.state.loader) {
            loader = <Loader />;
        }

        const content = (
            <div>
                {loader}
                <div className='modal-delete-question'>{this.T('question', [this.state.form.label])}</div>
            </div>
        );
        return <Modal title={this.T('title')} close={result => this.props.close(result)} footer={footer} content={content} visible={true} />;
    }
}

export default DeleteModal;
