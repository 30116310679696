import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";

import CatalogueSecondaryNav from "./components/CatalogueSecondaryNav";

import StockMain from "./views/stock/Main";
import SupplyMain from "./views/supply/Main";
import OrderMain from "./views/order/Main";
import StocktakingMain from "./views/stocktaking/Main";

import "./Main.scss";

class CatalogueMain extends Component {
    render() {
        return (
            <div className="area">
                <div className="area-wrapper">
                    <CatalogueSecondaryNav parentProps={this.props} />
                    <Switch>
                        <Route path={`${this.props.match.path}/stock`} component={StockMain} />{" "}
                        <Route path={`${this.props.match.path}/supply`} component={SupplyMain} />
                        <Route path={`${this.props.match.path}/order`} component={OrderMain} />
                        <Route path={`${this.props.match.path}/stocktaking`} component={StocktakingMain} />
                    </Switch>
                </div>
            </div>
        );
    }
}

export default CatalogueMain;
