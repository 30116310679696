import { DataSourceNew } from "../../utils/endpoint/DataSourceNew";
import { DataSourceFunction } from "../../utils/endpoint/DataSourceNew.function.types";

import { PostMagazineProductBarcodeInsertData } from "../../../../../Api/types/src/api/magazine/PostMagazineProductBarcodeInsert.types";

export const PostProductBarcodeInsertDataSource: DataSourceFunction<
    "MagazineProductBarcodeInsert",
    undefined,
    PostMagazineProductBarcodeInsertData,
    undefined
> = parent =>
    new DataSourceNew(
        {
            name: "MagazineProductBarcodeInsert",
            endpointConfig: {
                url: "/v2/magazine/MagazineProductBarcodeInsert",
                method: "POST"
            }
        },
        parent
    );
