import React from 'react';

import { MagazineOrderProductForm } from './EditProduct';
import { CustomComponent } from '../../../../../utils/CustomComponent';
import TO from './Translate.json';
import './ProductRow.scss';

interface Props {
    product: MagazineOrderProductForm;
    onAddQuantity: (productId: number) => void;
    onDeleteQuantity: (productId: number, quantityIndex: number) => void;
    onQuantityValueChange: (productId: number, quantityIndex: number, value: string) => void;
    onQuantityDateChange: (productId: number, quantityIndex: number, value: string) => void;
    T: CustomComponent['T'];
}

const classBase = '_magazine-order-edit-product-row';

const Row: React.FC<Props> = ({
    product,
    T,
    onQuantityDateChange,
    onQuantityValueChange,
    onAddQuantity,
    onDeleteQuantity
}) => (
    <div className={`${classBase}__wrapper`}>
        <div className={`${classBase}__product-info`}>
            <div className={`${classBase}__product-info-name`}>
                {T(product.product.productName)}
            </div>
            <div className={`${classBase}__product-info-ean`}>{product.product.ean}</div>
            <div className={`${classBase}__product-info-category`}>
                {T(product.product.mainCategoryLabel)} > {T(product.product.categoryLabel)}
            </div>
        </div>
        <div className={`${classBase}__product-quantiy`}>
            {T(TO.contractOrderQuantity)}
            {product.contractOrderQuantity.contractOrder}
        </div>
        <div className={`${classBase}__form`}>
            <div className={`${classBase}__form-quantity`}>
                {product.formQuantity.map((fq, index) => (
                    <div className={`${classBase}__form-quantity-row`} key={index}>
                        {fq.expireDate || fq.expireDate === '' ? (
                            <input
                            className={`${classBase}__form-quantity-row-date`}
                                type='date'
                                value={fq.expireDate}
                                onChange={e =>
                                    onQuantityDateChange(
                                        product.product.productId,
                                        index,
                                        e.target.value
                                    )
                                }
                            />
                        ) : null}
                        <input
                        className={`${classBase}__form-quantity-row-value`}
                            type='text'
                            value={fq.quantity}
                            onChange={e =>
                                onQuantityValueChange(
                                    product.product.productId,
                                    index,
                                    e.target.value
                                )
                            }
                        />
                        <button
                            className='button-light x-small icon'
                            onClick={() => onDeleteQuantity(product.product.productId, index)}
                        >
                            close
                        </button>
                        <div className={`${classBase}__form-quantity-error`}>{fq.error}</div>
                    </div>
                ))}
            </div>
            <div className={`${classBase}__add-more`}>
                {product.product.isExpiration || product.formQuantity.length === 0 ? (
                    <button
                        className='button-light x-small'
                        onClick={() => onAddQuantity(product.product.productId)}
                    >
                        {T(TO.addQuantity)}
                    </button>
                ) : null}
            </div>
        </div>
    </div>
);

export default Row;
