import BasicFormComponent from '../../../../components/basicFormComponent/BasicFormComponent';
import React from 'react';

import appTranslate from '../../../../appTranslate.json';
import axios from 'axios';
import moment from 'moment';

import TextField from '../../../../components/form/TextField';
import SelectField from '../../../../components/form/SelectField';

import { checkNotEmptyText, checkValidDate } from '../../../../helpers/validator/Validator';

import Modal from '../../../../components/modal/Modal';
import Loader from '../../../../components/basic/Loader';

class EditModal extends BasicFormComponent {
    TObject = appTranslate.areas.contracts.order.editModal;

    constructor(props) {
        super(props);

        this.state = {
            destinations: [],
            details: undefined,
            form: {
                shipmentDate: {
                    value: moment()
                        .add(3, 'd')
                        .format('YYYY-MM-DD'),
                    error: '',
                    checks: [checkValidDate]
                },
                destinationId: {
                    value: undefined,
                    error: ''
                }
            },
            loader: true
        };
    }

    componentDidMount() {
        this.fetchData();
    }

    fetchData = () => {
        this.setLoader(true);
        this.context.authService
            .getAuthHeader()
            .then(authHeader => {
                return axios({
                    url: GEAPP_ADMIN_CONFIG.adminApiUrl + '/contract/order/update/data/' + this.props.params.id,// eslint-disable-line no-undef
                    method: 'GET',
                    headers: {
                        ...authHeader,
                        'Content-Type': 'application/json'
                    }
                })
                    .then(response => {
                        console.log(response);
                        if (response.status === 200 && response.data.code === 200) {
                            const data = response.data.data.details;
                            this.setLoader(false);
                            this.setState(prevState => {
                                const destinations = response.data.data.companies.reduce((t, c) => {
                                    if (c.companyId === data.company.companyId) {
                                        t = c.destinations;
                                    }
                                    return t;
                                }, []);
                                return {
                                    form: {
                                        ...prevState.form,
                                        shipmentDate: {
                                            ...prevState.form.shipmentDate,
                                            value: moment(data.shipmentDate).format('YYYY-MM-DD')
                                        },
                                        destinationId: {
                                            ...prevState.form.destinationId,
                                            value: destinations.length > 0 ? data.destination.destinationId : undefined,
                                            error: destinations.length > 0 ? '' : this.T('noDestination')
                                        }
                                    },
                                    details: data,
                                    destinations: destinations
                                };
                            });
                        }
                    })
                    .catch(error => {
                        console.log(error);
                    });
            })
            .catch(e => {
                console.log(e);
            })
            .finally(() => {
                // this.setLoader('properties', false);
            });
    };

    prepareData = () => {
        const form = this.state.form;
        return {
            contractOrderId: this.props.params.id,
            destinationId: parseInt(form.destinationId.value),
            shipmentDate: form.shipmentDate.value
        };
    };

    onSubmit = () => {
        console.log(this.validateFields());

        if (!this.state.form.destinationId.value) {
            this.setState(prev => ({
                form: {
                    ...prev.form,
                    destinationId: {
                        ...prev.form.destinationId,
                        error: this.T('noDestination')
                    }
                }
            }));
            return;
        }

        console.log(this.prepareData());

        if (this.validateFields() && !!this.state.form.destinationId.value) {
            this.submit();
        } else {
            console.log();
        }
    };

    submit = () => {
        this.setLoader(true);
        this.context.authService
            .getAuthHeader()
            .then(authHeader => {
                return axios({
                    url: GEAPP_ADMIN_CONFIG.adminApiUrl + '/contract/order/update',// eslint-disable-line no-undef
                    method: 'POST',
                    headers: {
                        ...authHeader,
                        'Content-Type': 'application/json'
                    },
                    data: {
                        ...this.prepareData()
                    }
                })
                    .then(response => {
                        console.log(response);
                        this.props.close(true);
                    })
                    .catch(error => {
                        console.log(error);
                    });
            })
            .catch(e => {
                console.log(e);
            })
            .finally(() => {
                // this.setLoader('properties', false);
            });
    };

    render() {
        const footer = (
            <div className='space-between'>
                <button className='_button-light' onClick={() => this.onSubmit()}>
                    {this.T('save')}
                </button>

                <button className='_button-light' onClick={() => this.props.close(false)}>
                    {this.T('cancel')}
                </button>
            </div>
        );

        let loader = null;
        if (this.state.loader) {
            loader = <Loader />;
        }

        const content = (
            <div>
                {loader}

                <SelectField
                    label={this.T('destination')}
                    value={this.state.form.destinationId.value}
                    options={this.state.destinations.map(e => {
                        return {
                            value: e.destinationId,
                            label: `${e.label} (${e.street} ${e.houseNumber}, ${e.postalCode} ${e.city})`
                        };
                    })}
                    error={this.state.form.destinationId.error}
                    onInput={value => {
                        value = parseInt(value);
                        this.setState(prev => ({
                            form: {
                                ...prev.form,
                                destinationId: {
                                    ...prev.form.destinationId,
                                    error: !!value ? '' : this.T('noDestination')
                                }
                            }
                        }));
                        this.onInputValue('destinationId', value);
                    }}
                />

                <TextField
                    field={this.state.form.shipmentDate}
                    type='date'
                    onInput={value => this.onInputValue('shipmentDate', value)}
                    onBlur={() => this.validateField('shipmentDate')}
                    label={this.T('shipmentDate')}
                />
            </div>
        );
        return (
            <Modal
                title={this.T('title')}
                close={result => this.props.close(result)}
                footer={footer}
                content={content}
                visible={true}
            />
        );
    }
}

export default EditModal;
